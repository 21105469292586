import Cookies from 'js-cookie';

const SessionRemove = () => {

    sessionStorage.removeItem("personalaccidentbasicinfo");
    sessionStorage.removeItem("personalaccidentpremiumcover");
    sessionStorage.removeItem("personalaccidentbeneficiaries");
    sessionStorage.removeItem("persionalaccidentinformationpage");
    sessionStorage.removeItem("personalaccdientPolicynumber");
  
};

export default SessionRemove ;