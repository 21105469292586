import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
const { config } = require('../api/api');

function LifeAccidentCover() {
  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });
  const [loading, setLoading] = useState(false);




  const handleqOptionChange = (event) => {



  };


  

  const [dhofarData, setDhofarData] = useState(false);
  const [age, setAge] = useState(null);
  useEffect(() => {


    const storedDhofarString = Cookies.get('personalData'); //sessionStorage.getItem('userData')
    
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);


  const [vendorKey, setVendorkey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };
    debugger

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch( base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorkey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]);






  const postData = { token: dhofarData };


  const [assure, setAssured] = useState('');
  const [premium, setpermium] = useState('');

  useEffect(() => {
    

    if (vendorKey !== '') {
      fetch(base_url + '/get_personal_accident_premium', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),

      })
        .then(response => response.json())
        .then(data => {
          
          setpermium(data.result.data.premium);
          setAssured(data.result.data.sum_assured);

        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }
  }, [vendorKey]);

  //getmaritalstatus


  useEffect(() => {
    debugger

    const preminumjson = sessionStorage.getItem('personalaccidentpremiumcover');

    if (preminumjson!=undefined){
      debugger
      const preminums = JSON.parse(preminumjson);
      setpermium(preminums.premium_valu);
      setAssured(preminums.assured_value);
    }


  },[]);






  const handleSubmit = async (event) => {

    var data = {
      "premium_valu": premium,
      "assured_value": assure
    }

    debugger

    sessionStorage.setItem("personalaccidentpremiumcover", JSON.stringify(data))

    event.preventDefault();
    setLoading(true);

    event.preventDefault();
    window.location.href = '/lifeadd-beneficiary';



  };

  const [buttonDisabled, setButtonDisabled] = useState(false);

  // const today = new Date().toISOString().split('T')[0];
  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];


  const [dobValue, setdobValue] = useState('');
  const handledobChange = (event) => {
    const selectedDob = event.target.value;
    setdobValue(event.target.value);

    const dobDate = new Date(selectedDob);
    const today = new Date();
    let calculatedAge = today.getFullYear() - dobDate.getFullYear();
    const monthDiff = today.getMonth() - dobDate.getMonth();

    // Adjust age if the current date is before the user's birthday in the current year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
      calculatedAge--;
    }

    setAge(calculatedAge);
  };

  const [selectedgenderOption, setSelectedgenderOption] = useState(null);
  const [selectedgenderOptionid, setSelectedgenderOptionid] = useState(null);
  const handlegenderChange = (event) => {
    setSelectedgenderOptionid(event.target.options[event.target.selectedIndex].id);
    setSelectedgenderOption(event.target.value);
  };

  const [selectedmaritalOption, setSelectedmaritalOption] = useState(null);
  const [selectedmaritalOptionid, setSelectedmaritalOptionid] = useState(null);
  const handlemaritalChange = (event) => {
    setSelectedmaritalOptionid(event.target.options[event.target.selectedIndex].id);
    setSelectedmaritalOption(event.target.value);
  };
  //getgender

  const NumberFormatter = ({ value }) => {
    // Ensure value is a number and format to 3 decimal places
    const formattedValue = `${Number(value).toFixed(3)} OMR`;
  debugger
    return formattedValue ;
  };










  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Personal Accident Cover   ' : 'تغطية الحوادث الشخصية\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">



              {/* <h3 className="">{premium} {"OMR"}</h3>
              <h2 className="px-0 py-1">{'Sum Assured'} {assure}</h2> */}
              <div className="px-0 py-1">
              <h1 className="text-bold" value={premium}> {premium==''?<strong></strong>:<strong><NumberFormatter value={premium} /></strong>} </h1>
                <h2 className="px-0 py-1" value={assure}>{'Sum Assured'} {assure}</h2>
                <ul>
                  <li>2 Years Coverage</li>
                  <li>Death due to an accident 3,000.000</li>
                  <li>Permanent Total Disablement due to an accident 3,000.000</li>
                  <li>Permanent Partial Disablement due to an accident (% of Sum Assured as certified by Govt Medical Board) 3,000.000 </li>
                  <li>Death Due to any reason (Other than Accident) 1,000.000</li>
                  <li>Medical Expenses due to an accident (Up to) 500.000</li>
                  <li>Repatriation of Mortal Remains to home country (Up to) 400.000</li>
                  <li>Air ticket expenses for medically unfit to work (Up to) 100.000</li>
                </ul>
              </div>
              {/* <h2 className="px-0 py-1">{'Air ticket expenses for medically unfit to work (Up to) 100.000'}</h2> */}
              <h2> </h2>
              <button type="submit" disabled={buttonDisabled} onClick={handleSubmit} className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('ADD')}</button>


              


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default LifeAccidentCover;
