import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../../api/api');

function LifeTermPlanPolicyStatus() {

  const base_url = config.baseURL;
  const redirect_url = config.redirectURL;

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);


    const [dhofarData, setDhofarData] = useState(false);
  const [age, setAge] = useState(null);
  useEffect(() => {


    const storedDhofarString = Cookies.get('personalData'); //sessionStorage.getItem('userData')
    debugger
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);


  const [vendorKey, setVendorkey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };
    debugger

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch( base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorkey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]);


  const [payment_link , setPaymentValue] = useState('');

  debugger

  const policyjson = sessionStorage.getItem('personalaccdientPolicynumber');
      const policy_no = JSON.parse(policyjson);


  const handleQotation = async (event) => {

    
  
      debugger
  
      var quotation_data =
      {
        "token": dhofarData,
        "policy_no":policy_no,
        "redirect_url": redirect_url + '/lifetermplan-downloadpolicy?policyno='+ policy_no
      };
      debugger
      if (vendorKey !== '') {
        // const fetchPolicy = async () => {
        try {
  
  
          const Policyresponce = await fetch( base_url + '/create_term_plan_payment_link', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${vendorKey}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(quotation_data),
          });
  
          if (!Policyresponce.ok) {
            throw new Error('Network response was not ok');
          }
          debugger
  
          const data = await Policyresponce.json();
  
        setPaymentValue(data.result.data.payment_url);
        window.location.href = data.result.data.payment_url;
        //   await handlePolicy(data.result.data.amount)
        } catch (error) {
          console.error('Error fetching occupations:', error);
        }
        // };
  
  
      }
    };
    const NumberFormatter = ({ value }) => {
      // Ensure value is a number and format to 3 decimal places
      const formattedValue = `${Number(value).toFixed(3)} OMR`;
    debugger
      return formattedValue ;
    };
    const handleSubmit = async (event) => {

        handleQotation();    
    
      };

      const handlecancel = async (event) => {

        window.location.href = './term-plan'
      };

      function formatDOB(dob) {
        const date = new Date(dob);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      }

      debugger

      const sumassuredjson = sessionStorage.getItem("personalaccidentpremiumcover");
      const sumaasured = JSON.parse(sumassuredjson);

      const personalinfojson = sessionStorage.getItem('persionalaccidentinformationpage');
      const personalinfo = JSON.parse(personalinfojson);

      const amountjson = sessionStorage.getItem('personalaccdientamount');
      const amount = JSON.parse(amountjson);



  return (


    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Personal Accident Insurance Policy' : 'بوليصة التأمين ضد الحوادث الشخصية\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>
            {/* style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} */}

            <Col md={6} className="myforms my-5">

            <h1 style={{textAlign:"center"}}><b>{(lnstr === 'en') ? 'SUMMARY' : 'ملخص\n'}</b></h1>



            {/* <h2 style={{textAlign:'left'}}>{(lnstr === 'en') ? 'Plan Details':'تفاصيل الخطة'}</h2> */}
            <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
              <div className="d-flex justify-content-between my-3">
                <div>
                <p>{(lnstr === 'en') ? 'Plan Type' :'نوع الخطة'}</p>
                <h6><strong>Term Plan</strong></h6>
                </div>
                <div>
              <p>{(lnstr === 'en') ? 'Sum Assured':'المبلغ المؤمن'}</p>
              <h6><strong>{sumaasured !=null ? sumaasured ?.premium_valu :''}</strong></h6>
              </div>
              
              </div>
              
            </div>

            


            <br />
            <h3>{(lnstr === 'en') ? 'Personal Details':'التفاصيل الشخصية'}</h3>
            <div>



            <table className="table table-bordered table-condenced">

    <tbody>

    <tr><td>First Name</td><td><b>{personalinfo != null ? personalinfo ?.fullName : ''}</b></td>
    <td>Email</td><td><b>{personalinfo != null ? personalinfo ?.email : ''}</b></td></tr>

    <tr><td>Civil ID</td><td><b>{personalinfo != null ? personalinfo?.civilid : ''}</b></td>
    <td>Mobile</td><td><b>{personalinfo !=null ? personalinfo ?.mobilenumber : ''}</b></td></tr>

    <tr><td>Passport</td><td><b>{personalinfo != null ? personalinfo?.passportnumber : ''}</b></td>
    <td>Expiry Date of Civil ID / Passport</td><td><b>{personalinfo != null ? formatDOB (personalinfo?.expiry_date) : ''}</b></td></tr>

    {/* <tr><td>Gender</td><td><b>gender</b></td>
    <td>Marital Status</td><td><b>marital_status_value</b></td></tr> */}

    <tr><td>Residential Location</td><td><b>{personalinfo != null ? personalinfo?.Residential_Location: ''}</b></td>
    <td>Nationality</td><td><b>{personalinfo != null ? personalinfo?.nationality: ''}</b></td></tr>

    </tbody>

    </table>
                            
                            </div>
                            <br/>



                            <h3>{(lnstr === 'en') ? 'Premium Details':'تفاصيل متميزة'}</h3>
            <div>
            <table className="table table-bordered table-condenced" style={{ textAlign: 'left' }}>

  <tbody>

      <tr><td style={{ width: '50%' }}>
      Basic premium
          </td><td><b>{amount != null ? <NumberFormatter value={amount?.base_price} /> : ''}</b></td></tr>

      <tr><td>Policy Fee	
          </td><td><b>{amount != null ? <NumberFormatter value={amount?.policy_fee} /> : ''}</b></td></tr>
        
      
          <tr><td>Government Fee
      
      </td><td><b>{amount != null ? <NumberFormatter value={amount?.government_fee} />: ''}</b>
      </td></tr>


      <tr><td>Emergency Fund Fee
  </td><td><b>{amount != null ? <NumberFormatter value={amount?.emergency_fund_fee} />: ''}</b></td></tr>

      <tr><td>VAT
          </td><td><b>{amount != null ?<NumberFormatter value={amount?.vat} />  : ''}</b></td></tr>

      <tr><td>Total Premium
  </td><td><b>{amount != null ?<NumberFormatter value={amount?.total} />  : ''}</b></td></tr>
  </tbody>

  </table>
            </div>


            {/* <p style={{textAlign:'center'}}><strong>{policy_no}</strong></p> */}

            <div className="d-flex  my-3">

            <button className="btn btn-primary" style={{ padding: '10px 30px' , marginRight : '10px'}}   onClick={handlecancel} >Edit</button>

            <button className="btn btn-primary" style={{ padding: '10px 30px'}}   onClick={handleSubmit} > Proceed to Payment</button>

            </div>

            


            </Col>

            <Col md={3}></Col>

          </div>


        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>



    
  );
}

export default LifeTermPlanPolicyStatus;