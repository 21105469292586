import React, { Fragment, useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
const { config } = require('./api/api');



function Partner() {
  const base_url = config.baseURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();

  const [searchData, setSearchData] = useState(false);
  useEffect(() => {
    const storedSearchString = sessionStorage.getItem('searchData');
    if (storedSearchString) {
      const storedSearch = JSON.parse(storedSearchString);
      setSearchData(storedSearch);
    }
  }, []);


  const [searchResult, setSearchResult] = useState(false);
  const [omrAmount, setOmrAmount] = useState('');
  useEffect(async () => {
    const storedResultString = sessionStorage.getItem('searchResult');
    if (storedResultString !== "undefined") {
      const storedResult = JSON.parse(storedResultString);
      debugger;
      if(storedResult != null && storedResult != undefined && storedResult.fee != null && storedResult.fee != undefined){
        await getOMRAmount(storedResult.fee,storedResult.currency);
      }
     
      setSearchResult(storedResult);
    }
  }, []);

  const [flgResult, setFlagResult] = useState(false);
  useEffect(() => {
    const storedFlgResultString = sessionStorage.getItem('flagResult');
    if (storedFlgResultString !== "undefined") {
      const storedflgResult = JSON.parse(storedFlgResultString);
      setFlagResult(storedflgResult);
    }
  }, []);


  const [sembassyResult, setEmbassyResult] = useState([]);
  useEffect(() => {
    const storedEmbassyString = sessionStorage.getItem('embassyResult');
    if (storedEmbassyString !== "") {
      const storedEmbassy = JSON.parse(storedEmbassyString);
      setEmbassyResult(storedEmbassy);
    }
  }, []);


  const [formData, setFormData] = useState({
    destination: '',
    arraival: '',
    lengthofstay: ''
  });


  const backClick = (buttonValue) => {

    const url = `/visa`;

    window.location.href = url;
  }
  const handleClick = (buttonValue) => {

    if (Cookies.get('userData')) {
      // Construct the URL with parameters
      // const parameter1 = searchData.destination;
      // const parameter3 = searchData.lengthofstay;
      // const parameter4 = buttonValue;
      // const url = `/application`;
      const url = `/application-new`;

      // ?destination=${parameter1}&lengthofstay=${parameter3}&visatype=${parameter4}`


      window.location.href = url;
    }
    else {

      window.location.href = '/login'; ///Login?red=Visatypes

    }

  };
  const getOMRAmount = (amount, currency) => {
debugger
    fetch(base_url+'/amount_convertion', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "amount":amount,
        "currency": currency
    }),
    })
      .then(response => response.json())
      .then(data => {
        debugger;
        if(data['message'] == 'success'){
          var result = data['result'];
          var split_data = result.split('.');
          var convertion_amount = '';
          if(split_data[1].length >= 3){
            convertion_amount = split_data[0] +'.'+ result.split('.')[1].substring(0, 3);
          }else{
            convertion_amount = result;
          }
          setOmrAmount(convertion_amount);
        }
       
        // setVendorKey(data.result[0].vendor_key);
      })
      .catch(error => {
        console.error('Error posting data:', error);
      });
   
  };
  return (
    <div className="App">


      <Navbar />
      <div className="features_section layout_padding">
        <div className="container">

          <div className="row">

            <Col md={3} className="text-center">
            </Col>

            <Col md={6} className="m-2 mt-4">
              <table>
                <tbody>
                  <tr><td>{t('Destination')}</td><td>&nbsp; : &nbsp;</td><td><b>{t(searchData.destination)}</b></td></tr>
                  <tr><td>{t('Citizenship')}</td><td>&nbsp; : &nbsp;</td><td><b>{t(searchData.citizenship)}</b></td></tr>
                  {/* <tr><td>{t('Length')} {t('Of')} {t('stay')}</td><td>&nbsp; : &nbsp;</td><td><b>{searchData.lengthofstay}</b></td></tr> */}
                </tbody>
              </table>
            </Col>

            <Col md={3} className="text-center">
            </Col>


            <Col md={6} className="colmds-4 my-2 py-3">

              <Row>

                <Col md={4}><img src={flgResult.to} alt="SUPERJET" style={{ width: '120px', height: '120px', marginTop: '5px' }} /></Col>

                <Col md={8}>

                  <h4>{t('Tourist')} {t('e-visa')}</h4>

                  {(searchResult['fee']) ? <div>{t('Visa')} {t('Charges')} : <b>{searchResult['fee']} {searchResult['currency']}</b> ({omrAmount} {t('OMR')})</div> : ''}

                  {(searchResult['fee']) ? <div>{t('Max')} {t('Stay')} : <b>{searchResult['max_stay']} Days</b></div> : ''}

                  {(searchResult['fee']) ? <div><button type="button" className="btn btn-primary rounded-pill mt-4 text-white py-1 px-4" onClick={() => handleClick("Tourist e-visa")}>{(lnstr === 'en' ? 'Apply' : 'يتقدم')}</button></div> : ''}

                  {/* {(searchResult['fee']) ? <div></div> : (sembassyResult && sembassyResult.length>0 && sembassyResult[0]['title'].indexOf('A visa is not required for your visit')>-1) ? <div></div> : <span style= {{ color: "red" }}>{t('Superjet')} {t('is')} {t('unable')} {t('to')} {t('offer')} {t('this')} {t('service')} {t('for')} {t('now')}.</span> } */}

                  {(searchResult['fee']) ? <div></div> : (searchResult['label'] === 'Visa Required') ? <span style={{ color: "red" }}>{t('Visa')} {t('Required')}. {t('Superjet')} {t('is')} {t('unable')} {t('to')} {t('offer')} {t('this')} {t('service')} {t('for')} {t('now')}.</span> : (searchResult['label'] !== 'Visa Required') ? <div>{searchResult['label']}<br /></div> : ''}

                  {(searchResult['fee'] === '') && (searchResult['label'] === 'Visa Required' || searchResult['label'] === 'eVisa') ? <div><br /><b style={{ color: "black" }}>{t('How')} {t('can')} {t('I')} {t('get')} {t('this')} {t('visa')}?<br /></b> To learn how to get this visa, please reach out to <a href={searchResult['embassy_url']} target='blank' style={{ color: "blue" }}>the local embassy or consultate</a></div> : ''}

                  {(searchResult['fee'] === '') && (searchResult['label'] !== 'Visa Required' && searchResult['label'] !== 'eVisa') ? <div><br /><b style={{ color: "black", "fontSize": "15px" }}>You are good to go!<br /></b> You don't need a Visa for {searchData.destination} if you have a passport from {searchData.citizenship}</div> : ''}

                  {(!searchResult) ? <span style={{ color: "red" }}>{t('Superjet')} {t('is')} {t('unable')} {t('to')} {t('offer')} {t('this')} {t('service')} {t('for')} {t('now')}.</span> : ''}

                </Col>

              </Row>

            </Col>

            {/* {t('A')} {t('visa')} {t('is')} {t('required')} {t('to')} {t('travel')} {t('to')} {t('this')} {t('country')}, {t('but')}  */}

            <Col md={2} className="text-center">
            </Col>

            <Col md={3} className="text-center">
            </Col>


            <Col md={6} className="mt-4 p-0 px-2" style={{ textAlign: "justify" }}>

              <div>
                {/*<button type="button" className="btn btn-primary rounded-pill mt-4 text-white py-1 px-4"*/}
                {/*        onClick={() => handleClick("Tourist e-visa")}>Apply*/}
                {/*</button>*/}
                <div style={{ textAlign: 'center' }}>
                  <button type="button"
                    className="btn btn-primary rounded-pill mt-4 text-white py-1 px-4"
                    onClick={() => backClick("Tourist e-visa")}>
                    <i className="fas fa-arrow-left" style={{ marginRight: '8px' }}></i>
                  </button>
                </div>
              </div>

            </Col>

            <Col md={4} className="text-center">
            </Col>


          </div>

        </div>
      </div>

      <Footer />

    </div>
  );
}

export default Partner;
