
import { toBeRequired } from '@testing-library/jest-dom/matchers';
import { endPoints } from './api';
import * as ApiConfig from './api';
export const api = {
  makeUserLoginCall(data) {
    debugger
    var login_endpoint = data.type == 'traveller' ? endPoints.loginEndpoint : endPoints.loginAgencyEndpoint;
    return ApiConfig.getApiInterfaceWithToken()
      .post(login_endpoint, data)
      .then(responseJson => {
        if (typeof responseJson.data === 'object') {
          return responseJson.data;
        } else {
          if (responseJson.data[0] === '{') {
            return JSON.parse(responseJson.data + '}');
          }
          if (responseJson.data[0] === '[') {
            return JSON.parse(responseJson.data + ']');
          }
        }
      })
      .catch(error => {
        return error;
      });
  },

  makeUserSignUpCall(data) {
    // debugger
    return ApiConfig.getApiInterfaceWithToken()
      .post(endPoints.signUp, data)
      .then(responseJson => {
        if (typeof responseJson.data === 'object') {
          return responseJson.data;
        } else {
          if (responseJson.data[0] === '{') {
            return JSON.parse(responseJson.data + '}');
          }
          if (responseJson.data[0] === '[') {
            return JSON.parse(responseJson.data + ']');
          }
        }
      })
      .catch(error => {
        return error;
      });
  },
  maketestApplication(data) {
    // debugger
    return ApiConfig.getApiInterfaceWithToken(data['accesstoken'])
      .post(endPoints.testApplication, data)
      .then(responseJson => {
        if (typeof responseJson.data === 'object') {
          return responseJson.data;
        } else {
          if (responseJson.data[0] === '{') {
            return JSON.parse(responseJson.data + '}');
          }
          if (responseJson.data[0] === '[') {
            return JSON.parse(responseJson.data + ']');
          }
        }
      })
      .catch(error => {
        return error;
      });
  },
  makeVisasearch(data) {
    // debugger
    return ApiConfig.getApiInterfaceWithToken()
      .post(endPoints.visaPrograms, data)
      .then(responseJson => {
        if (typeof responseJson.data === 'object') {
          return responseJson.data;
        } else {
          if (responseJson.data[0] === '{') {
            return JSON.parse(responseJson.data + '}');
          }
          if (responseJson.data[0] === '[') {
            return JSON.parse(responseJson.data + ']');
          }
        }
      })
      .catch(error => {
        return error;
      });
  },
  makeGetCountryCall() {
    // debugger
    return ApiConfig.getApiInterface()
      .get(endPoints.countryList)
      .then(responseJson => {
        if (typeof responseJson.data === 'object') {
          return responseJson.data;
        } else {
          if (responseJson.data[0] === '{') {
            return JSON.parse(responseJson.data + '}');
          }
          if (responseJson.data[0] === '[') {
            return JSON.parse(responseJson.data + ']');
          }
        }
      })
      .catch(error => {
        return error;
      });
  },
  makeSetAgencyPassword(data) {
    // debugger
    return ApiConfig.getApiInterfaceWithToken()
      .post(endPoints.visaPrograms, data)
      .then(responseJson => {
        if (typeof responseJson.data === 'object') {
          return responseJson.data;
        } else {
          if (responseJson.data[0] === '{') {
            return JSON.parse(responseJson.data + '}');
          }
          if (responseJson.data[0] === '[') {
            return JSON.parse(responseJson.data + ']');
          }
        }
      })
      .catch(error => {
        return error;
      });
  },
  makeAgencySignUpCall(data) {
    debugger
    return ApiConfig.getApiInterface()
      .post(endPoints.signUpAgency, data)
      .then(responseJson => {
        if (typeof responseJson.data === 'object') {
          return responseJson.data;
        } else {
          if (responseJson.data[0] === '{') {
            return JSON.parse(responseJson.data + '}');
          }
          if (responseJson.data[0] === '[') {
            return JSON.parse(responseJson.data + ']');
          }
        }
      })
      .catch(error => {
        return error;
      });
  },
}

