import React,{Fragment, useState, useEffect} from "react";
import Navbar  from "../../Navbar";
import Footer  from "../../Footer";
import { useTranslation } from "react-i18next";
import { Container, Form, Row, Col, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
const { config } = require('../../api/api');

function LifeSumAssured(){
  const base_url = config.baseURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();

  const [vendorKey, setVendorkey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };
    debugger

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch( base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorkey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]);

  const [dhofarData, setDhofarData] = useState(false);
  const [age, setAge] = useState(null);
  useEffect(() => {


    const storedDhofarString = Cookies.get('personalData'); //sessionStorage.getItem('userData')
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);

  //   const [buttonDisabled, setButtonDisabled] = useState(false);
  const postData = { token: dhofarData };

  const handleSubmit = async (event, premiumValue) => {

    var data = {
      "premium_valu": premiumValue,
    }

    debugger

    sessionStorage.setItem("personalaccidentpremiumcover", JSON.stringify(data))

    event.preventDefault();
    setLoading(true);

    event.preventDefault();
    window.location.href = '/lifetermplan-add-beneficiary';
  };
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [loading, setLoading] = useState(false);


  const [assure, setAssured] = useState('');
  const [price, setprice] = useState([{}]);

  useEffect(() => {
    debugger

    if (vendorKey !== '') {
      fetch(base_url + '/get_term_plan_premium', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),

      })
        .then(response => response.json())
        .then(data => {
          debugger
          setprice(data.result.data.prices);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }
  }, [vendorKey]
  );

  const NumberFormatter = ({ value }) => {

    if (value != undefined) {
      const formattedValue = `${Number(value).toFixed(3)} OMR`;
      return formattedValue;
    } else {
      const formattedValue = '';
      return formattedValue;
    }
    // Ensure value is a number and format to 3 decimal places

    debugger

  };



  return (
    <Fragment>
      <div className="header_section1">
        <Navbar />
        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Choose Plan Sum Assured' : 'اختر مبلغ الخطة المؤمن'}</h3>
          </div>
        </div>
      </div>

      <div className="features_sections">
        <div className="container">
          <div className="row">
            <Col md={3}></Col>
            <Col md={6} className="myforms my-5">
              {price.map((item, index) => (

                <div key={index}>
                  <ul style={{listStyle : 'Circle' , fontSize: '30px'}}><li>
                    <h1 className="text-bold" value={item.premium}> {item == '' || item.premium == '' ? <strong></strong> : <strong><NumberFormatter value={item.premium} /></strong>} </h1>
                    <h2 ><u>{'Sum Assured'} {item.sum_assured}</u></h2>
                  </li></ul>
                  <div>
                    <button type="submit" disabled={buttonDisabled} onClick={(event) => handleSubmit(event, item.sum_assured)} className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('ADD')}</button>
                  </div>
                  <br />
                </div>


              ))}



            </Col>
          </div>
        </div>
      </div>
      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
    </Fragment>
  );



}
export default LifeSumAssured