import React, {Fragment, useState, useEffect} from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import {Row, Col} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import Cookies from 'js-cookie';
import {Checkmark} from 'react-checkmark';
import axios from "axios";
const { config } = require('../src/api/api')

function Orderpagenew({usrtok}) {
    const base_url = config.baseURL;
    const [userData, setUserData] = useState(false);

    const {t} = useTranslation(); 
    const [order_id, setOrder] = useState('');

    // const [orderData, setOrderData] = useState(false);
    // useEffect(() => {
    //     // debugger;
    //     const storedOrderString = sessionStorage.getItem('orderResult');
    //     if (storedOrderString) {
    //         const storedOrder = JSON.parse(storedOrderString);
    //         setOrderData(storedOrder);
    //     }
    // }, []);


    // useEffect(() => {

    //     // debugger;

    //     const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
    //     if (storedUserString !== undefined) {
    //         const storedUser = JSON.parse(storedUserString);
    //         setUserData(storedUser);
    //     }
    // }, []);

    // usrtok = userData.access_token;
    // console.log(usrtok)


    const urlSearchString = window.location.search;

    const uparams = new URLSearchParams(urlSearchString);
   
//    setOrder(order);

    const [vendorKey, setVendorKey] = useState('');
    useEffect(()=>{
        const order =uparams.get('orderid');
        setOrder(order);
    },[]);

    useEffect(() => {

        const postvData = {organization_name: "Superjet"};

        if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


            fetch(base_url+'/create_organization', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postvData),
            })
                .then(response => response.json())
                .then(data => {

                    // console.log(data)
                    setVendorKey(data.result[0].vendor_key);
                    // debugger
                })
                .catch(error => {
                    console.error('Error posting data:', error);
                });


        }
    }, [vendorKey]); // Dependency array

    // console.log(vendorKey)


    const [OrderDetails, setOrderDetails] = useState('');
    const [OrderID, setOrderID] = useState('');
    const [CreatedDate, setCreatedDate] = useState('');
    const [PaymentStatus, setPaymentStatus] = useState('');
    const [OrderSts, setOrderSts] = useState('');
    const [ApplicationSts, setApplicationSts] = useState('');
    const [ApplicationID, setApplicationID] = useState('');
    const [ProcessingSts, setProcessingSts] = useState('');

    useEffect(() => {

        if (vendorKey !== '') {

            var GivenData = {
                'order_id': uparams.get('orderid'),
                'vendor_key': vendorKey
            }

            var vkData = GivenData;
            debugger

            fetch(base_url+'/each_order_details', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(vkData),
            })
                .then(response => response.json())
                .then(data => {
                    debugger
                    console.log(data.result.order)

                    setOrderDetails(data.result.order)
                    setOrderID(data.result.order.id)
                    setCreatedDate(data.result.order.created_at)
                    setPaymentStatus(data.result.order.payment_status)
                    setOrderSts(data.result.order.order_status)
                    setApplicationSts(data.result.order.applications[0].application_status)
                    setApplicationID(data.result.order.applications[0].id)
                    setProcessingSts(data.result.order.applications[0].processing_status)

                })
                .catch(error => {
                    console.error('Error posting data:', error);
                });
        }
    }, [usrtok, vendorKey, uparams.get('order_id')]);

    return (
        <div className="App">


            <div className="header_section1">

                <Navbar/>


                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{t('Order')} {t('Details')}</h3>
                    </div>
                </div>


            </div>


            <div className="features_section layout_padding my-5">
                <div className="container">

                    <div className="row">

                        <Col md={3} className="text-center">
                        </Col>

                        <Col md={6} className="m-2 mt-4">

                            <Checkmark/>
                            <br/>
                            <h3 className="h3" style={{textAlign: 'center'}}>

                                {t('Order')} {t('Placed')} {t('Successfully')}

                            </h3>

                            {/*<p style={{textAlign: 'center'}}>Order No : <b>{uparams.get('order_id')}</b></p>*/}
                            <br/>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <tr>
                                    <td style={{textAlign: 'left', width: '30%', fontWeight: 'bold', padding: '8px'}}>
                                        Order ID
                                    </td>
                                    <td style={{width: '1%', textAlign: 'left', fontWeight: 'bold', padding: '8px'}}>
                                        :
                                    </td>
                                    <td style={{textAlign: 'left', width: '69%', padding: '8px'}}>
                                        <b>{order_id}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{
                                        textAlign: 'left',
                                        width: '30%',
                                        fontWeight: 'bold',
                                        padding: '8px'
                                    }}>First Name
                                    </td>
                                    <td style={{width: '1%', textAlign: 'left', fontWeight: 'bold', padding: '8px'}}>
                                        :
                                    </td>
                                    <td style={{textAlign: 'left', width: '69%', padding: '8px'}}>
                                        <b>{OrderDetails.first_name}</b></td>
                                </tr>
                                <tr>
                                    <td style={{
                                        textAlign: 'left',
                                        width: '30%',
                                        fontWeight: 'bold',
                                        padding: '8px'
                                    }}>Last Name
                                    </td>
                                    <td style={{width: '1%', textAlign: 'left', fontWeight: 'bold', padding: '8px'}}>
                                        :
                                    </td>
                                    <td style={{textAlign: 'left', width: '69%', padding: '8px'}}>
                                        <b>{OrderDetails.last_name}</b></td>
                                </tr>
                                <tr>
                                    <td style={{
                                        textAlign: 'left',
                                        width: '30%',
                                        fontWeight: 'bold',
                                        padding: '8px'
                                    }}>Email
                                    </td>
                                    <td style={{width: '1%', textAlign: 'left', fontWeight: 'bold', padding: '8px'}}>
                                        :
                                    </td>
                                    <td style={{textAlign: 'left', width: '69%', padding: '8px'}}>
                                        <b>{OrderDetails.email}</b></td>
                                </tr>
                                <tr>
                                    <td style={{
                                        textAlign: 'left',
                                        width: '30%',
                                        fontWeight: 'bold',
                                        padding: '8px'
                                    }}>Arrival
                                    </td>
                                    <td style={{width: '1%', textAlign: 'left', fontWeight: 'bold', padding: '8px'}}>
                                        :
                                    </td>
                                    <td style={{textAlign: 'left', width: '69%', padding: '8px'}}>
                                        <b>{OrderDetails.arrival}</b></td>
                                </tr>
                                <tr>
                                    <td style={{
                                        textAlign: 'left',
                                        width: '30%',
                                        fontWeight: 'bold',
                                        padding: '8px'
                                    }}>Destination
                                    </td>
                                    <td style={{width: '1%', textAlign: 'left', fontWeight: 'bold', padding: '8px'}}>
                                        :
                                    </td>
                                    <td style={{textAlign: 'left', width: '69%', padding: '8px'}}>
                                        <b>{OrderDetails.destination}</b></td>
                                </tr>
                                {/* <tr>
                                    <td style={{
                                        textAlign: 'left',
                                        width: '30%',
                                        fontWeight: 'bold',
                                        padding: '8px'
                                    }}>Birth Country
                                    </td>
                                    <td style={{width: '1%', textAlign: 'left', fontWeight: 'bold', padding: '8px'}}>
                                        :
                                    </td>
                                    <td style={{textAlign: 'left', width: '69%', padding: '8px'}}>
                                        <b>{OrderDetails.birthcountry}</b></td>
                                </tr>
                                <tr>
                                    <td style={{
                                        textAlign: 'left',
                                        width: '30%',
                                        fontWeight: 'bold',
                                        padding: '8px'
                                    }}>Citizenship Country
                                    </td>
                                    <td style={{width: '1%', textAlign: 'left', fontWeight: 'bold', padding: '8px'}}>
                                        :
                                    </td>
                                    <td style={{textAlign: 'left', width: '69%', padding: '8px'}}>
                                        <b>{OrderDetails.citizenshipcountry}</b></td>
                                </tr>
                                <tr>
                                    <td style={{
                                        textAlign: 'left',
                                        width: '30%',
                                        fontWeight: 'bold',
                                        padding: '8px'
                                    }}>Destination
                                    </td>
                                    <td style={{width: '1%', textAlign: 'left', fontWeight: 'bold', padding: '8px'}}>
                                        :
                                    </td>
                                    <td style={{textAlign: 'left', width: '69%', padding: '8px'}}>
                                        <b>{OrderDetails.destination}</b></td>
                                </tr>
                                <tr>
                                    <td style={{
                                        textAlign: 'left',
                                        width: '30%',
                                        fontWeight: 'bold',
                                        padding: '8px'
                                    }}>Description
                                    </td>
                                    <td style={{width: '1%', textAlign: 'left', fontWeight: 'bold', padding: '8px'}}>
                                        :
                                    </td>
                                    <td><b>{OrderDetails.description}</b></td>
                                </tr>
                                <tr>
                                    <td style={{
                                        textAlign: 'left',
                                        width: '30%',
                                        fontWeight: 'bold',
                                        padding: '8px'
                                    }}>DOB
                                    </td>
                                    <td style={{width: '1%', textAlign: 'left', fontWeight: 'bold', padding: '8px'}}>
                                        :
                                    </td>
                                    <td style={{textAlign: 'left', width: '69%', padding: '8px'}}>
                                        <b>{OrderDetails.dob}</b></td>
                                </tr>
                                <tr>
                                    <td style={{
                                        textAlign: 'left',
                                        width: '30%',
                                        fontWeight: 'bold',
                                        padding: '8px'
                                    }}>Commission Type
                                    </td>
                                    <td style={{width: '1%', textAlign: 'left', fontWeight: 'bold', padding: '8px'}}>
                                        :
                                    </td>
                                    <td><b>{OrderDetails.commission_type}</b></td>
                                </tr>
                                <tr>
                                    <td style={{
                                        textAlign: 'left',
                                        width: '30%',
                                        fontWeight: 'bold',
                                        padding: '8px'
                                    }}>Reference
                                    </td>
                                    <td style={{width: '1%', textAlign: 'left', fontWeight: 'bold', padding: '8px'}}>
                                        :
                                    </td>
                                    <td style={{textAlign: 'left', width: '69%', padding: '8px'}}>
                                        <b>{OrderDetails.reference}</b></td>
                                </tr>
                                <tr>
                                    <td style={{
                                        textAlign: 'left',
                                        width: '30%',
                                        fontWeight: 'bold',
                                        padding: '8px'
                                    }}>Sex
                                    </td>
                                    <td style={{width: '1%', textAlign: 'left', fontWeight: 'bold', padding: '8px'}}>
                                        :
                                    </td>
                                    <td style={{textAlign: 'left', width: '69%', padding: '8px'}}>
                                        <b>{OrderDetails.sex}</b></td>
                                </tr> */}
                            </table>
                            {/*<p>Order ID: <b>{OrderDetails.order_id}</b></p>*/}
                            {/*<p>First Name: <b>{OrderDetails.first_name}</b></p>*/}
                            {/*<p>Last Name: <b>{OrderDetails.last_name}</b></p>*/}
                            {/*<p>Email: <b>{OrderDetails.email}</b></p>*/}
                            {/*<p>Arrival: <b>{OrderDetails.arrival}</b></p>*/}
                            {/*<p>Birth country: <b>{OrderDetails.birthcountry}</b></p>*/}
                            {/*<p>Citizenship country: <b>{OrderDetails.citizenshipcountry}</b></p>*/}
                            {/*<p>destination: <b>{OrderDetails.destination}</b></p>*/}
                            {/*<p>description: <b>{OrderDetails.description}</b></p>*/}
                            {/*<p>DOB: <b>{OrderDetails.dob}</b></p>*/}
                            {/*<p>commission_type: <b>{OrderDetails.commission_type}</b></p>*/}
                            {/*<p>reference: <b>{OrderDetails.reference}</b></p>*/}
                            {/*<p>sex: <b>{OrderDetails.sex}</b></p>*/}


                        </Col>

                        <Col md={12} className="text-center" style={{paddingTop:'40px'}}>
                        <p ><a style={{color:'green', textAlign:'right', fontWeight:'bold', paddingTop:'20px', fontSize:'22px',textDecoration: 'underline'}} href={`/visa`}>Back Home</a></p>
                        </Col>


                    </div>

                </div>
            </div>

            <Footer/>

        </div>
    );
}

export default Orderpagenew;