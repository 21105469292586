import React,{Fragment, useState, useEffect} from "react";
import Navbar  from "../Navbar";
import Footer  from "../Footer";
import { useTranslation } from "react-i18next";
import { Container, Form, Row, Col, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import DownloadPolicy from './Policy_Wording.pdf'

function CyberChosePlan(){
    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const { t } = useTranslation();

    const [vendorKey, setVendorKey] = useState('');

    useEffect(() => {
  
      const postvData = { organization_name: "Superjet" };
  
      if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
  
  
        fetch('https://api.superjetom.com/create_organization', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postvData),
        })
          .then(response => response.json())
          .then(data => {
            setVendorKey(data.result[0].vendor_key);
          })
          .catch(error => {
            console.error('Error posting data:', error);
          });
  
  
      }
    }, [vendorKey]);

    const [dhofarData, setDhofarData] = useState(false);
  const [age, setAge] = useState(null);
  useEffect(() => {


    const storedDhofarString = Cookies.get('dhofarData'); //sessionStorage.getItem('userData')
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);

//   const [buttonDisabled, setButtonDisabled] = useState(false);
  const postData = { token: dhofarData };

    const items =[{ amount: '24.000', description: 'Sum Assured 5,000' },
        { amount: '15.000', description: 'Sum Assured 3,000' },
        { amount: '10.000', description: 'Sum Assured 1,000' },];
    
    const items1 = [
          { amount: '1500 OMR', description: 'Online theft of personal funds' },
          { amount: '500 OMR', description: 'Online Shopping Fraud' },
          { amount: '500 OMR', description: 'Online Sales Fraud' },
          { amount: '150 OMR', description: 'Loss from Cyber Bullying or Defamation' },
          { amount: '500 OMR', description: 'Recovery of Insure Data' },
          { amount: '500 OMR', description: 'Cyber Extortion' },
      ];

    const handleSubmit = async (event) => {

            event.preventDefault();
            setLoading(true);
            
            event.preventDefault();
            window.location.href='/cyber-personalinformation';
          };
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [loading, setLoading] = useState(false);


    const [assure, setAssured] = useState('');
  const [premium, setpermium] = useState('');

  useEffect(() => {
    debugger

    if (vendorKey !== '') {
      fetch('https://api.superjetom.com/get_personal_accident_premium', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),

      })
        .then(response => response.json())
        .then(data => {
          debugger
          setpermium(data.result.data.premium);
          setAssured(data.result.data.sum_assured);

        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }
  }, [vendorKey]
  );

  const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };


    const Popup = ({ onClose }) => {

      return (
          <div style={popupStyle}>
              <h2 className="text-center">Product 1</h2>
              <ul>
        {items1.map((item, index) => (
          <li key={index}>
            {item.description} - {item.amount} OMR
          </li>
        ))}
      </ul>
              <div>
              <button onClick={onClose}>Close</button>
              </div>
          </div>
      );
  };
  
  // Popup Style
  const popupStyle = {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      background: 'white',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      zIndex: 1000,
      borderRadius: '8px',
  };



    return(
        <Fragment>
            <div className="header_section1">
                <Navbar/>
                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Cyber Plan':'خطة سايبر'}</h3>
                    </div>
                </div>
            </div>

<div className="features_sections">
            <div className="container">
                <div className="row">
                    <Col md={3}></Col>
                    <Col md={6} className="myforms my-5">
                        {items.map((item, index) => (
                            <div key={index} className="d-flex justify-content-between my-3">
                               
                              <div className="px-0 py-1">
                              <ul>
                                <li>
                              <h1 className="text-bold px-0 py-1">
                                    <strong>{item.amount} {"OMR"}</strong>
                                </h1>
                                <h2 className="px-0 py-1">
                                    <u>Product {index + 1}</u>
                                </h2>
                                <a href="#" onClick={togglePopup}>
                                    <u>View More</u>
                                </a>
                                </li>
                                </ul>
                              </div>
                               
                                <div>
                                    <button 
                                        type="submit" 
                                        disabled={buttonDisabled} 
                                        onClick={handleSubmit} 
                                        className="btn btn-primary rounded-pill text-white my-1 py-1 px-4"
                                    >
                                        {t('ADD')}
                                    </button>
                                </div>
                            </div>
                        ))}
                        <a href={DownloadPolicy} download={DownloadPolicy}>Download Policy Wording</a>
                    </Col>
                </div>
            </div>

            {/* Popup Component */}
            {isOpen && <Popup onClose={togglePopup} />}
        </div>








            <Footer />

            {loading && (
                <div className="overlay">
                <div className="spinner"></div>
                </div>
            )}
        </Fragment>
    );


}
export default CyberChosePlan