export const APP_SUBMIT_FORM = 'APP_SUBMIT_FORM';
export const APP_SUBMIT_FORM_FAIL = 'APP_SUBMIT_FORM_FAIL';
export const APP_SUBMIT_FORM_SUCCESS = 'APP_SUBMIT_FORM_SUCCES';
export const APP_FORM_SUBMITTED = 'APP_FORM_SUBMITTED';

export const submitForm = (formData, access_token) => ({
  
  type: APP_SUBMIT_FORM,
  payload: formData,
  access_token: access_token
});



export const formSubmitted = () => ({
  type: APP_FORM_SUBMITTED,
});
