import React, { Fragment, useEffect, useState, useRef } from "react";
import { Container, Row, Col, Card, CardBody } from "react-bootstrap";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { submitForm } from './redux/actions/actions';
import Cookies from 'js-cookie';
import CookieConsent from '../CookieConsent';

const Applicationiframe = () => {
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();

  const [userData, setUserData] = useState(false);
  const [deepLink, setDeepLink] = useState('');
  const iframeRef = useRef(null);
  useEffect(() => {
    const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
    if (storedUserString !== undefined) {
      const storedUser = JSON.parse(storedUserString);
      setUserData(storedUser);
    }
    else {
      alert("login session expired");
      window.location.href = '/';
    }
  }, []);


  useEffect(() => {
    const deepLinkString = sessionStorage.getItem('deeplink');
    if (deepLinkString) {
      const deepLinkSearch = JSON.parse(deepLinkString);

      var link = deepLinkSearch?.result?.iframe_deeplink_url;
      setDeepLink(link);
    }
  }, []);


  const [applicationData, setapplicationData] = useState(false);
  useEffect(() => {
    const storedapplicationData = sessionStorage.getItem('applicationData');
    if (storedapplicationData !== "undefined") {
      const storedAppData = JSON.parse(storedapplicationData);
      setapplicationData(storedAppData);
    }
  }, []);


  const [plink, setpLink] = useState('');
  useEffect(() => {
    const storedplink = sessionStorage.getItem('viplink');
    if (storedplink !== "undefined") {
      const storedplnkData = JSON.parse(storedplink);
      setpLink(storedplnkData);
    }
  }, []);


  useEffect(() => {
    debugger
    // Listen for postMessage from the iframe
    const messageListener = (event) => {
      debugger
      if (event?.data?.type === 'formSubmitted') {
        sessionStorage.setItem("event_data", JSON.stringify(event?.data));
        window.location.href = '/make-payment';
        // debugger
        // // Hide the iframe
        // document.getElementById('visaApplicationForm').style.display = 'none';

        // // Show the next step
        // document.getElementById('nextStep').style.display = 'block';

        // // Use the order_id received from the iframe
        // setupPaymentLink(event.data.orderId);
      }
    };

    window.addEventListener('message', messageListener);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  // Setup payment link using the orderId
  const setupPaymentLink = (orderId) => {
    console.log('Setting up payment link for visa application:', orderId);
    // Implement your logic to set up the payment link using the orderId
  };

  // Proceed to payment logic
  const proceedToPayment = () => {
    console.log('Proceeding to payment for visa application');
    // Implement your logic to proceed to payment
  };








  return (

    <Fragment>

      <div className="header_section1">

        <Navbar />

      </div>
      {/* Other components */}
      <CookieConsent />

      <div style={{ margin: 0, padding: 0 }}>
        <iframe
          id="visaApplicationForm"
          src={deepLink}
          title="Visa"
          style={{
            width: '100vw',
            height: '100vh',
            border: 'none',
            display: 'block', // Removes the iframe's bottom gap
          }}
        />

      </div>
      <div id="nextStep" style={{ display: 'none' }}>
        {/* Content for the next step */}
        <h2>Payment Setup</h2>
        {/* More content here */}
      </div>




      <Footer />
    </Fragment>
  );

};

export default Applicationiframe;