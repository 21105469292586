// CookieConsent.js
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const CookieConsent = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const consent = Cookies.get('cookie_consent');
        if (!consent) {
            setVisible(true);
        }
    }, []);
    useEffect(() => {
        // Set a cookie when the component mounts
        Cookies.set('myCookie', 'value', { expires: 7, sameSite: 'None', secure: true });
    }, []);

    const acceptCookies = () => {
        Cookies.set('cookie_consent', 'accepted', { expires: 365 });
        setVisible(false);
    };

    if (!visible) return null;

    return (
        <div style={{ position: 'fixed', bottom: 0, width: '100%', background: '#f1f1f1', padding: '10px', textAlign: 'center' }}>
            <p>This website uses cookies to enhance the user experience. By accepting cookies, you agree to our use of cookies.</p>
            <button onClick={acceptCookies}>Accept All Cookies</button>
        </div>
    );
};

export default CookieConsent;