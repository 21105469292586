import React,{Fragment, useState, useEffect} from "react";
import Navbar  from "../Navbar";
import Footer  from "../Footer";
import { useTranslation } from "react-i18next";
import { Container, Form, Row, Col, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import MedicalDownload from './Mednet_network_list.xlsx';
import  Table_Of_Benifits from './Download_Table_Of_Benifits.pdf';
import Download_Exclusion_List from './Download_Exclusion_List.pdf';
import Download_Policy_Wording from './Download_Policy_Wording.pdf';
import Download_Covid_terms_and_condition from './Download_Covid-19_terms_and_condition.pdf';

function MedicalSumAssured(){
    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const { t } = useTranslation();

    const [vendorKey, setVendorKey] = useState('');

    useEffect(() => {
  
      const postvData = { organization_name: "Superjet" };
  
      if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
  
  
        fetch('https://api.superjetom.com/create_organization', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postvData),
        })
          .then(response => response.json())
          .then(data => {
            setVendorKey(data.result[0].vendor_key);
          })
          .catch(error => {
            console.error('Error posting data:', error);
          });
  
  
      }
    }, [vendorKey]);

    const [dhofarData, setDhofarData] = useState(false);
  const [age, setAge] = useState(null);
  useEffect(() => {


    const storedDhofarString = Cookies.get('dhofarData'); //sessionStorage.getItem('userData')
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);

//   const [buttonDisabled, setButtonDisabled] = useState(false);
  const postData = { token: dhofarData };

    const items =[{ amount: '24.000 ', description: ' 5,000' , plan : 'Green' },
        { amount: '15.000 ', description: ' 3,000' , plan : 'Silver' },
        { amount: '10.000 ', description: ' 1,000' , plan : 'Gold' },];

    const handleSubmit = async (event) => {

            event.preventDefault();
            setLoading(true);
            
            event.preventDefault();
            window.location.href='/medical-limits';
          };
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [loading, setLoading] = useState(false);


    const [assure, setAssured] = useState('');
  const [premium, setpermium] = useState('');

  useEffect(() => {
    debugger

    if (vendorKey !== '') {
      fetch('https://api.superjetom.com/get_personal_accident_premium', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),

      })
        .then(response => response.json())
        .then(data => {
          debugger
          setpermium(data.result.data.premium);
          setAssured(data.result.data.sum_assured);

        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }
  }, [vendorKey]
  );



    return(
        <Fragment>
            <div className="header_section1">
                <Navbar/>
                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Choose your Plan':'اختر خطتك'}</h3>
                    </div>
                </div>
            </div>

            <div className="features_sections">
                <div className="container">
                    <div className="row">
                        <Col md={3}></Col>
                        <Col md={6} className="myforms my-5">
                        {items.map((item, index) => (
                          
                            <div key={index}>
                                <p>Plan starting from</p>
                              <ul><li>
                            <h1 className="text-bold"><strong>{item.amount} {"OMR"}</strong></h1>
                            <h2 ><b><u>{item.plan} Plan</u></b></h2>
                            <p>{item.plan} Network with Annual Limit between {item.amount} to 5,000 OMR</p>
                            
                            </li></ul>
                            <a herf ={MedicalDownload} download='Mednet network list.xlsx'>Download Network List</a>
                            <div>
                            <button type="submit" disabled={buttonDisabled} onClick={handleSubmit}  className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('ADD')}</button>
                            </div>
                            <br/>   
                            </div> 
                            
                                
                        ))}  
                        
                       <div><a href={Table_Of_Benifits} download='Download_Table_Of_Benifits.pdf'>Download Table Of Benifits</a></div> 
                       <div><a href={Download_Exclusion_List} download='Download_Exclusion_List.pdf'>Download Exclusion List</a></div>
                       <div><a href={Download_Policy_Wording} download='Download_Policy_Wording.pdf'>Download Policy Wording</a></div>
                       <div><a href={Download_Covid_terms_and_condition} download='Download_Covid-19_terms_and_condition.pdf'>Download Covid-19 terms and condition</a></div> 
                                       
                        </Col>                        
                    </div>
                </div>
            </div>
            <Footer />

            {loading && (
                <div className="overlay">
                <div className="spinner"></div>
                </div>
            )}
        </Fragment>
    );


}
export default MedicalSumAssured