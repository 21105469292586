import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('./api/api');

function DownloadOutboundPolicy() {
  const pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)(:[0-9]{1,5})?(\/.*)?$/;

  const base_url = config.baseURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();

  const [dhofarData, setDhofarData] = useState(null);
  const [policyNo, setPolicyNo] = useState(null);
  const [policyLink, setPolicyLink] = useState(null);

  useEffect(() => {
    debugger
    const urlSearchString = window.location.search;
    const uparams = new URLSearchParams(urlSearchString);
    const policy_no = uparams.get('policyno');

    setPolicyNo(policy_no);

  }, []);




  const [vendorKey, setVendorKey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch(base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }

    if (vendorKey !== '' && vendorKey !== null && vendorKey !== undefined) {
      fetch(base_url + '/create_outbound_token', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
        }
      })
        .then(response => response.json())
        .then(data => {

          debugger

          const dhdata = data.result.data.token;

          const expiryDate = new Date();
          // expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));

          // Cookies.set('dhofarData', JSON.stringify(dhdata), { expires: expiryDate });
          setDhofarData(dhdata);



        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }

  }, [vendorKey]); // Dependency array



  const redirect_url = "https://superjetom.com/";

  // const [paymentUrl, setPaymenturl] = useState('');

  const postData = [{ token: dhofarData }, { policy_number: policyNo }];

  if (vendorKey !== '' && policyNo !== null && dhofarData !== null && dhofarData !== '') {

    fetch(base_url + '/get_outbound_policy_documents', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${vendorKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.message == 'success') {
          setPolicyLink(data.result.data.policy_schedule_url);
        } else {
          setPolicyLink('')
        }
        debugger
        // setPaymenturl(data.result.data.payment_url);
      })
      .catch(error => {
        console.error('Error posting data:', error);
      });

  }
  const isValidUrl = (url) => {
    return pattern.test(url);
  };
  return (
    <Fragment>
      <Navbar />
      <div className="features_section layout_padding my-5">
        {policyLink ? (
          <div className="container-fluid bg-breadcrumb">
            <div className="container text-center">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="abt-txt">Download Policy {policyNo}</h3>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                <h3 className="abt-txt">Policy Status {policyNo}</h3>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <div className="text-center">
              {policyLink ? (
                isValidUrl(policyLink) ? (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      var url = policyLink;
                      const link = document.createElement('a');
                      link.href = url;
                      link.target = '_blank';
                      link.download = url.split('/').pop(); // Extracts filename from URL
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }}
                  >
                    Download Policy Document
                  </button>
                ) : (
                  <h2>Sorry, the policy document is not available.</h2>
                )
              ) : policyLink === '' ? (
                <h2>Policy Payment Pending</h2>
              ) : (
                "Loading"
              )}
            </div>
          </div>
        </div>

      </div>
      <Footer />

    </Fragment>
  );
}

export default DownloadOutboundPolicy;