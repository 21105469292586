import { AGENCY_REGISTER, AGENCY_REGISTER_FAIL, AGENCY_REGISTER_SUCCESS, FORM_SUBMITTED, SUBMIT_FORM_FAIL, SUBMIT_FORM_SUCCESS } from '../actions/actions';

const initialState = {
  submitted: false,
  message: null,
  signupdata: null,
  error: null,

};

const formReducer = (state = initialState, action) => {
//   debugger
  switch (action.type) {
    case FORM_SUBMITTED:
      return {
        ...state,
        message: null,
        signupdata: null,
        error: null
      };
      case SUBMIT_FORM_FAIL:
        // debugger
        return {
          ...state,
          signupdata: null,
          message: action.result.message,
          error: null
        };
        case SUBMIT_FORM_SUCCESS:
          // debugger
          return {
            ...state,
            signupdata: action.result.result[0],
            message: action.result.message,
            error: null
          };
          case AGENCY_REGISTER:
      return {
        ...state,
        message: null,
        signupdata: null,
        error: null
      };
      case AGENCY_REGISTER_FAIL:
        return {
          ...state,
          signupdata: null,
          message: action.result.message,
          error: null
        };
        case AGENCY_REGISTER_SUCCESS:
          debugger
          return {
            ...state,
            signupdata: action.result.result[0],
            message: action.result.message,
            error: null
          };
    default:
      return state;
  }
};

export default formReducer;