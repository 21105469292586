import { all } from 'redux-saga/effects';
import formSaga from './signup/redux/sagas/sagas';
import loginSaga from './login/redux/sagas/sagas';
import appSaga from './application/redux/sagas/sagas';
import searchSaga from './search/redux/sagas/sagas';

export default function* rootSaga() {
  yield all([formSaga(), loginSaga(), appSaga(), searchSaga()]);
  // yield all([loginSaga()]);
}
