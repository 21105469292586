import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "react-bootstrap";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { submitForm } from './redux/actions/actions';
import Cookies from 'js-cookie';

const Verify = ({ onSubmitForm, message, appdata, error  }) => {

    const { t } = useTranslation();

  const [userData, setUserData] = useState(false);
  
  useEffect(() => {
  const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
    if (storedUserString!==undefined) {
      const storedUser = JSON.parse(storedUserString);
      setUserData(storedUser);
    }
    else
    {
      alert("login session expired");
      window.location.href='/';
    }
  }, []);



  const [applicationData, setapplicationData] = useState(false);
  useEffect(() => {
  const storedapplicationData = sessionStorage.getItem('applicationData');
    if (storedapplicationData!=="undefined") {
      const storedAppData = JSON.parse(storedapplicationData);
      setapplicationData(storedAppData);
    }
  }, []);


  const [plink, setpLink] = useState('');
  useEffect(() => {
  const storedplink = sessionStorage.getItem('viplink');
    if (storedplink!=="undefined") {
      const storedplnkData = JSON.parse(storedplink);
      setpLink(storedplnkData);
    }
  }, []);


  const [loading, setLoading] = useState(false);
    
      const handleSubmit = (e) => {
        // debugger;
        e.preventDefault();

        var data   = {

          'program_id': applicationData.program_id,
          'reference': applicationData.reference,
          'payment_method_id': applicationData.payment_method_id, //formData.payment_method_id
          'description': applicationData.description,
          'quantity': '1',
          'accesstoken': userData.access_token,
          'firstName': applicationData.firstName,
          'lastName': applicationData.lastName,
          'dob': applicationData.dob,
          'birthCountry': applicationData.birthCountry,
          'citizenshipCountry': applicationData.citizenshipCountry,
          'sex': applicationData.sex,
          'email': applicationData.email,
          'phoneA': applicationData.phoneA,
          'phoneP': applicationData.phoneP,
          'passportNumber': applicationData.passportNumber,
          'passportIssuingCountry': applicationData.passportIssuingCountry,
          'passportIssuingDate': applicationData.passportIssuingDate,
          'passportExpiringDate': applicationData.passportExpiringDate,
          'transit': applicationData.transit,
          'applicantEmployed': applicationData.applicantEmployed,
          'fee': applicationData.fee,
          'commission': applicationData.commission,
          'destination': applicationData.destination,
          'arrival': applicationData.arraival,
          'commission_type': applicationData.commissiontype
        }

        onSubmitForm(data, userData.access_token);

        setLoading(true);
      };

        if(message)
        {

        if(message==='order placed succesfully')
        {
        const apdata = appdata;
      
        if(apdata!=='')
        {
        sessionStorage.setItem("orderResult", JSON.stringify(apdata));
        }
      
        window.location.href = `/Orderpage`;
        }
        else
        {
          alert(message);
          window.location.href = `/`;
        }
      
        }


    return (

    <Fragment>

      <div className="header_section1">

      <Navbar />


      <div className="container-fluid bg-breadcrumb">
            <div className="container text-center py-5">            
                <h3 className="abt-txt my-5">{t('Verify')} {t('Your')} {t('Details')}</h3>
            </div>
        </div>


      </div>
       
 
        <div className="features_section layout_padding">
        <div className="container">
            
            <div className="row">

                <Col md={3}></Col>

<Col md={6} className="myforms my-1">


                            <span style={{ color: 'red', fontSize: '23px' }}> {(message === 'success') ? 'Application Send!' : '' }</span>


    <form onSubmit={handleSubmit}>



                            <table style={{ lineHeight: 2 }}>
                              <tbody>
                              <tr><td>{t('First')} {t('Name')}</td><td className="coloncolumn">:</td><td><b>{applicationData.firstName}</b></td></tr>
                              <tr><td>{t('Last')} {t('Name')}</td><td className="coloncolumn">:</td><td><b>{applicationData.lastName}</b></td></tr>
                              <tr><td>{t('DOB')}</td><td className="coloncolumn">:</td><td><b>{(applicationData.dob) ? format(new Date(applicationData.dob), 'dd-MM-yyyy') : '' }</b></td></tr>
                              <tr><td>{t('Birth')} {t('Country')}</td><td className="coloncolumn">:</td><td><b>{applicationData.birthCountry}</b></td></tr>
                              <tr><td>{t('Citizenship')}</td><td className="coloncolumn">:</td><td><b>{applicationData.citizenshipCountry}</b></td></tr>
                              <tr><td>{t('Gender')}</td><td className="coloncolumn">:</td><td><b>{applicationData.sex}</b></td></tr>
                              <tr><td>{t('Email')}</td><td className="coloncolumn">:</td><td><b>{applicationData.email}</b></td></tr>
                              <tr><td>{t('Phone')}</td><td className="coloncolumn">:</td><td><b>{applicationData.phoneA} {applicationData.phoneP}</b></td></tr>
                              <tr><td>{t('Passport')} {t('Number')}</td><td className="coloncolumn">:</td><td><b>{applicationData.passportNumber}</b></td></tr>
                              <tr><td>{t('Passport')} {t('Issuing')} {t('Country')}</td><td className="coloncolumn">:</td><td><b>{applicationData.passportIssuingCountry}</b></td></tr>
                              <tr><td>{t('Passport')} {t('Issuing')} {t('Date')}</td><td className="coloncolumn">:</td><td><b>{(applicationData.passportIssuingDate) ? format(new Date(applicationData.passportIssuingDate), 'dd-MM-yyyy') : '' }</b></td></tr>
                              <tr><td>{t('Passport')} {t('Expiry')} {t('Date')}</td><td className="coloncolumn">:</td><td><b>{(applicationData.passportExpiringDate) ? format(new Date(applicationData.passportExpiringDate), 'dd-MM-yyyy') : '' }</b></td></tr>
                              <tr><td>{t('Employment')} {t('Status')}</td><td className="coloncolumn">:</td><td><b>{applicationData.applicantEmployed}</b></td></tr>
                              <tr><td>{t('Visa')} {t('Fee')}</td><td className="coloncolumn">:</td><td><b>{applicationData.fee}</b></td></tr>
                              </tbody>
                            </table>
                            
                            <a href={(plink) ? plink : ''}>
       <button type="button" className="btn btn-primary rounded-pill text-white my-2 py-1 px-4">{t('Proceed')} {t('Payment')}</button></a>

    </form>

                      

                    </Col>

<Col md={3}></Col>

</div>
            
            </div>
          </div>

    <Footer />

    {loading && (
      <div className="overlay">
        <div className="spinner"></div>
      </div>
    )}

    </Fragment>

);

};

const mapStateToProps = state => ({
    message: state.application.message,
    appdata: state.application.appdata,
    error: state.application.error
  });
  
  const mapDispatchToProps = (dispatch) => ({
      onSubmitForm: (formData) => dispatch(submitForm(formData)),
    });
    
    export default connect(mapStateToProps, mapDispatchToProps)(Verify);