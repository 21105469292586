import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

function About() {

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';



    const { t } = useTranslation();

  return (
    <div>

        <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
            <div className="container text-center py-5">            
                <h3 className="abt-txt my-5">{t('THE COMPANY')}</h3>
            </div>
        </div>


        </div>
        

        <div className="features_section layout_padding my-1">
        <div className="container">


                <div className="row g-4 justify-content-center">
                    
                    <div className="col-12 p-0 mt-3 wow fadeInUp" data-wow-delay="0.2s">

                        {(lnstr==='en') ? '' : <div className={lnstr}><b style={{ color: "#33d0f2" }}>من نحن</b></div>  }

                        {(lnstr==='en') ? <div className={lnstr}>SuperJet is the first company in the GCC to provide integrated eVisa and comprehensive insurance services through advanced APIs. Our pioneering approach enhances customer journeys and boosts revenue for our clients. We aim to be the leading provider of integrated digital solutions for visas and comprehensive insurance in the GCC, continually expanding our services to meet and exceed the evolving needs of our diverse clientele.</div>
                        :
                        <div className={lnstr}>تعتبر SuperJet الشركة الأولى في دول الخليج العربي التي تقدم خدمات التأشيرات الإلكترونية المتكاملة والتأمين الشامل من خلال API المتقدمة لدينا. منهجنا الرائد يعزز تجربة العملاء ويزيد من الإيرادات لعملائنا. نحن نسعى لأن نكون المزودين الرائدين للحلول الرقمية المتكاملة للتأشيرات والتأمين الشامل في الخليج، مع التوسع المستمر في خدماتنا لتلبية وتجاوز احتياجات عملائنا المتنوعة.
                        </div>}

                        <br />

                        {(lnstr==='en') ? 
                        <div className={lnstr}>
                            <b style={{ color: "#33d0f2" }}>Vision:</b>
                        </div>
                        :
                        <div className={lnstr}>
                            <b style={{ color: "#33d0f2" }}>الرؤية:</b>
                            <br />
                        </div>
                        }
                        
                        
                        {(lnstr==='en') ? <div className={lnstr}>To revolutionize travel and insurance services in the GCC through innovative technology, making essential services more accessible while enhancing security and convenience for all users.</div>
                        :
                        <div className={lnstr}> ثورة خدمات السفر والتأمين في دول الخليج العربي من خلال التكنولوجيا المبتكرة، مما يجعل الخدمات الأساسية أكثر وصولاً مع تعزيز الأمان والراحة لجميع المستخدمين.
                        </div>}

<br />

                        {(lnstr==='en') ?
                            <div className={lnstr}>
                                <b style={{ color: "#33d0f2" }}>Mission:</b>
                            </div>
                            :
                            <div className={lnstr}>
                                <b style={{ color: "#33d0f2" }}>المهمة:</b>
                                <br />
                            </div>
                        }


                        
                        {(lnstr==='en') ?
                            <div className={lnstr}>
                                <ul className="custom-list">
                                    <li> To become the leading provider of integrated eVisa and insurance services in the GCC.</li>
                                    <li> To enhance the experience for customers through seamless digital solutions.</li>
                                    <li> To partner with airlines, banks, travel agencies, fintech, telcos, and utility aggregators to enhance and expand services.</li>
                                    <li> To drive significant growth in revenue for partners.</li>
                                </ul>
                            </div>
                        :
                        <div className={lnstr}>
                            <ul>
                                <li>أن نصبح المزود الرائد لخدمات التأشيرات الإلكترونية والتأمين في دول الخليج.</li>
                            <li>تحسين تجربة العملاء من خلال الحلول الرقمية السلسة.</li>
                            <li>الشراكة مع شركات الطيران والبنوك ووكالات السفر والتكنولوجيا المالية وشركات الاتصالات ومزودي الخدمات العامة لتعزيز وتوسيع الخدمات.</li>
                            <li>تحقيق نمو كبير في الإيرادات للشركاء.</li>
                            </ul>
                        </div>}
                        <br />


                        {(lnstr==='en') ?
                            <div className={lnstr}>
                                <b style={{ color: "#33d0f2" }}>Values:</b>
                            </div>
                            :
                            <div className={lnstr}>
                                <b style={{ color: "#33d0f2" }}>القيم:</b>
                                <br />
                            </div>
                        }



                        {(lnstr==='en') ?
                            <div className={lnstr}>
                                <ul className="custom-list">
                                    <li> Innovation</li>
                                    <li> Customer-Centricity</li>
                                    <li> Integrity</li>
                                    <li> Collaboration</li>
                                </ul>
                            </div>
                        :
                        <div className={lnstr}>
                            <ul>
                                <li>الابتكار</li>
                                <li>تركيز العميل</li>
                                <li>النزاهة</li>
                                <li>التعاون</li>
                            </ul>
                        </div>}

                        <br />



                        {(lnstr==='en') ?
                            <div className={lnstr}>
                                <b style={{ color: "#33d0f2" }}>Main Services:</b>
                            </div>
                            :
                            <div className={lnstr}>
                                <b style={{ color: "#33d0f2" }}>الخدمات الرئيسية :</b>
                                <br />
                            </div>
                        }

                        {(lnstr==='en') ?
                            <div className={lnstr}>
                                <ul className="custom-list">
                                    <li> eVisas</li>
                                    <li> Insurance</li>
                                    <li> API Integration</li>
                                    <li> White Label App</li>
                                </ul>
                            </div>
                        :
                        <div className={lnstr}>
                            <ul>
                                <li>التأشيرات الإلكترونية</li>
                                <li>التأمين</li>
                                <li>دمج API</li>
                                <li>White Label App</li>
                            </ul>
                        </div>}

                        <br />

                        {(lnstr==='en') ?
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>Contact:</b> : <a style={{ color: "blue", textDecoration: "none" }} href="mailto:admin@superjetom.com">admin@superjetom.com</a>
                            </div>
                            :
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>التواصل</b> : <a style={{ color: "blue", textDecoration: "none" }} href="mailto:admin@superjetom.com">admin@superjetom.com</a>
                                <br/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>


        <Footer />

    </div>
  );
}

export default About;