import React, { Fragment, useState, useEffect } from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import sideimg from './side7.jpg';
import { Button } from "react-bootstrap";
import { connect } from 'react-redux';
import { submitForm } from './search/redux/actions/actions';
import { useTranslation } from 'react-i18next';
import Select2Wrapper from './Select2Wrapper';
import Cookies from 'js-cookie';
const { config } = require('../src/api/api');
function Home({ onSubmitForm, message, searchresult, flagresult, embassyresult, error }) {

  const base_url = config.baseURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();

  return (
    <div className="App">
      <div className="header_section">

        <Navbar />

        <div className="banner_section layout_padding">
          <div className="container">
            <h1 className="discover_text">{t('Discover')}</h1>
            <h1 class="amazing_text">

              {(lnstr === 'en') ? <div><span style={{ color: "#000" }}>Our</span> Super <span style={{ color: "#000" }}>Services</span></div>
                : 'خدماتنا المميزة'}

            </h1>
            <div className="banner_section2 pt-3">

              {/* <h2 className="browse_text">{t('Browse')} {t('the')} {t('highlight')}</h2> */}
            </div>
            <div className="row pt-2">
              <div className="col-sm-12 col-md-6 m-auto mb-2">
                <div className="choose_main1 p-3 mx-auto mt-4 text-center">
                  <div className="icon_3">
                    <h6 className="food_text">{(lnstr === 'en') ? <span>e-Visa</span> : <span>التأشيرات الألكترونية</span>}</h6>
                    {/* <p className='px-5'>Simplifying world travel modernizing your visa. More than 100 countries are now giving out e-visas to travelers</p> */}
                  </div>
                  <a href="/Visa"><button type="submit" className="btn btn-primary rounded-pill text-white my-2 py-1 px-4">{(lnstr === 'en') ? <span>Explore</span> : <span>بحث</span>}</button></a>


                </div>
              </div>
              <div className="col-sm-12 col-md-6 m-auto mb-2">
                <div className="choose_main1 p-3 mx-auto mt-4 text-center">

                  <div className="icon_4">
                    <h6 className="food_text">{(lnstr === 'en') ? <span>e-Insurance</span> : <span>التأمين الألكتروني</span>}</h6>
                    {/* <p>Whether seeking coverage for personal needs or business operations, insurance services offer essential safeguards in an unpredictable world.</p> */}
                  </div>
                  <a href="/Insurance"><button type="submit" className="btn btn-primary rounded-pill text-white my-2 py-1 px-4">{(lnstr === 'en') ? <span>Explore</span> : <span>بحث</span>}</button></a>


                </div>
              </div>
            </div>



          </div>
        </div>
      </div>

      {/* <div className="work_section layout_padding">
        <div className="container">
          <div className="work_main">
            <h1 className="work_text">WHAT <span className="htext">WE DO</span></h1>
            <div className="work_section2">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="choose_main">
                  <div className="choose_left">
                    <h1 className="number_text"><a href="#">01</a></h1>
                  </div>
                  <div className="choose_right">
                    <h1 className="choose_text">{t('Visa')} {t('Services')}</h1>
                    <p className="many_text">{t('Simplifying')} {t('visa')} {t('application')} {t('processes')} {t('can')} {t('have')} {t('a')} {t('multitude')} {t('of')} {t('benefits,')} {t('from')} {t('reducing')} {t('paperwork')} {t('and')} {t('administrative')} {t('burdens')} {t('to')} {t('speeding')} {t('up')} {t('the')} {t('overall')} {t('process')} {t('for')} {t('travellers')}.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="choose_main">
                  <div className="choose_left">
                    <h1 className="number_text"><a href="#">02</a></h1>
                  </div>
                  <div className="choose_right">
                    <h1 className="choose_text">{t('Insurance')} {t('Services')}</h1>
                    <p className="many_text">{t('Whether')} {t('seeking')} {t('coverage')} {t('for')} {t('personal')} {t('needs')} {t('or')} {t('business')} {t('operations')}, {t('insurance')} {t('services')} {t('offer')} {t('essential')} {t('safeguards')} {t('in')} {t('an')} {t('unpredictable')} {t('world')}.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div> */}


      <div class="features_section layout_padding mb-5">
        <div class="container">
          <h1 class="work_text">
            {(lnstr === 'en') ? <div>HOW <span className="htext"> IT WORKS</span></div>
              : 'ألية العمل'}
          </h1>

          <div class="features_section2">
            <div class="row union" style={{ marginLeft: lnstr === 'ar' ? '12%' : '' }}>
              <div class="col-md-4">
              </div>
              <div class="col-md-4 col-sm-12 hfc">
                <div className="nilaone">
                  <h4 className="text-center" style={{ marginTop: lnstr === 'ar' ? '22%' : '' }}>{t('Inform')}</h4>
                  <p className="text-center px-2">

                    {(lnstr === 'en') ?
                      <div>Educate customers by informing them about the correct requirements about their trip &
                        insurance</div>
                      : <div>تثقيف العملاء من خلال إبلاغهم بالمتطلبات الصحيحة حول رحلتهم والتأمين</div>}

                  </p>
                </div>
              </div>
              <div class="col-md-4">
              </div>



              <div class="col-md-1">
              </div>
              <div class="col-md-4 col-sm-12 hfc">

                <div className="half-circle" style={{ marginLeft: lnstr === 'ar' ? '-65px' : '' }}>
                  <p className="text-center">

                    {(lnstr === 'en') ? <div>Help customers to obtain their visas and insurance in a simple and secure way</div>
                      : 'مساعدة العملاء في الحصول على التأشيرات والتأمين بطريقة بسيطة وآمنة'}

                  </p>
                  <h4 className="text-center">{t('Guide')}</h4>
                </div>

              </div>
              <div class="col-md-2">
              </div>
              <div class="col-md-4 col-sm-12 hfc">

                <div className='verifysec' style={{ marginRight: lnstr === 'ar' ? '22%' : '' }}>

                  <h4 className="text-center">{t('Verify')}</h4>
                  <p className="text-center">

                    {(lnstr === 'en') ? <div>Check customers have the documents they need</div>
                      : 'التأكد من أن العملاء لديهم المستندات التي يحتاجونها'}


                  </p>

                </div>

              </div>
              <div class="col-md-1">
              </div>

            </div>
          </div>
        </div>
      </div>

      <Footer />


    </div>
  );
}

const mapStateToProps = state => ({
  message: state.visasearch.message,
  searchresult: state.visasearch.searchretdata,
  flagresult: state.visasearch.countryflagdata,
  embassyresult: state.visasearch.embassydata,
  error: state.visasearch.error
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitForm: (formData) => dispatch(submitForm(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
