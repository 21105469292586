import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import sideimg from './side7.jpg';

function Partner() {

    const { t } = useTranslation();

  return (
    <div className="App">
      

      <div className="header_section1">

      <Navbar />


      <div className="container-fluid bg-breadcrumb">
            <div className="container text-center py-5">            
                <h3 className="abt-txt my-5">{t('PARTNER')} {t('WITH')} {t('US')}</h3>
            </div>
        </div>

      
      </div>

      


      <div className="features_section layout_padding">
        <div className="container">
          <h1 className="features_text">Travel <span className="htext">  Without Limit</span></h1>
          <div className="features_section2">
            <div className="row">
              <div className="col-md-4">
                
              <img src={sideimg} alt="SUPERJET" />

              </div>
              <div className="col-md-8">
                <h1 classNameName='features_text text-left'>Leading visa application for travellers</h1>
                <p style={{ margin: 0 }}><br />Absolutely! Simplifying visa application processes can have a multitude of benefits, from reducing paperwork and administrative burdens to speeding up the overall process for travellers.<br /><br /> In today's fast-paced world, where people are constantly on the move for business, leisure, or other purposes, having efficient visa services can make a significant difference in facilitating smooth travel experiences. Plus, it aligns well with the trend toward digitalization and online convenience in various aspects of our lives.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

    </div>
  );
}

export default Partner;
