import Navbar from "./Navbar";
import Footer from "./Footer";
import {useTranslation} from 'react-i18next';
import Cookies from 'js-cookie';

function Privacy() {

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const {t} = useTranslation();

    return (
        <div>

            <div className="header_section1">

                <Navbar/>


                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{t('PRIVACY')} {t('POLICY')}</h3>
                    </div>
                </div>


            </div>


            <div className="features_section layout_padding my-1">
                <div className="container">

                    <div className="row g-4 justify-content-center">

                        <div className="col-12 wow fadeInUp" data-wow-delay="0.2s">

                            {(lnstr === 'en') ?
                                <div className={lnstr}>This privacy statement from SUPERJET outlines their commitment to
                                    protecting and preserving the privacy and confidentiality of user information.
                                    Here's a breakdown of the key points:</div>
                                :
                                <div className={lnstr}>يحدد بيان الخصوصية هذا من سوبرجيت التزامهم بحماية والحفاظ على
                                    خصوصية وسرية معلومات المستخدم. فيما يلي ملخص للنقاط الرئيسية</div>}

                            <br/>
                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Security:</b>
                                    <p>SUPERJET ensures high levels of security and privacy for user
                                        transactions, using SSL technology for encryption. They implement policies and
                                        procedures to protect personal information from loss, misuse, unauthorised
                                        access,
                                        disclosure, alteration or destruction.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>الأمان</b>
                                    <p>تضمن سوبرجيت مستويات عالية من الأمان والخصوصية لمعاملات المستخدمين،
                                    باستخدام تقنية SSL للتشفير. يتم تنفيذ السياسات والإجراءات لحماية المعلومات الشخصية
                                    من الفقدان أو سوء الاستخدام أو الوصول غير المصرح به أو الإفشاء أو التغيير أو
                                    التدمير</p>
                                </div>}


                            <br/>
                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Information Handling:</b>
                                    <p>SUPERJET strives to record information accurately
                                    and completely. Users have access to their personal information for correction or
                                    modification where appropriate. Information collected is used exclusively for
                                    providing better service and is kept confidential. Financial transactions are
                                    processed securely through major third-party credit card processing companies.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>التعامل مع المعلومات</b>
                                    <p>تسعى سوبرجيت لتسجيل المعلومات بدقة وكاملة. يمكن
                                    للمستخدمين الوصول إلى معلوماتهم الشخصية لتصحيحها أو تعديلها عند الضرورة. تُستخدم
                                    المعلومات التي يتم جمعها حصريًا لتقديم خدمة أفضل وتظل سرية. يتم معالجة المعاملات
                                    المالية بأمان من خلال شركات معالجة بطاقات الائتمان الكبرى التابعة لجهات خارجية.</p>
                                </div>}


                            <br/>
                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Cookies:</b>
                                    <p>SUPERJET uses cookies to enhance user experience on the website.
                                    While cookies may need to be enabled for certain functionalities, they do not store
                                    personal information and are entirely confidential. SUPERJET provides links to other
                                    sites that may also use cookies, and users are advised to familiarise themselves
                                    with the privacy policies of those sites.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>كوكيز</b>
                                    <p>تستخدم سوبرجيت ملفات تعريف الارتباط لتحسين تجربة المستخدم على
                                    الموقع. في حين قد يحتاج تمكين ملفات تعريف الارتباط لبعض الوظائف، فإنها لا تخزن
                                    معلومات شخصية وتظل سرية تمامًا. توفر سوبرجيت روابط لمواقع أخرى قد تستخدم أيضًا ملفات
                                    تعريف الارتباط، وينصح المستخدمون بالتعرف على سياسات الخصوصية لتلك المواقع.</p>
                                </div>}


                            <br/>
                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>System Information:</b>
                                    <p>SUPERJET reserves the right to collect and store
                                    system information like IP addresses, browser types, or operating systems for
                                    administration purposes.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>معلومات النظام</b>
                                    <p>تحتفظ سوبرجيت بالحق في جمع وتخزين معلومات النظام مثل عناوين
                                    IP وأنواع المتصفحات أو أنظمة التشغيل لأغراض الإدارة</p>
                                </div>}

                            <br/>
                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Email:</b>
                                    <p>SUPERJET uses email to notify users about changes in application
                                    status, database updates, new services, features, or special discounts. Email lists
                                    are confidential and not sold or given to third parties.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>البريد الإلكتروني</b>
                                    <p>تستخدم سوبرجيت البريد الإلكتروني لإخطار المستخدمين
                                    بالتغييرات في حالة التطبيقات أو تحديثات قواعد البيانات أو الخدمات الجديدة أو الميزات
                                    أو الخصومات الخاصة. تظل قوائم البريد الإلكتروني سرية ولا تُباع أو تُمنح لأطراف
                                    ثالثة</p>
                                </div>}


                            <br/>
                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Change or Modify Information:</b>
                                    <p>Users can change or modify their profiles
                                    using their customer ID and password. Any unauthorised use should be reported
                                    immediately.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>تغيير أو تعديل المعلومات</b>
                                    <p>يمكن للمستخدمين تغيير أو تعديل ملفاتهم الشخصية باستخدام معرف العميل وكلمة المرور
                                        الخاصة بهم. يجب الإبلاغ عن أي استخدام غير مصرح به على الفور</p>
                                </div>}


                            <br/>
                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Disclaimer:</b>
                                    <p>SUPERJET disclaims responsibility for any damages or losses
                                    arising from unauthorised use, breach of security, or system failure. They are not
                                    liable for inaccuracies, errors, viruses, or hacker attacks. Links to other sites
                                    are provided for information purposes, and SUPERJET is not responsible for their
                                    privacy practices or content.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>إخلاء المسؤولية</b>
                                    <p>تخلي سوبرجيت مسؤوليتها عن أي أضرار أو خسائر تنشأ عن
                                    الاستخدام غير المصرح به أو خرق الأمان أو فشل النظام. لا تتحمل المسؤولية عن عدم الدقة
                                    أو الأخطاء أو الفيروسات أو هجمات الهاكرز. يتم توفير الروابط للمواقع الأخرى لأغراض
                                    المعلومات، ولا تتحمل سوبرجيت مسؤولية ممارسات الخصوصية أو المحتوى الخاص بها</p>
                                </div>}


                            <br/>
                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Summary:</b>
                                    <p>By accessing the site and using its services, users agree to the
                                    terms of the Privacy Statement and Terms & Conditions. Non-agreement with any terms
                                    means users should refrain from using the site.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>الملخص </b>
                                    <p>من خلال الوصول إلى الموقع واستخدام خدماته، يوافق المستخدمون على
                                    شروط بيان الخصوصية والشروط والأحكام. يعني عدم الموافقة على أي من الشروط أنه يجب على
                                    المستخدمين الامتناع عن استخدام الموقع</p>
                                </div>}


                        </div>
                    </div>
                </div>
            </div>


            <Footer/>

        </div>
    );
}

export default Privacy;
