import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import whatsappimg from './whatsap.png';

const Footer = () => {

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <div>

      <div className="footer_section layout_padding">
        <div className="container">
          <div className="row mb-5">
            <div className="col-sm-6 col-lg-3">
              <div className="footer-item d-flex flex-column">
                <h1 className={`customer_text mb-1 ${lnstr}`}>Superjet</h1>
                <p className={`footer_text ${lnstr}`}>

                  {(lnstr === 'en') ? 'SuperJet is the leading provider of integrated eVisa and comprehensive insurance services in the GCC, enhancing customer journeys and boosting partner revenues through advanced API solutions.'
                    : 'Superjet هي الشركة الرائدة في دول مجلس التعاون الخليجي في تقديم خدمات التأشيرات الإلكترونية والتأمين الشامل   نسعى لتعزيز خدمة العملاء وزيادة إيرادات شركائنا من خلال  API'}


                </p>
              </div>
            </div>





            <div className="col-sm-6 col-lg-3">
              <div className={`footer-item d-flex flex-column ${lnstr}`}>
                <h4 className="customer_text mb-1">{t('Quick Links')}</h4>
                {/*<a href="/about"><i className="fas fa-angle-right me-2"></i> {t('About Us')}</a>*/}
                <a href="/about-us"><i className={`fas fa-angle-${lnstr === 'en' ? 'right' : 'left'} me-2`}></i> {(lnstr === 'en') ? 'About Us' : <span>نبذة عنا
                </span>}</a>
                <a href="/visa"><i className={`fas fa-angle-${lnstr === 'en' ? 'right' : 'left'} me-2`}></i> {(lnstr === 'en') ? 'e-Visa' : <span>التأشيرات الألكترونية
                </span>}</a>
                <a href="/insurance"><i className={`fas fa-angle-${lnstr === 'en' ? 'right' : 'left'} me-2`}></i> {(lnstr === 'en') ? 'e-Insurance' : <span>التأمين الألكتروني
                </span>}</a>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className={`footer-item d-flex flex-column ${lnstr}`}>
                <h4 className="customer_text mb-1">{t('RESOURCES')}</h4>
                <a href="/privacy"><i className={`fas fa-angle-${lnstr === 'en' ? 'right' : 'left'} me-2`}></i> {t('Privacy Policy')}</a>
                <a href="/terms"><i className={`fas fa-angle-${lnstr === 'en' ? 'right' : 'left'} me-2`}></i>  {(lnstr === 'en') ? ' Terms Conditions' : 'الشروط والأحكام'}</a>
                {/* document link */}
                {/* <a href="/ISA Assist - Claims Procedure.pdf" target="_blank" rel="noopener noreferrer"><i className={`fas fa-angle-${lnstr === 'en' ? 'right' : 'left'} me-2`}></i>  {(lnstr==='en') ? ' ISA Assist - Claims Procedure' : 'مساعدة ISA - إجراءات المطالبات' }</a>
                            {lnstr === 'en'?<a  href= "/Outbound Travel - Policy Wording (Hajj & Umrah Travel) - English.pdf" target="_blank" rel="noopener noreferrer"><i className={`fas fa-angle-${lnstr === 'en' ? 'right' : 'left'} me-2`}></i>  {(lnstr==='en') ? ' Outbound Travel - Policy Wording (Hajj & Umrah Travel)' : 'السفر للخارج - صياغة السياسة (سفر الحج والعمرة)' }</a>:<a  href= "/Outbound Travel - Policy Wording (Hajj-Umrah Travel) - Arabic.pdf" target="_blank" rel="noopener noreferrer"><i className={`fas fa-angle-${lnstr === 'en' ? 'right' : 'left'} me-2`}></i>  {(lnstr==='en') ? ' Outbound Travel - Policy Wording (Hajj & Umrah Travel)' : 'السفر للخارج - صياغة السياسة (سفر الحج والعمرة)' }</a>}
                            {lnstr === 'en'? <a href="/Outbound Travel - Policy Wording (Standard) - English.pdf" target="_blank" rel="noopener noreferrer"><i className={`fas fa-angle-${lnstr === 'en' ? 'right' : 'left'} me-2`}></i>  {(lnstr==='en') ? ' Outbound Travel - Policy Wording (Standard)' : 'السفر للخارج - صياغة السياسة (قياسية)' }</a>:<a href="/Outbound Travel - Policy Wording (Standard) Arabic.pdf" target="_blank" rel="noopener noreferrer"><i className={`fas fa-angle-${lnstr === 'en' ? 'right' : 'left'} me-2`}></i>  {(lnstr==='en') ? ' Outbound Travel - Policy Wording (Standard)' : 'السفر للخارج - صياغة السياسة (قياسية)' }</a>}
                            <a href="/Policy Wordings - Inbound Travel.pdf" target="_blank" rel="noopener noreferrer"><i className={`fas fa-angle-${lnstr === 'en' ? 'right' : 'left'} me-2`}></i>  {(lnstr==='en') ? ' Inbound Travel - Policy Wordings' : 'السفر الداخلي – صيغ السياسة' }</a> */}

              </div>
            </div>
            <div className="col-sm-6 col-lg-3">

              {/* <div className={`footer-item d-flex flex-column ${lnstr}`}>
                <h4 className="customer_text mb-1">{t('Whatsapp')}</h4>
                <a href="https://wa.me/96877074345?text=support"><img src={whatsappimg} alt="SUPERJET" />  Superjet{(lnstr === 'en') ? <span>(968)77074345</span> : <span>77074345(968)</span>}</a>
                <a href="https://wa.me/96877074345?text=support"><img src={whatsappimg} alt="SUPERJET" />  Travel Insurance{(lnstr === 'en') ? <span>(968)77074345</span> : <span>77074345(968)</span>}</a>
              </div> */}

              {/* <div className={`footer-item d-flex flex-column ${lnstr}`}>
                <h4 className="customer_text mb-1">{t('Support')}</h4>
                <div className="d-flex" >
                <div className="col-6 col-sm-6 col-lg-8"  style={{paddingLeft: '0px'}}>
                  <span style={{color:'white'}}>Superjet</span>
                  </div>

                  <div className="col-8 col-sm-8 col-lg-8">
                  <img src={whatsappimg} alt="SUPERJET" /><a href="https://wa.me/96877074345?text=support" className="pl-2">
                      {(lnstr === 'en') ? <span>(968) 77074345</span> : <span>77074345 (968)</span>}
                    </a>
                  </div>
                </div>
                <div className="d-flex">
                <div className="col-6 col-sm-6 col-lg-8"  style={{paddingLeft: '0px'}}>
                  <span style={{color:'white'}}>Travel Insurance</span>
                  </div>

                  <div className="col-8 col-sm-8 col-lg-8">
                  <img src={whatsappimg} alt="SUPERJET" />
                    <a href="https://wa.me/9613190210?text=support" className="pl-2">
                      {(lnstr === 'en') ? <span>(961) 3190210</span> : <span>3190210 (961)</span>}
                    </a>
                  </div>
                </div>
              </div> */}


              <div className={`footer-item d-flex flex-column ${lnstr}`}>
                <h4 className="customer_text mb-1">{t('Support')}</h4>
                <div className="d-flex" >
                  <div className="col-6 col-sm-6 col-lg-8 pl-0">


                  {(lnstr === 'en') ? <span style={{ color: 'white' }}>For Enquiries</span> : <span style={{ color: 'white' }}>للاستفسارات
                </span>}
                    {/* <span style={{ color: 'white' }}>For Enquiries</span> */}
                  </div>
                </div>
       
                <div className="col-8 col-sm-8 col-lg-8 pl-0">
                  <img src={whatsappimg} alt="SUPERJET" /><a href="https://wa.me/96877074345?text=support" target="_blank" className={(lnstr === 'en') ?"pl-2": "pr-2"}>
                    {(lnstr === 'en') ? <span>(968) 77074345</span> : <span>77074345 (968)</span>}
                  </a>
                </div>
                <br/>

                <div className="d-flex" >
                  <div className="col-12 col-sm-12 col-lg-12" style={{ paddingLeft: '0px' }}>
                  {(lnstr === 'en') ? <span style={{ color: 'white' }}>Travel Insurance Support</span> : <span style={{ color: 'white' }}>دعم تأمين السفر</span>}
                    {/* <span style={{ color: 'white' }}>Travel Insurance Support</span> */}
                  </div>
                </div>

                <div className="col-8 col-sm-8 col-lg-8 pl-0">
                  <img src={whatsappimg} alt="SUPERJET" />
                  <a href="https://wa.me/9613190210?text=support" target="_blank" className=  {(lnstr === 'en') ?"pl-2": "pr-2"}>
                    {(lnstr === 'en') ? <span>(961) 3190210</span> : <span>3190210 (961)</span>}
                  </a>
                </div>


                {/* <div className="d-flex">
                <div className="col-6 col-sm-6 col-lg-8"  style={{paddingLeft: '0px'}}>
                  <span style={{color:'white'}}>Travel Insurance Support</span>
                  </div>

                  <div className="col-8 col-sm-8 col-lg-8">
                  <img src={whatsappimg} alt="SUPERJET" />
                    <a href="https://wa.me/9613190210?text=support" className="pl-2">
                      {(lnstr === 'en') ? <span>(961) 3190210</span> : <span>3190210 (961)</span>}
                    </a>
                  </div>
                </div> */}
              </div>


              <div className={`footer-item d-flex flex-column ${lnstr}`}>
                <h4 className="customer_text mb-1">{t('FOLLOW US')}</h4>
                <div className="d-flex align-items-center">
                  <a className="btn-square btn btn-primary text-white rounded-circle mx-1" href="https://www.instagram.com/superjet.om/"><i className="fab fa-instagram"></i></a>
                  <a className="btn-square btn btn-primary text-white rounded-circle mx-1" href="https://www.linkedin.com/in/superjet.om/"><i className="fab fa-linkedin-in"></i></a>
                </div>
              </div>
            </div>
          </div>


          <p className="copyright_text">{(lnstr === 'en') ? <span>Copyright {currentYear} All Rights Reserved.</span> : <span>حقوق الطبع والنشر © جميع الحقوق محفوظة
          </span>}</p>

          {/* <a href="https://superjetom.com"> Superjetom</a> */}


        </div>
      </div>

    </div>
  );
}

export default Footer;
