import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en.json';
import frTranslation from './ar.json';
import Cookies from 'js-cookie';

const langstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

const resources = {
  en: {
    translation: enTranslation,
  },
  ar: {
    translation: frTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: langstr,
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;