import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
const { config } = require('../api/api');


function BasicInformation() {
  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });
  const [loading, setLoading] = useState(true);
  const [selectedDateofBirth, setSelectedDateofBirth] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedmarital, setSelectedmarital] = useState(null);
  const [vendorKey, setVendorkey] = useState('');

  // const [selectedOption, setSelectedOption] = useState(null);
  // const [loading, setLoading] = useState(false);
  // const [showSection, setShowSection] = useState(false);

  // const [buttonDisabled, setButtonDisabled] = useState(false);

  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  //   setFormData({ ...formData, 'insurancetype': event.target.value });

  //   if (event.target.value === 'Outbound Travel') {
  //     setShowSection(true);
  //     setButtonDisabled(true);
  //   }
  //   else {
  //     setShowSection(false);
  //     setButtonDisabled(false);
  //   }

  // };



  // const [selectedqOption, setSelectedqOption] = useState(null);
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // month is 0-indexed
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = parseDate(dob);

    // Check if birthDate is valid
    if (isNaN(birthDate.getTime())) {
      throw new Error('Invalid date');
    }

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birth date hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const handleqOptionChange = (event) => {

    // if (event.target.value === 'No') {
    //   alert("The outbound plans are available only for residents of Oman.");
    //   setButtonDisabled(true);
    //   return;
    // }
    // else {
    //   setButtonDisabled(false);
    // }

  };


  // const [vendorKey, setVendorKey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };
    debugger

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch( base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorkey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array


  const handleSubmit = async (event) => {

    debugger

    var data = {
      "basicinfo_gender_id": selectedgenderOptionid,
      "basicinfo_gender_detail": selectedgenderOption,
      "basicinfo_marital_status_id": selectedmaritalOptionid,
      "basicinfo_marital_status_detail": selectedmaritalOption,
      "dob": selectedDateofBirth
    }

    debugger

    sessionStorage.setItem("personalaccidentbasicinfo", JSON.stringify(data))

    event.preventDefault();
    setLoading(true);

    event.preventDefault();
    window.location.href = '/accident-cover';

  };

  useEffect(() => {
    debugger
    const basicvalue = sessionStorage.getItem('personalaccidentbasicinfo');
    if (basicvalue != undefined) {
      const value = JSON.parse(basicvalue);
      setSelectedGender(value.basicinfo_gender_detail);
      setSelectedmarital(value.basicinfo_marital_status_detail);
      setSelectedDateofBirth(value.dob);
      setSelectedgenderOption(value.basicinfo_gender_detail);
      setSelectedgenderOptionid(value.basicinfo_gender_id);
      setSelectedmaritalOptionid(value.basicinfo_marital_status_id);
      setSelectedmaritalOption(value.basicinfo_marital_status_detail);

    }

  }, []);

  const [dhofarData, setDhofarData] = useState(false);
  const [age, setAge] = useState(null);
  useEffect(() => {


    const storedDhofarString = Cookies.get('personalData'); //sessionStorage.getItem('userData')
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);


  // const [vendorKey, setVendorkey] = useState('');

  // useEffect(() => {
  //   const vendortoken = Cookies.get('Vendorkey');

  //   if (vendortoken !== undefined) {

  //     const storedVendor = vendortoken;
  //     setVendorkey(storedVendor)


  //   }
  // }, []);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const postData = { token: dhofarData };
  // const today = new Date().toISOString().split('T')[0];
  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];
  const minDate = new Date(today.getFullYear() - 60, today.getMonth(), today.getDate()).toISOString().split('T')[0];

  const [dobValue, setdobValue] = useState('');
  const handledobChange = (event) => {
    const selectedDob = event.target.value;
    setdobValue(event.target.value);

    const dobDate = new Date(selectedDob);
    const today = new Date();
    let calculatedAge = today.getFullYear() - dobDate.getFullYear();
    const monthDiff = today.getMonth() - dobDate.getMonth();

    // Adjust age if the current date is before the user's birthday in the current year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
      calculatedAge--;
    }

    setAge(calculatedAge);
  };

  const [selectedgenderOption, setSelectedgenderOption] = useState(null);
  const [selectedgenderOptionid, setSelectedgenderOptionid] = useState(null);
  const handlegenderChange = (event) => {
    debugger
    setSelectedGender(event.target.value)
    setSelectedgenderOptionid(event.target.options[event.target.selectedIndex].id);
    setSelectedgenderOption(event.target.value);
  };

  const [selectedmaritalOption, setSelectedmaritalOption] = useState(null);
  const [selectedmaritalOptionid, setSelectedmaritalOptionid] = useState(null);
  const handlemaritalChange = (event) => {
    setSelectedmarital(event.target.value)
    setSelectedmaritalOptionid(event.target.options[event.target.selectedIndex].id);
    setSelectedmaritalOption(event.target.value);
  };
  //getgender

  const [goptions, setGender] = useState([]);
  const [moptions, setMarital] = useState([]);

  useEffect(() => {
    if (goptions && goptions.length === 0 && vendorKey !== '') {

      

      debugger
      fetch(base_url + '/getGender', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      })
        .then(response => response.json())
        .then(data => {
          setGender(data.result.data.response_data);
          married()

        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }    
  },[vendorKey]
  );

  const married = () => {
    if (moptions && moptions.length === 0 && vendorKey !== '') {
      setLoading(true);

      fetch(base_url + '/getMaritalStatus', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      })
        .then(response => response.json())
        .then(data => {

          setMarital(data.result.data.response_data);
          setLoading(false);


        })
        .catch(error => {
          console.error('Error posting data:', error);
        });

    }
  };

  //getmaritalstatus

  // const [moptions, setMarital] = useState([]);
  // useEffect(() => {
  //   debugger
  //   if (moptions && moptions.length === 0 && vendorKey !== '') {

  //     fetch(base_url + '/getMaritalStatus', {
  //       method: 'POST',
  //       headers: {
  //         'Authorization': `Bearer ${vendorKey}`,
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(postData),
  //     })
  //       .then(response => response.json())
  //       .then(data => {

  //         setMarital(data.result.data.response_data);


  //       })
  //       .catch(error => {
  //         console.error('Error posting data:', error);
  //       });

  //   }

  // },[vendorKey])

  // const handleSubmit = async (event) => {

  //   event.preventDefault();

  //   if (selectedOption === null) {
  //     alert('Please choose plan');
  //     return false;
  //   }
  // }
  const isButtonDisabled = !selectedgenderOption || !selectedmaritalOption || !selectedDateofBirth;






  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Personal Accident' : 'حادث شخصي\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">



              <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Select Insured Details' : 'حدد تفاصيل المؤمن عليه\n'}</h4>

              <form onSubmit={handleSubmit}>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{t('Gender')}</span>
                  </div>
                  <select value={selectedGender} name="gender" className="form-control" onChange={handlegenderChange} required>
                    <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                    {goptions && goptions.map((opt) =>
                      <option key={opt.id} id={opt.id} value={opt.description}>{t(opt.description)}</option>
                    )}
                  </select>
                </div>
                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{(lnstr === 'en') ? 'Marital Status' : 'الحالة الأجتماعية'}</span>
                  </div>
                  <select value={selectedmarital} name="maritalstatus" className="form-control" onChange={handlemaritalChange}
                    required>
                    <option>{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                    {moptions && moptions.map((mopt) =>
                      <option key={mopt.id} id={mopt.id}
                        value={mopt.description}>{t(mopt.description)}</option>
                    )}
                  </select>
                </div>
                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{t('DOB')}</span>
                  </div>
                  <input value={selectedDateofBirth} type="date" name="dob" max={maxDate} min={minDate} className="form-control" onChange={(e) => {
                    setSelectedDateofBirth(e.target.value);
                    const formattedDate = e.target.value.replace(/\//g, '-');
                    const [year, month, day] = formattedDate.split("-");
                    const finalformattedDate = `${day}-${month}-${year}`;
                    setFormData({ ...formData, ['dob']: finalformattedDate });

                    // const finalAgeFormattedDate = `${day}-${month}-${year}`;
                    // Calculate and set the age
                    const age = calculateAge(finalformattedDate);
                    setAge(age);

                  }} onKeyDown={(e) => e.preventDefault()} required />
                </div>
                {age !== null && (
                  <div className="mt-2">
                    <p>You are {age} years old.</p>
                  </div>
                )}
                <button type="submit" disabled={isButtonDisabled} className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('Submit')}</button>


              </form>


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );


}

export default BasicInformation;
