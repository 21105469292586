import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';

function Founders() {

    const { t } = useTranslation();

  return (
    <div>

        <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
            <div className="container text-center py-5">            
                <h3 className="abt-txt my-5">{t('FOUNDERS')}</h3>
            </div>
        </div>


        </div>
        

        <div className="features_section layout_padding my-1">
        <div className="container">

        <h1 className="features_text">World's Leading<span className="htext"> Visa Application</span></h1>
            
            {/* <div className="row">
                    <h1 className="mb-4">{t('Superjet')} {t('is')} {t('the')} {t('world\'s')} {t('leading')} {t('visa')} {t('application')} {t('for')} {t('travellers')}.</h1>
                    </div> */}

                <div className="row g-4 justify-content-center">
                    
                    <div className="col-12 p-0 mt-3 wow fadeInUp" data-wow-delay="0.2s">
                        <p>{t('Absolutely!')} {t('Simplifying')} {t('visa')} {t('application')} {t('processes')} {t('can')} {t('have')} {t('a')} {t('multitude')} {t('of')} {t('benefits')}, {t('from')} {t('reducing')} {t('paperwork')} {t('and')} {t('administrative')} {t('burdens')} {t('to')} {t('speeding')} {t('up')} {t('the')} {t('overall')} {t('process')} {t('for')} {t('travellers')} {t('In')} {t('today\'s')} {t('fast-paced')} {t('world')} {t('where')} {t('people')} {t('are')} {t('constantly')} {t('on')} {t('the')} {t('move')} {t('for')} {t('business')}, {t('leisure')}, {t('or')} {t('other')} {t('purposes')}, {t('having')} {t('efficient')} {t('visa')} {t('services')} {t('can')} {t('make')} {t('a')} {t('significant')} {t('difference')} {t('in')} {t('facilitating')} {t('smooth')} {t('travel')} {t('experiences')}. {t('Plus')}, {t('it')} {t('aligns')} {t('well')} {t('with')} {t('the')} {t('trend')} {t('toward')} {t('digitalization')} {t('and')} {t('online')} {t('convenience')} {t('in')} {t('various')} {t('aspects')} {t('of')} {t('our')} {t('lives')}.</p>
                        <b>{t('FOCUS')}:</b>
                        <p>{t('Absolutely!')} {t('By')} {t('honing')} {t('in')} {t('on')} {t('what')} {t('you')} {t('do')} {t('best')} {t('and')} {t('delivering')} {t('exceptional')} {t('service')} {t('in')} {t('that')} {t('area')}, {t('you')} {t('not')} {t('only')} {t('stand')} {t('out')} {t('from')} {t('competitors')} {t('but')} {t('also')} {t('establish')} {t('yourself')} {t('as')} {t('a')} {t('trusted')} {t('authority')} {t('in')} {t('the')} {t('field')} {t('of')} {t('travel')} {t('document')} {t('solutions')}. {t('Providing')} {t('free')} {t('access')} {t('to')} {t('valuable')} {t('databases')} {t('adds')} {t('even')} {t('more')} {t('value')} {t('to')} {t('your')} {t('offerings')}, {t('as')} {t('it')} {t('empowers')} {t('travellers')} {t('with')} {t('the')} {t('information')} {t('they')} {t('need')} {t('to')} {t('navigate')} {t('the')} {t('complexities')} {t('of')} {t('international')} {t('travel')} {t('effectively')}. {t('This')} {t('commitment')} {t('to')} {t('excellence')} {t('and')} {t('customer-centric')} {t('approach')} {t('can')} {t('indeed')} {t('foster')} {t('long-term')} {t('relationships')} {t('built')} {t('on')} {t('trust')} {t('and')} {t('reliability')}, {t('further')} {t('solidifying')} {t('your')} {t('position')} {t('as')} {t('a')} {t('leader')} {t('in')} {t('the')} {t('industry')}.</p>
                    </div>
                </div>
            </div>
        </div>


        <Footer />

    </div>
  );
}

export default Founders;