import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Navbar from "../Navbar";
import Footer from "../Footer";
import Select2Wrapper from '../Select2Wrapper';
import Cookies from 'js-cookie';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
const { config } = require('../api/api');


Modal.setAppElement('#root');
const Application = () => {
  const base_url = config.baseURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

  const { t } = useTranslation();

  const [searchData, setSearchData] = useState(false);
  useEffect(() => {
    const storedSearchString = sessionStorage.getItem('searchData');
    if (storedSearchString !== 'undefined') {
      const storedSearch = JSON.parse(storedSearchString);
      setSearchData(storedSearch);
    }
  }, []);


  const [userData, setUserData] = useState(false);

  useEffect(() => {
    const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
    // debugger
    if (storedUserString !== undefined) {
      const storedUser = JSON.parse(storedUserString);
      setUserData(storedUser);
    }
    else {
      alert("login session expired");
      window.location.href = '/';
    }
  }, []);

  const usrtok = userData.access_token;


  const [searchResult, setSearchResult] = useState(false);
  useEffect(() => {
    const storedResultString = sessionStorage.getItem('searchResult');
    if (storedResultString !== "undefined") {
      const storedResult = JSON.parse(storedResultString);
      setSearchResult(storedResult);
    }
  }, []);


  const handlesexChange = (e) => {
    debugger
    const { name } = e.target;

    setFormData({ ...formData, [name]: e.target.value });
  };


  function generateRandomValue() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomValue = '';
    for (let i = 0; i < 10; i++) {
      randomValue += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return randomValue;
  }



  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    birthCountry: '',
    citizenshipCountry: '',
    sex: '',
    email: '',
    phoneA: '',
    phoneP: '',
    livingCountry: '',
    passportCountry: '',
    passportNumber: '',
    passportIssuingCountry: '',
    passportIssuingDate: '',
    passportExpiringDate: '',
    transit: 'Yes',
    applicantEmployed: ''
  });

  const [dob, setDob] = useState('');
  const [birthcountry, setBirth] = useState('');
  const [passportNumber, setPnumber] = useState('');
  const [passportIssuingDate, setIdate] = useState('');
  const [passportExpiringDate, setEdate] = useState('');
  const [userEmail, setuserEmail] = useState('');
  const [userFirstname, setUserFirstname] = useState('');
  const [userLastname, setUserLastname] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'dob') {
      setDob(value);
    }
    if (name === 'birthCountry') {
      setBirth(value);
    }
    if (name === 'passportNumber') {
      setPnumber(value);
    }
    if (name === 'passportIssuingDate') {
      setIdate(value);
    }
    if (name === 'passportExpiringDate') {
      setEdate(value);
    } if (name === 'email') {
      setuserEmail(value);
    }


    setFormData({ ...formData, [name]: value });
  };




  const [pdata, setPdata] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleImageChange = async (e) => {
    debugger;
    const frmData = new FormData();
    var file_name = e.target.files[0].name;
    var parts = file_name.split('.');
    var file_type = parts[parts.length - 1];
    // var file_type = file_name.split('.')[1];
    if (file_type == 'png' || file_type == 'jpeg' || file_type == 'jpg') {
      frmData.append('profile-file', e.target.files[0]);
      setLoading(true);
      try {
        // debugger
        const response = await axios.post(base_url+'/upload_passport', frmData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        // console.log('Image upload successful:', response.data);
        // debugger
        setPdata(response.data.result);

        if (response.data.result !== 'Passport format not support') {
          if (response.data.result.dob && response.data.result.dob !== '') {
            setDob(response.data.result.dob);
          }
          if (response.data.result.place_of_birth && response.data.result.place_of_birth !== '') {
            setBirth(response.data.result.place_of_birth);
          }
          if (response.data.result.passport_no && response.data.result.passport_no !== '') {
            setPnumber(response.data.result.passport_no);
          }
          if (response.data.result.date_of_issue && response.data.result.date_of_issue !== '') {
            setIdate(response.data.result.date_of_issue);
          }
          if (response.data.result.date_of_expiry && response.data.result.date_of_expiry !== '') {
            setEdate(response.data.result.date_of_expiry);
          }

        }

        setFormData({ ...formData, [`passportNumber`]: response.data.result.passport_no, [`birthCountry`]: response.data.result.place_of_birth, [`dob`]: response.data.result.dob, [`passportIssuingDate`]: response.data.result.date_of_issue, [`passportExpiringDate`]: response.data.result.date_of_expiry });
        setLoading(false);
      } catch (error) {
        console.error('Error uploading image:', error);
        // Handle error, show error message, etc.
      }
    } else {
      alert(`.${file_type} file type not support`);
    }

  };



  const [vendorKey, setVendorKey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch(base_url+'/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array



  //getcounutries

  const [options, setCountries] = useState([]);

  useEffect(() => {

    var vkData = { vendor_key: vendorKey };

    fetch('/country', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vkData),
    })
      .then(response => response.json())
      .then(data => {
        // debugger
        setCountries(data.result);
      })
      .catch(error => {
        console.error('Error fetching countries:', error);
      });
  }, [vendorKey]);

  const [phoneaValue, setphoneaValue] = useState('');
  const [citizcountry, setcitizcountry] = useState('');
  const handlecitizenshipChange = (value) => {

    // const [description, id] = value.split("#");

    const cnData = { country: value };

    setcitizcountry(value);

    fetch(base_url+'/get_country_code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cnData),
    })
      .then(response => response.json())
      .then(data => {
        // debugger

        const cde = data.result.replace(/\+/g, '');

        setphoneaValue(cde);

      })
      .catch(error => {
        console.error('Error posting data:', error);
      });

  };

  const handlephoneaChange = (event) => {
    setphoneaValue(event.target.value);
  };


  const [pkey, setpKey] = useState('');

  const vkdata = { vendor_key: vendorKey };

  if (vendorKey !== '' && pkey === '') {

    fetch(base_url+'/generate_payment_token', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${usrtok}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vkdata),
    })
      .then(response => response.json())
      .then(data => {
        // debugger

        setpKey(data.result.payment_token);


      })
      .catch(error => {
        console.error('Error posting data:', error);
      });

  }



  const handleSubmit = async (e) => {
    debugger;
    e.preventDefault();
    setLoading(true);

    const rand = Math.floor(Math.random() * 90000000) + 10000000;
    const rnd = rand.toString();
    var order_details;
    var pdata;
    var order_payment_url = '';
    if (userData != null && userData != undefined && userData.agency_name != null && userData.agency_name != undefined) {
      order_payment_url = base_url+'/apply_visa_agency_traveller';
      order_details = {
        'program_id': searchResult.id,
        'reference': generateRandomValue(),
        // 'payment_method_id': '6a26b79f-5216-41d1-ab58-98a7765615a4', //formData.payment_method_id
        'description': "Apply visa",
        'quantity': 1,
        'firstName': formData.firstName,
        'lastName': formData.lastName,
        'dob': formData.dob,
        'birthCountry': formData.birthCountry,
        'citizenshipCountry': citizcountry,
        'sex': formData.sex,
        'email': formData.email,
        'phoneA': phoneaValue,
        'phoneP': formData.phoneP,
        'passportNumber': formData.passportNumber,
        'passportIssuingCountry': formData.passportIssuingCountry,
        'passportIssuingDate': formData.passportIssuingDate,
        'passportExpiringDate': formData.passportExpiringDate,
        'transit': 'Yes',
        'applicantEmployed': formData.applicantEmployed,
        'fee': searchResult.fee,
        'commission': searchResult.commision,
        'destination': searchData.destination,
        'arrival': searchData.citizenship,
        'commission_type': searchResult.commision_type
      };
      pdata = {
        "amount_cents": searchResult.fee,
        "reference_id": rnd,
        // "is_live": "false",
        "full_name": userData.first_name,
        "email": userData.email,
        "save_selection": "true",
        "redirection_url": base_url+"/payment",
        "currency": searchResult.currency,
        "description": "Payment visa **",
        // "payment_token": pkey
      };
    } else {
      order_payment_url = base_url+'/order_visa';
      order_details = {
        'program_id': searchResult.id,
        'reference': generateRandomValue(),
        'payment_method_id': '6a26b79f-5216-41d1-ab58-98a7765615a4', //formData.payment_method_id
        'description': "Apply visa",
        'quantity': 1,
        'firstName': userData.first_name,
        'lastName': userData.last_name,
        'dob': formData.dob,
        'birthCountry': formData.birthCountry,
        'citizenshipCountry': citizcountry,
        'sex': formData.sex,
        'email': userData.email,
        'phoneA': phoneaValue,
        'phoneP': formData.phoneP,
        'passportNumber': formData.passportNumber,
        'passportIssuingCountry': formData.passportIssuingCountry,
        'passportIssuingDate': formData.passportIssuingDate,
        'passportExpiringDate': formData.passportExpiringDate,
        'transit': 'Yes',
        'applicantEmployed': formData.applicantEmployed,
        'fee': searchResult.fee,
        'commission': searchResult.commision,
        'destination': searchData.destination,
        'arrival': searchData.citizenship,
        'commission_type': searchResult.commision_type
      };
      pdata = {
        "amount_cents": searchResult.fee,
        "reference_id": rnd,
        "is_live": "false",
        "full_name": userData.first_name,
        "email": userData.email,
        "save_selection": "true",
        "redirection_url": base_url+"/payment",
        "currency": searchResult.currency,
        "description": "Payment visa **",
        "payment_token": pkey
      };
    }
    sessionStorage.setItem("applicationData", JSON.stringify(order_details));
    var pldata = { vendor_key: vendorKey, payment_data: pdata, order_data: order_details }

    debugger;
    await fetch(order_payment_url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${usrtok}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pldata),
    })
      .then(response => response.json())
      .then(data => {
        sessionStorage.setItem("viplink", JSON.stringify(data.result[0].payment_link));
        window.location.href = `/verify`;
      })
      .catch(error => {
        console.error('Error posting data:', error);
      });





    // onSubmitForm(data, userData.access_token);
  };

  const today = new Date().toISOString().split('T')[0];


  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Handle link click to open modal
  const handleOpenModal = (event) => {
    event.preventDefault(); // Prevent the default link behavior
    setIsModalOpen(true);
  };

  // Handle close modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (

    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{t('Visa')} {t('Application')} {t('Form')}</h3>
          </div>
        </div>


      </div>


      <div class="features_section">
        <div class="container">

          <Row>

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">


              <div className="clformrow mt-1">


                <form onSubmit={handleSubmit}>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('First')} {t('Name')}</span>
                    </div>
                    <input type="text" name="firstName" className="form-control"
                      value={userData.first_name} onChange={handleChange} required />
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Last')} {t('Name')}</span>
                    </div>
                    <input type="text" name="lastName" className="form-control"
                      value={userData.last_name} onChange={handleChange} required />
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Upload')} {t('Passport')}</span>
                    </div>
                    {/* <label htmlFor="fileInput">Upload your image (png/jpg/jpeg):</label> */}
                    <input type="file" className="form-control" onChange={(event) => {
                      const file = event.target.files[0];
                      const maxSize = 2 * 1024 * 1024; // 5MB limit

                      if (file && file.size > maxSize) {
                        alert('File size must be less than 2MB');
                        event.target.value = ''; // Clear the input
                      } else {
                        handleImageChange(event, 'fileupload');
                      }
                    }} />
                    <small style={{ textAlign: 'right', display: 'block' }}>
                      Accepted formats: .png, .jpg, .jpeg. Maximum file size: 2MB.
                    </small>
                  </div>

                  {(pdata === 'Passport format not support') ?
                    <div className="input-group mb-2 mt-2" style={{ color: 'red' }}>Passport format
                      not supported. Please fill out the fields manually.</div> : ''}

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('DOB')}</span>
                    </div>
                    <input type="date" name="dob" max={today} className="form-control" value={dob}
                      onChange={handleChange} required />
                  </div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Birth')} {t('Country')}</span>
                    </div>
                    <input type="text" name="birthCountry" className="form-control"
                      value={birthcountry} onChange={handleChange} required />
                  </div>

                  {/* <div className="input-group mb-2 mt-2">
      <div className="input-group-prepend prepends">
        <span className="input-group-text">{t('Citizenship')}</span>
      </div>
      <input type="text" name="citizenshipCountry" className="form-control" value={searchData.citizenship} onChange={handleChange} required />
    </div> */}

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Gender')}</span>
                    </div>
                    <select name="sex" className="form-control" onChange={handlesexChange} required>
                      <option value="">-- Select --</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Email')}</span>
                    </div>
                    <input type="text" name="email" className="form-control" value={formData.email}
                      onChange={handleChange} required />
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Living')} {t('Country')}</span>
                    </div>

                    <div className="styledata">
                      <Select2Wrapper name="livingCountry"
                        options={options} className='form-control select2'
                        onChange={handlecitizenshipChange} required />
                    </div>

                    {/*<select name="citizenshipCountry" className="form-control"*/}
                    {/*        onChange={handlecitizenshipChange} required>*/}
                    {/*    <option value="">-- {t('Select')} --</option>*/}
                    {/*    {options && options.map((option) =>*/}
                    {/*        <option key={option.id}*/}
                    {/*                value={option.country}>{t(option.country)}</option>*/}
                    {/*    )}*/}
                    {/*</select>*/}
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Phone')}</span>
                    </div>
                    <input type="text" className="phonea" name="phoneA"
                      style={{ width: 60, border: '1px solid #dee2e6', padding: '0 13px' }}
                      value={phoneaValue} onChange={handlephoneaChange} required />
                    <input type="text" name="phoneP" className="form-control"
                      value={formData.phoneP} onChange={handleChange} required />
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Passport')} {t('Country')}</span>
                    </div>
                    <input type="text" name="passportCountry" className="form-control" readOnly
                      required value={searchData.citizenship} />
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Passport')} {t('Number')}</span>
                    </div>
                    <input type="text" name="passportNumber" className="form-control"
                      value={passportNumber} onChange={handleChange} required />
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span
                        className="input-group-text">{t('Passport')} {t('Issuing')} {t('Country')}</span>
                    </div>
                    <input type="text" name="passportIssuingCountry" className="form-control"
                      value={formData.passportIssuingCountry} onChange={handleChange}
                      required />
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span
                        className="input-group-text">{t('Passport')} {t('Issuing')} {t('Date')}</span>
                    </div>
                    <input type="date" max={today} name="passportIssuingDate"
                      className="form-control" value={passportIssuingDate}
                      onChange={handleChange} required />
                  </div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span
                        className="input-group-text">{t('Passport')} {t('Expiry')} {t('Date')}</span>
                    </div>
                    <input type="date" min={today} name="passportExpiringDate"
                      className="form-control" value={passportExpiringDate}
                      onChange={handleChange} required />
                  </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Employment')} {t('Status')}</span>
                    </div>
                    <input type="text" name="applicantEmployed" className="form-control"
                      value={formData.applicantEmployed} onChange={handleChange} required />
                  </div>


                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Visa')} {t('Fee')}</span>
                    </div>
                    <input type="text" name="fee" className="form-control" value={searchResult.fee}
                      required readOnly />
                  </div>

                  <br />
                  <p className={lnstr}>
                    {(lnstr === 'en') ? 'We use this to create your E-Visa and send you updates about your application' : 'نستخدم هذه المعلومات لإنشاء تأمينك وإرسال تحديثات حول طلبك'}
                  </p>

                  <p className={lnstr}><input type="checkbox" checked={isChecked}
                    onChange={handleCheckboxChange} name="note" /> <a href="" onClick={handleOpenModal}>
                      {(lnstr === 'en') ? 'I want to receive E-Visa updates, product launches and personalized offers. I can opt out anytime.' : 'أرغب في تلقي تحديثات التأمين، وإطلاقات المنتجات، والعروض الشخصية. يمكنني الانسحاب في أي وقت.\n'}
                    </a></p>


                  <button type="submit" disabled={!isChecked}
                    className="btn btn-primary rounded-pill text-white my-2 py-1 px-4">{t('Submit')}</button>


                </form>

              </div>

            </Col>

            <Col md={3}></Col>

          </Row>

          {loading && (
            <div className="overlay">
              <div className="spinner"></div>
            </div>
          )}

        </div>

      </div>



      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            position: 'relative',
            padding: '20px',
            maxWidth: '80%', // Set larger width for larger modal
            maxHeight: '80vh',
            overflow: 'auto',
            inset: 'auto',
            width: '100%',
          },
        }}
      >
        <div>
          <button
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              fontSize: '24px',
              cursor: 'pointer'
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <center>
            <h2 style={{ fontWeight: 600 }}>Form Filling Disclaimers for Visa Processing</h2>
          </center>
        </div>

        <br />
        <br />
        <br />

        <p><b style={{ fontWeight: 600 }}>Accuracy of Information</b>
          <br />
          • I hereby acknowledge that all information provided in this form is accurate and truthful to the best
          of my knowledge. I understand that providing false or misleading information may result in the rejection
          of my visa application and potential legal consequences.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Role of SuperJetOM</b>
          <br />
          • I understand and agree that SuperJetOM is a service provider facilitating the submission of my visa
          application. I acknowledge that the decision to grant or deny the visa is solely at the discretion of
          the relevant embassy and government authorities. SuperJetOM has no influence or control over the
          decision-making process of the visa application.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Processing Time and Delays</b>
          <br />
          • I acknowledge that visa processing times may vary and that SuperJetOM cannot guarantee any specific
          timeframe for the processing of my application. I understand that delays may occur due to various
          factors beyond the control of SuperJetOM.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Non-Refundable Fees</b>
          <br />
          • I understand that all fees paid to SuperJetOM for the visa processing service are non-refundable,
          regardless of the outcome of the visa application.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Additional Documentation</b>
          <br />
          • I acknowledge that the embassy or government authorities may request additional documentation or
          information during the processing of my visa application. I agree to promptly provide any such requested
          information to avoid delays in processing.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>No Guarantees of Approval</b>
          <br />
          • I understand that the submission of a visa application through SuperJetOM does not guarantee approval
          of the visa. The decision rests solely with the embassy and government authorities, and SuperJetOM
          cannot influence this outcome.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Legal Compliance</b>
          <br />
          • I agree to comply with all applicable laws and regulations related to the visa application process. I
          understand that any violation of these laws may result in the rejection of my application and potential
          legal consequences.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Privacy and Data Protection</b>
          <br />
          • I acknowledge that SuperJetOM will handle my personal information in accordance with its privacy
          policy and applicable data protection laws. I consent to the collection, processing, and storage of my
          personal data for the purpose of visa processing.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Changes to Application Requirements</b>
          <br />
          • I understand that visa application requirements and procedures may change without notice. I agree to
          provide any updated information or documentation as required by the embassy or government authorities.
          <br />
          <br />
          <b style={{ fontWeight: 600 }}>Communication and Notifications</b>
          <br />
          • I agree to receive communication and notifications from SuperJetOM regarding my visa application via
          the contact information provided in this form. I understand that it is my responsibility to ensure that
          my contact details are accurate and up to date.
        </p>
      </Modal>

      <Footer />

    </Fragment>

  );

};

export default Application;