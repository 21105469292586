import React, { Fragment , useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Select2Wrapper from './Select2Wrapper';
import Cookies from 'js-cookie';
const { config } = require('../src/api/api');

function Inboundtraveldetails() {
  const base_url = config.baseURL;
    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const [dhofarData, setDhofarData] = useState(false);  
    useEffect(() => {
    const storedDhofarString = Cookies.get('dhofarData'); //sessionStorage.getItem('userData')
      if (storedDhofarString!==undefined) {
        const storedDhofar = JSON.parse(storedDhofarString);
        setDhofarData(storedDhofar);
      }
      else
      {
        // alert("token expired");
        // window.location.href='/';
      }
    }, []);


    //history edit

    const [selectednOption, setSelectednOption] = useState(null);
    // useEffect(() => {
    //   const storedTravelHistory = sessionStorage.getItem('inboundtraveldetails');
    //     if (storedTravelHistory!=="undefined" && storedTravelHistory!=null) {
    //       const storedtravelHistory = JSON.parse(storedTravelHistory);
          
    //       const ndays = storedtravelHistory.cumulative_days!='' ? storedtravelHistory.cumulative_days : null;

    //       const numdays = ndays!=null ? parseInt(ndays, 10) : null;

    //       setSelectednOption(numdays);

    //     }
    // }, []);

    //

    

    


    const [responseData, setResponseData] = useState(null);


    const [vendorKey, setVendorKey] = useState('');
  
    useEffect(() => {

      const postvData = { organization_name: "Superjet" };

      if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
        
        
        fetch(base_url+'/create_organization', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(postvData),
          })
          .then(response => response.json())
          .then(data => {
            setVendorKey(data.result[0].vendor_key);
          })
          .catch(error => {
              console.error('Error posting data:', error);
          });


      }
    }, [vendorKey]); // Dependency array

//getproducts

  const postData = { token: dhofarData };

  if(responseData===null && vendorKey!=='')
  {

  fetch(base_url+'/getProducts', {
  method: 'POST',
  headers: {
      'Authorization': `Bearer ${vendorKey}`,
      'Content-Type': 'application/json',
  },
  body: JSON.stringify(postData),
  })
  .then(response => response.json())
  .then(data => {
    // debugger
      setResponseData(data.result.data[0]);
    
  })
  .catch(error => {
      console.error('Error posting data:', error);
  });

  }


    const { t } = useTranslation();

    const [selectedcOption, setSelectedcOption] = useState(null);
    const [selectedcOptionid, setSelectedcOptionid] = useState(null);
    

    const handlecOptionChange = (event) => {
      setSelectedcOptionid(event.target.id);
      setSelectedcOption(event.target.value);
    };
    const handlenOptionChange = (event) => {
      setSelectednOption(event.target.value);
    };

    const handleclick = () => {

        if(selectedcOption==null)
        {
          alert('Please choose coverage');
          return false;
        }
        else if(selectednOption==null)
        {
          alert('Please select number of days');
          return false;
        }
        else
        {


      var data   = {

        "inbound_product_type_id": "2",
        "inbound_product_type_name": responseData.type.description,
        "inbound_product_id": responseData.productId,
        "inbound_product_name": responseData.productDescription,
        "inbound_coverage_id": selectedcOptionid,
        "inbound_coverage_name": selectedcOption,
        "inbound_period_type_id": 1,
        "inbound_period_value_id": 6,
        "cumulative_days": selectednOption,

      }

      sessionStorage.setItem("inboundtraveldetails", JSON.stringify(data));

      window.location.href = `/inbound-travel-info`;

      }
      
    };


  return (
    <Fragment>

    <div className="header_section1">

    <Navbar />


    <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">            
              <h3 className="abt-txt my-5">{(lnstr === 'en' ? 'Inbound Travel' : 'السفر الداخلي ')}</h3>
          </div>
      </div>


    </div>
       
 
        <div className="features_section my-1">
        <div className="container">
            
            <div className="row">

                <Col md={3}></Col>

                    <Col md={6} className="myforms my-5"> 


                        <div>

{responseData && (
                
                <div>

                            <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Select Product' : 'اختيار المنتج'}</h4>

                            <div class="align-items-center travel-selection-option">
                                {responseData.productDescription}
                                <input type="radio" name="travelplan" checked className="d-inline-block option-circle ml-auto" />
                            </div>


                            <h4 className="px-0 py-2 mt-3">{(lnstr === 'en') ? 'Coverage Selection' : 'اختيار التغطية'}</h4>

                            {responseData.packages.map((option) => 

                            <div class="align-items-center travel-selection-option mt-2">
                                {option.coverageArea.name}
                                <input type="radio" name="coverageselection" id={option.coverageArea.id} value={option.coverageArea.name} className="d-inline-block option-circle ml-auto" onChange={handlecOptionChange} />
                            </div>

                            )}


                            <h4 className="px-0 py-2 mt-3">{(lnstr === 'en') ? 'Number Of Days' : 'عدد الأيام'}</h4>

                    <select className="form-control" value={selectednOption} onChange={handlenOptionChange} name="numberofdays"
                            style={{padding: 8, direction: 'ltr' }}>
                        <option value="">{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                        <option value="30">30</option>
                        <option value="60">60</option>
                        <option value="90">90</option>
                    </select>


                    <button type="button" onClick={handleclick} className="btn btn-primary rounded-pill text-white my-3 py-1 px-4">{t('Submit')}</button>
                            
                            </div>

                          )}

{!responseData && (
  <div className="overlay">
    <div className="spinner"></div>
  </div>
)}

                            </div>




                    </Col>

<Col md={3}></Col>

</div>
            
            </div>
          </div>

    <Footer />

    

    </Fragment>
  );
}

export default Inboundtraveldetails;