export const SUBMIT_FORM = 'SUBMIT_FORM';
export const SUBMIT_FORM_FAIL = 'SUBMIT_FORM_FAIL';
export const SUBMIT_FORM_SUCCESS = 'SUBMIT_FORM_SUCCES';
export const FORM_SUBMITTED = 'FORM_SUBMITTED';

export const AGENCY_REGISTER = 'AGENCY_REGISTER';
export const AGENCY_REGISTER_FAIL = 'AGENCY_REGISTER_FAIL';
export const AGENCY_REGISTER_SUCCESS = 'AGENCY_REGISTER_SUCCESS';
export const submitForm = (formData) => ({
  type: SUBMIT_FORM,
  payload: formData,
});
export const formSubmitted = () => ({
  type: FORM_SUBMITTED,
});

// export const agencyRegisterAction = (data) => ({
//   debugger
//   type: AGENCY_REGISTER,
//   data: data
// });
export const agencyRegisterAction = (payload) => {
  return {
    type: AGENCY_REGISTER,
    payload,
  }
};