import { combineReducers } from 'redux';
import signupReducer from './signup/redux/reducer/reducer';
import loginReducer from './login/redux/reducer/reducer';
import applicationReducer from './application/redux/reducer/reducer';
import searchReducer from './search/redux/reducer/reducer';

const rootReducer = combineReducers({
  signup: signupReducer,
  login: loginReducer,
  application: applicationReducer,
  visasearch: searchReducer
});

export default rootReducer;