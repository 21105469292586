export const SEARCH_SUBMIT_FORM = 'SEARCH_SUBMIT_FORM';
export const SEARCH_SUBMIT_FORM_FAIL = 'SEARCH_SUBMIT_FORM_FAIL';
export const SEARCH_SUBMIT_FORM_SUCCESS = 'SEARCH_SUBMIT_FORM_SUCCES';
export const SEARCH_FORM_SUBMITTED = 'SEARCH_FORM_SUBMITTED';

export const submitForm = (formData) => ({
  
  type: SEARCH_SUBMIT_FORM,
  payload: formData,
});



export const formSubmitted = () => ({
  type: SEARCH_FORM_SUBMITTED,
});
