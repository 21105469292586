export const LOGIN_SUBMIT_FORM = 'LOGIN_SUBMIT_FORM';
export const LOGIN_SUBMIT_FORM_FAIL = 'LOGIN_SUBMIT_FORM_FAIL';
export const LOGIN_SUBMIT_FORM_SUCCESS = 'LOGIN_SUBMIT_FORM_SUCCES';
export const LOGIN_FORM_SUBMITTED = 'LOGIN_FORM_SUBMITTED';

export const submitForm = (formData) => ({
  
  type: LOGIN_SUBMIT_FORM,
  payload: formData,
});



export const formSubmitted = () => ({
  type: LOGIN_FORM_SUBMITTED,
});
