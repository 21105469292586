import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

function Apipage() {

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const { t } = useTranslation();

  return (
    <div>

        <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
            <div className="container text-center py-5">            
                <h3 className="abt-txt my-5">API</h3>
            </div>
        </div>


        </div>
        

        <div className="features_section layout_padding my-1">
        <div className="container">


                <div className="row g-4 justify-content-center">

                    <div className="col-12 p-0 mt-3 wow fadeInUp" data-wow-delay="0.2s">

                        {/*{(lnstr==='en') ? '' :*/}
                        {/*    <div className={lnstr}>*/}
                        {/*        <b style={{ color: "#33d0f2" }}>التعاون معنا</b>*/}
                        {/*        <br/>*/}
                        {/*        <br/>*/}
                        {/*        <br/>*/}
                        {/*    </div>*/}
                        {/*      }*/}


                        {/*{(lnstr==='en') ? '' :*/}
                        {/*    <div className={lnstr}>*/}
                        {/*        <b style={{ color: "#33d0f2" }}>واجهة برمجة التطبيقات (API)</b>*/}
                        {/*    </div>*/}
                        {/*}*/}

                        {(lnstr === 'en') ? '' : <div className={lnstr}>في SuperJet، نقدم تكاملاً سلساً من خلال API  المتقدمة لدينا، مما يتيح لك تعزيز عروض خدماتك وزيادة إيراداتك. تم تصميم API لدينا لتبسيط عملية الحصول على التأشيرات الإلكترونية والتأمين، مما يوفر لعملائك تجربة سلسة وفعالة.
                        </div>

                        }





                        {(lnstr==='en') ? <div className={lnstr}>At SuperJet, we offer seamless integration through our advanced APIs, allowing you to enhance your service offerings and boost your revenue. Our APIs are designed to simplify the process of obtaining eVisas and insurance, providing your customers with a smooth and efficient experience.</div>
                        : ''
                        // <div className={lnstr}>في سوبرجيت، نقدم تكاملاً سلسًا من خلال واجهات برمجة التطبيقات المتقدمة الخاصة بنا، مما يسمح لك بتحسين عروض خدماتك وزيادة إيراداتك. تم تصميم واجهات برمجة التطبيقات لدينا لتبسيط عملية الحصول على التأشيرات الإلكترونية والتأمين، مما يوفر لعملائك تجربة سلسة وفعالة.</div>
                        }

                        <br />


                        {(lnstr === 'en') ?
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>Benefits of Partnering with SuperJet:</b>
                            </div>
                            :
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>فوائد الشراكة مع SuperJet :</b>
                            </div>
                        }


                        {(lnstr === 'en') ?
                            <div className={lnstr}>
                                <ul className="custom-list">
                                    <li> <b>Increase Revenue:</b> Earn on each successful transaction.</li>
                                    <li> <b>Enhance Customer Satisfaction:</b> Provide your customers with instant quotes from leading insurers and help them navigate the visa application process.</li>
                                    <li> <b>Expand Service Offerings:</b> Offer additional services beyond your current offerings, increasing your relevance to customers.</li>
                                    <li> <b>Simple Integration:</b> Our APIs are easy to integrate into your app and website, creating innovative customer service and loyalty program use cases.</li>
                                </ul>
                            </div>
                        :
                        <div className={lnstr}>
                            <ul>

                                <li><b>زيادة الإيرادات: </b>إربح على كل عملية ناجحة.</li>
                                <li><b>تعزيز رضا العملاء: </b>قدم لعملائك عروض أسعار فورية من شركات التأمين الرائدة وساعدهم في التنقل عبر عملية تقديم الطلبات للتأشيرات.</li>

                                <li><b>توسيع عروض الخدمات: </b>قدم خدمات إضافية بخلاف عروضك الحالية، مما يزيد من أهميتك لدى العملاء.</li>

                                <li><b>دمج بسيط: </b>API لدينا سهلة التكامل مع تطبيقك وموقعك الإلكتروني، مما يخلق حالات استخدام مبتكرة لخدمة العملاء وبرامج الولاء.
                                </li>
                            </ul>
                        </div>}
                        <br />


                        {(lnstr === 'en') ?
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>How It Works:</b>
                            </div>
                            :
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>آلية العمل :</b>
                            </div>
                        }

                        {(lnstr === 'en') ?
                            <div className={lnstr}>
                            <ul className="custom-list">
                                    <li> <b>Inform:</b> Educate your customers about the correct requirements for their trip and insurance.</li>
                                    <li> <b>Guide:</b> Help your customers obtain their visas and insurance in a simple and secure way.</li>
                                    <li> <b>Verify:</b> Ensure customers have all the necessary documents.</li>
                                </ul>
                            </div>
                        :
                        <div className={lnstr}>
                            <ul>

                                <li><b>التثقيف: </b>قم بتثقيف عملائك حول المتطلبات الصحيحة لرحلتهم والتأمين.</li>
                                <li><b>الإرشاد: </b>ساعد عملائك في الحصول على تأشيراتهم وتأمينهم بطريقة بسيطة وآمنة.</li>
                                <li><b>التحقق:  </b>تأكد من أن العملاء لديهم جميع الوثائق اللازمة.</li>
                            </ul>
                        </div>}


                        <br />

                        {(lnstr === 'en') ?
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>Contact</b> : <a style={{ color: "blue", textDecoration: "none" }} href="mailto:admin@superjetom.com">admin@superjetom.com</a>
                            </div>
                            :
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>التواصل</b> : <a style={{ color: "blue", textDecoration: "none" }}
                                href="mailto:admin@superjetom.com">admin@superjetom.com</a>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>


        <Footer />

    </div>
  );
}

export default Apipage;