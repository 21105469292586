import { takeLatest, call, put  } from 'redux-saga/effects';
import { SEARCH_SUBMIT_FORM, SEARCH_SUBMIT_FORM_SUCCESS, SEARCH_SUBMIT_FORM_FAIL } from '../actions/actions';
import * as api  from '../../../api/index'

function* submitForm(action) {
  // debugger


  
  try{
    var result = yield call(api.api.makeVisasearch, action.payload);
  
    if(result['message'] === 'success'){
    yield put({type:SEARCH_SUBMIT_FORM_SUCCESS, result});
    }else{
      yield put({type:SEARCH_SUBMIT_FORM_FAIL, result});
    }
    // Here you can perform side effects like API calls, etc.
    // For this example, we'll just dispatch an action
    // yield put(formSubmitted());
    }catch(err){
    console.log(err);
    }

  // yield call(api.signUp, action.payload);
 

  // var res = {'message': 'done'};
  // if(res['message'] === 'done'){
  // yield put({type:SEARCH_SUBMIT_FORM_SUCCESS, res});
  // }else{
  //   yield put({type:SEARCH_SUBMIT_FORM_FAIL, res});
  // }
  // Here you can perform side effects like API calls, etc.
  // For this example, we'll just dispatch an action
  // yield put(formSubmitted());

}

export default function* searchSaga() {
  // debugger
  yield takeLatest(SEARCH_SUBMIT_FORM, submitForm);
}