import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';

function Services() {

    const { t } = useTranslation();

  return (
    <div>

        <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
            <div className="container text-center py-5">            
                <h3 className="abt-txt my-5">{t('SERVICES')}</h3>
            </div>
        </div>


        </div>
        

        <div className="features_section">
                
        <div className="work_main pt-5">
            <h1 className="features_text">WHAT <span className="htext">WE DO</span></h1>
            <div className="work_section2">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="choose_main">
                  <div className="choose_left">
                    <h1 className="number_text"><a href="#">01</a></h1>
                  </div>
                  <div className="choose_right">
                    <h1 className="choose_text">{t('Visa')} {t('Services')}</h1>
                    <p className="many_text">{t('Simplifying')} {t('visa')} {t('application')} {t('processes')} {t('can')} {t('have')} {t('a')} {t('multitude')} {t('of')} {t('benefits,')} {t('from')} {t('reducing')} {t('paperwork')} {t('and')} {t('administrative')} {t('burdens')} {t('to')} {t('speeding')} {t('up')} {t('the')} {t('overall')} {t('process')} {t('for')} {t('travellers')}.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="choose_main">
                  <div className="choose_left">
                    <h1 className="number_text"><a href="#">02</a></h1>
                  </div>
                  <div className="choose_right">
                    <h1 className="choose_text">{t('Insurance')} {t('Services')}</h1>
                    <p className="many_text">{t('Whether')} {t('seeking')} {t('coverage')} {t('for')} {t('personal')} {t('needs')} {t('or')} {t('business')} {t('operations')}, {t('insurance')} {t('services')} {t('offer')} {t('essential')} {t('safeguards')} {t('in')} {t('an')} {t('unpredictable')} {t('world')}.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
                
        </div>


        <Footer />

    </div>
  );
}

export default Services;