import { takeLatest, call, put  } from 'redux-saga/effects';
import { APP_SUBMIT_FORM, APP_SUBMIT_FORM_SUCCESS, APP_SUBMIT_FORM_FAIL } from '../actions/actions';
import * as api  from '../../../api/index'

function* submitForm(action) {
  // debugger


  
try{
  var result = yield call(api.api.maketestApplication, action.payload);
 

  if(result['message'] === 'order placed succesfully'){
  yield put({type:APP_SUBMIT_FORM_SUCCESS, result});
  }else{
    yield put({type:APP_SUBMIT_FORM_FAIL, result});
  }
  // Here you can perform side effects like API calls, etc.
  // For this example, we'll just dispatch an action
  // yield put(formSubmitted());
  }catch(err){
  console.log(err);
  }

  // yield call(api.signUp, action.payload);
 

  // var res = {'message': 'done'};
  // if(res['message'] === 'done'){
  // yield put({type:APP_SUBMIT_FORM_SUCCESS, res});
  // }else{
  //   yield put({type:APP_SUBMIT_FORM_FAIL, res});
  // }
  // Here you can perform side effects like API calls, etc.
  // For this example, we'll just dispatch an action
  // yield put(formSubmitted());

}

export default function* appSaga() {
  // debugger
  yield takeLatest(APP_SUBMIT_FORM, submitForm);
}