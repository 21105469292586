import React, {Fragment, useState, useEffect} from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import {Row, Col} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import Cookies from 'js-cookie';

function Orderfailed() {

    const {t} = useTranslation();

    const [orderData, setOrderData] = useState(false);
    useEffect(() => {
        // debugger;
        const storedOrderString = sessionStorage.getItem('orderResult');
        if (storedOrderString) {
            const storedOrder = JSON.parse(storedOrderString);
            setOrderData(storedOrder);
        }
    }, []);


    const urlSearchString = window.location.search;

    const uparams = new URLSearchParams(urlSearchString);

    const failStyle = {
        display: "inline-block",
        position: "relative",
        border: "5em solid currentColor",
        borderRadius: "50%",
        fontSize: "0.4px",
        width: "50em",
        height: "50em",
        color: "#e27d7a",

    };

    const beforeAfterStyle = {
        content: '""',
        width: "5em",
        height: "34em",
        position: "absolute",
        left: "50%",
        top: "50%",
        marginTop: "-17em",
        marginLeft: "-2em",
        backgroundColor: "currentColor",
        borderRadius: "3px",
    };

    const beforeStyle = {
        ...beforeAfterStyle,
        transform: "rotate(45deg)",
    };

    const afterStyle = {
        ...beforeAfterStyle,
        transform: "rotate(-45deg)",
    };


    return (
        <div className="App">


            <div className="header_section1">

                <Navbar/>


                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{t('Order')}</h3>
                    </div>
                </div>
            </div>

            <div className="features_section layout_padding my-5">
                <div className="container">
                    <Row className="justify-content-center align-items-center">
                        <Col md={6} className="text-center">
                            <div style={{...failStyle, fontSize: "1.5px"}}>
                                <span style={beforeStyle}/>
                                <span style={afterStyle}/>
                            </div>
                            <div>
                                <h3 style={{textAlign: 'center'}}>
                                    Payment Failed
                                </h3>
                            </div>
                        </Col>
                    </Row>

                    {/*      <div className="row ">
              
            <Col md={3} className="text-center">
                            </Col>

                <Col md={6} className="m-2 mt-4">



                    <div style={{...failStyle, fontSize: "1.5px"}}>
                        <span style={beforeStyle}/>
                        <span style={afterStyle}/>
                    </div>

                    <div>
                        <h3 className="h3" style={{textAlign: 'center'}}>
                            Payment Failed
                        </h3>
                    </div>


                </Col>

                <Col md={3} className="text-center">
                </Col>


            </div>*/}

                </div>
            </div>

            <Footer/>

        </div>
    );
}

export default Orderfailed;