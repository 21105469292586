import { LOGIN_FORM_SUBMITTED, LOGIN_SUBMIT_FORM_FAIL, LOGIN_SUBMIT_FORM_SUCCESS } from '../actions/actions';

const initialState = {
  submitted: false,
  logindata: null,
  message: null,
  error: null
};

const formReducer = (state = initialState, action) => {
//   debugger
  switch (action.type) {
    case LOGIN_FORM_SUBMITTED:
      return {
        ...state,
        logindata: null,
        message: null,
        error: null
      };
      case LOGIN_SUBMIT_FORM_FAIL:
        // debugger
        return {
          ...state,
            logindata: null,
            message: action.result.message,
            error: null
        };
        case LOGIN_SUBMIT_FORM_SUCCESS:
          // debugger
          return {
            ...state,
            logindata: action.result.result[0],
            message: action.result.message,
            error: null
          };
    default:
      return state;
  }
};

export default formReducer;