// src/App.js

import React from 'react';
import { GoogleLogin } from 'react-google-login';

// Replace with your actual client ID
const clientId = '285050622574-krv8v2fv1j29orqmorsjn0lot7khii8r.apps.googleusercontent.com';

function Demo() {
  const responseGoogle = (response) => {
    console.log(response);
    // Handle the response from Google here
    // For example, send response.tokenId to your server for authentication
  };

  return (
    <div className="App">
      <h1>Google Login Example</h1>
      <GoogleLogin
        clientId={clientId}
        buttonText="Login with Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
      />
    </div>
  );
}

export default Demo;