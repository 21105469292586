import React from 'react';

const DhofarIframeComponent = () => {
  return (
    <div style={{ margin: 0, padding: 0, overflow: 'hidden' }}>
      <iframe
        src="https://besafe-dev.dhofarinsurance.com/welcome/affiliate/EdcLJVnz9QXWqMp3U6bwOr6zLof2"
        title="Example Website"
        style={{
            width: '100vw',
            height: '100vh',
            border: 'none',
            display: 'block', // Removes the iframe's bottom gap
          }}
      />
    </div>
  );
};

export default DhofarIframeComponent;