import { takeLatest, call, put  } from 'redux-saga/effects';
import { LOGIN_SUBMIT_FORM, LOGIN_SUBMIT_FORM_SUCCESS, LOGIN_SUBMIT_FORM_FAIL } from '../actions/actions';
// import api from '../../../api/api'; // Your API functions
import * as api  from '../../../api/index'

function* submitForm(action) {
  
  debugger

  try{
    var result = yield call(api.api.makeUserLoginCall, action.payload);
   
  
    // var res = {'message': 'success', 'username': result['result'][0]['first_name']};
    if(result['message'] === 'success'){
    yield put({type:LOGIN_SUBMIT_FORM_SUCCESS, result});
    }else{
      yield put({type:LOGIN_SUBMIT_FORM_FAIL, result});
    }
    // Here you can perform side effects like API calls, etc.
    // For this example, we'll just dispatch an action
    // yield put(formSubmitted());
  }catch(err){
  console.log(err);
  }


  // yield call(api.signUp, action.payload);
 

  // var res = {'message': 'success'};
  // if(res['message'] === 'success'){
  // yield put({type:LOGIN_SUBMIT_FORM_SUCCESS, res});
  // }else{
  //   yield put({type:LOGIN_SUBMIT_FORM_FAIL, res});
  // }

  // Here you can perform side effects like API calls, etc.
  // For this example, we'll just dispatch an action
  // yield put(formSubmitted());
}

export default function* loginSaga() {
  // debugger
  yield takeLatest(LOGIN_SUBMIT_FORM, submitForm);
}