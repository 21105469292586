import { takeLatest, call, put } from 'redux-saga/effects';
import { SUBMIT_FORM, SUBMIT_FORM_SUCCESS, SUBMIT_FORM_FAIL, AGENCY_REGISTER, AGENCY_REGISTER_SUCCESS, AGENCY_REGISTER_FAIL } from '../actions/actions';
import * as api from '../../../api/index'

function* submitForm(action) {
  // debugger


  try {

    var result = yield call(api.api.makeUserSignUpCall, action.payload);


    // var res = {'message': 'success', 'username': result['result'][0]['first_name']};
    if (result['message'] === 'user added successfully') {
      yield put({ type: SUBMIT_FORM_SUCCESS, result });
    } else {
      yield put({ type: SUBMIT_FORM_FAIL, result });
    }
    // Here you can perform side effects like API calls, etc.
    // For this example, we'll just dispatch an action
    // yield put(formSubmitted());
  } catch (err) {
    console.log(err);
  }



  // yield call(api.signUp, action.payload);


  // var res = {'message': 'weldone'};
  // if(res['message'] === 'weldone'){
  // yield put({type:SUBMIT_FORM_SUCCESS, res});
  // }else{
  //   yield put({type:SUBMIT_FORM_FAIL, res});
  // }
  // Here you can perform side effects like API calls, etc.
  // For this example, we'll just dispatch an action
  // yield put(formSubmitted());
}
function* signupAgency(action) {
  debugger
  try {
    var result = yield call(api.api.makeAgencySignUpCall, action.payload);
    if (result['message'] === 'agent added successfully') {
      yield put({ type: AGENCY_REGISTER_SUCCESS, result });
      // alert('Agent Successfully Created');
      setTimeout(() => {
        window.location.href='/agent-verify';
      }, 1000); 
    } else {
      yield put({ type: AGENCY_REGISTER_FAIL, result });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* formSaga() {
  // debugger
  yield takeLatest(SUBMIT_FORM, submitForm);
  yield takeLatest(AGENCY_REGISTER, signupAgency);

}