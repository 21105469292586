import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { connect } from 'react-redux';
import { confirmsubmitForm } from "./redux/actions/actions";
import { useTranslation } from 'react-i18next';
import { Col } from "react-bootstrap";
import Cookies from 'js-cookie';
import { endPoints } from '../api/api';
import { config } from '../api/api';

// var base64 = require('base-64');
const ConfirmPassword = ({ onSubmitForm, message, loginresult, error }) => {
  const base_url = config.baseURL;
  const [vendorKey, setVendorKey] = useState('');
  const [formData, setFormData] = useState({
    id: '',
    email: '',
    password: '',
    confirmpassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, confirmsetPasswordVisible] = useState(false);
  const [searchData, setSearchData] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    const urlSearchString = window.location.search;
    const uparams = new URLSearchParams(urlSearchString);
    const email = uparams.get('email');
    const id = uparams.get('id');

    var decodeed_mail = decodeURIComponent(escape(window.atob(email)));
    var decodeed_id = decodeURIComponent(escape(window.atob(id)));
    // decodeURIComponent(escape(window.atob(base64)));
    //  const decodedAgencyId = Buffer.from(id, 'base64').toString('utf-8');
    //         const decodedEmail = Buffer.from(email, 'base64').toString('utf-8');
    setFormData(prevState => ({
      ...prevState,
      email: decodeed_mail || '',
      id: decodeed_id || ''
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const ConfirmtogglePasswordVisibility = () => {
    confirmsetPasswordVisible(!confirmpasswordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setLoading(true);

    const data = {
      email: formData.email,
      password: formData.password,
      confirm_password: formData.confirmpassword,
      id: formData.id
    };

    if (data.password === data.confirm_password) {
      //       try {
      //         await onSubmitForm(data);
      //         const url = 'https://api.superjetom.com/set_agency_password';
      //         debugger
      //         const update_data = {
      //           "agency_id": formData.id,
      //           "password": formData.password
      //         };
      // // debugger
      //         await fetch('https://api.superjetom.com/set_agency_password', {
      //           method: 'POST',
      //           headers: {
      //             'Content-Type': 'application/json'
      //           },
      //           body: JSON.stringify(update_data),

      //         })
      //           .then(response => {
      //             if (!response.ok) {
      //               throw new Error(`HTTP error! Status: ${response.status}`);
      //             }
      //             return response.json();
      //           })
      //           .then(data1 => {
      //             console.log('Response data:', data1);
      //           })
      //           .catch(error => {
      //             console.error('Fetch error:', error);
      //           });
      //       } finally {
      //         setLoading(false);
      //       }
      // try {
      //   await onSubmitForm(data);
      //   const url = 'https://api.superjetom.com/set_agency_password';

      //   const update_data = {
      //     agency_id: formData.id,
      //     password: formData.password
      //   };

      //   const response = await fetch(url, {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify(update_data),
      //   });

      //   if (!response.ok) {
      //     throw new Error(`HTTP error! Status: ${response.status}`);
      //   }

      //   const data1 = await response.json();
      //   console.log('Response data:', data1);
      // } catch (error) {
      //   console.error('Fetch error:', error);
      // } finally {
      //   setLoading(false);
      // }
      debugger
      var update_data = {
        "agency_id": formData.id,
        "password": formData.confirmpassword,
      }
      fetch(base_url+'/set_agency_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(update_data),
      })
        .then(response => response.json())
        .then(data => {
          debugger
          if (data.message == 'success') {
            alert("Password Set Successfully");
            setTimeout(() => {
              window.location.href = '/login';
            }, 1000);
          }

          // setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });



      Cookies.remove('userData');
    } else {
      alert("Password does not match");
      setLoading(false);
    }
  };

  const lnstr = Cookies.get('langData') || 'en';

  return (
    <Fragment>
      <Navbar />
      <div className="features_section layout_padding my-5">
        <div className="container">
          <div className="row">
            <Col md={3}></Col>
            <Col md={6} className="myforms">
              <h1 className={lnstr}>{(lnstr === 'en') ? 'Set Password' : 'تعيين كلمة المرور'}</h1>
              <form onSubmit={handleSubmit}>
                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{t('Email')}</span>
                  </div>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    readOnly
                  />
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{t('Password')}</span>
                  </div>
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    name="password"
                    className="form-control"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <button type="button" className="btn btn-outline-grey" onClick={togglePasswordVisibility}>
                    {passwordVisible ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                  </button>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{t('Confirm password')}</span>
                  </div>
                  <input
                    type={confirmpasswordVisible ? 'text' : 'password'}
                    name="confirmpassword"
                    className="form-control"
                    value={formData.confirmpassword}
                    onChange={handleChange}
                    required
                  />
                  <button type="button" className="btn btn-outline-grey" onClick={ConfirmtogglePasswordVisibility}>
                    {confirmpasswordVisible ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                  </button>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary rounded-pill text-white my-2 py-1 px-4"
                >
                  {t('Submit')}
                </button>
                {message && message !== 'success' && (
                  <span style={{ color: 'red' }}>{message}</span>
                )}
              </form>
            </Col>
            <Col md={3}></Col>
          </div>
        </div>
      </div>
      <Footer />
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  // onSubmitForm: (formData) => dispatch(confirmsubmitForm(formData)),
});

export default connect(null, mapDispatchToProps)(ConfirmPassword);
