import Cookies from 'js-cookie';

const Logout = () => {

    Cookies.remove('userData');
  
    const langstr = sessionStorage.getItem('langData') ? sessionStorage.getItem('langData') : 'en';
    
    sessionStorage.clear();

    sessionStorage.setItem("langData", langstr);

    window.location.href = '/';
  
};

export default Logout;