import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { connect } from 'react-redux';
import { confirmsubmitForm } from "./redux/actions/actions";
import { useTranslation } from 'react-i18next';
import { Col } from "react-bootstrap";
import Cookies from 'js-cookie';
import { endPoints } from '../api/api';
import { config } from '../api/api';

// var base64 = require('base-64');
const AgentCreated = ({ onSubmitForm, message, loginresult, error }) => {
  const [vendorKey, setVendorKey] = useState('');
  const [formData, setFormData] = useState({
    id: '',
    email: '',
    password: '',
    confirmpassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, confirmsetPasswordVisible] = useState(false);
  const [searchData, setSearchData] = useState(null);

  const { t } = useTranslation();

  // useEffect(() => {
  //   const urlSearchString = window.location.search;
  //   const uparams = new URLSearchParams(urlSearchString);
  //   const email = uparams.get('email');
  //   const id = uparams.get('id');

  //   var decodeed_mail = decodeURIComponent(escape(window.atob(email)));
  //   var decodeed_id = decodeURIComponent(escape(window.atob(id)));
  //   debugger
  //   // decodeURIComponent(escape(window.atob(base64)));
  //   //  const decodedAgencyId = Buffer.from(id, 'base64').toString('utf-8');
  //   //         const decodedEmail = Buffer.from(email, 'base64').toString('utf-8');
  //   var update_data = {
  //     "email": decodeed_mail,
  //     "id": decodeed_id,
  //   }
  //   fetch('https://api.superjetom.com/send_mail_to_admin', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(update_data),
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       debugger
  //       if (data.message == 'success') {
  //         // alert("Password Set Successfully");
  //         // setTimeout(() => {
  //         //   window.location.href = '/login';
  //         // }, 1000);
  //       }

  //       // setVendorKey(data.result[0].vendor_key);
  //     })
  //     .catch(error => {
  //       console.error('Error posting data:', error);
  //     });
  // }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const ConfirmtogglePasswordVisibility = () => {
    confirmsetPasswordVisible(!confirmpasswordVisible);
  };

  const lnstr = Cookies.get('langData') || 'en';

  return (
    <Fragment>
      <Navbar />
      <div className="features_section layout_padding my-5">
        <div className="container">
          <div className="row">
            <Col md={3}></Col>
            <h1 style={{ fontWeight: 'bold' , fontSize: 17}} className={lnstr}>{(lnstr === 'en') ? 'Your request has been sent to our admin. You will receive a message at your registered email once the verification is complete.' : 'لقد تم إرسال طلبك إلى المشرف لدينا. ستتلقى رسالة على بريدك الإلكتروني المسجل بمجرد اكتمال عملية التحقق.'}</h1>
          </div>
        </div>
      </div>
      <Footer />
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  // onSubmitForm: (formData) => dispatch(confirmsubmitForm(formData)),
});

export default connect(null, mapDispatchToProps)(AgentCreated);
