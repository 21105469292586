import { APP_FORM_SUBMITTED, APP_SUBMIT_FORM_FAIL, APP_SUBMIT_FORM_SUCCESS } from '../actions/actions';

const initialState = {
  submitted: false,
  message: null,
  appdata: null,
  error: null
};

const formReducer = (state = initialState, action) => {
//   debugger
  switch (action.type) {
    case APP_FORM_SUBMITTED:
      return {
        ...state,
        message: null,
        appdata: null,
        error: null
      };
      case APP_SUBMIT_FORM_FAIL:
        // debugger
        return {
          ...state,
          appdata: null,
          message: action.result.message,
          error: null
        };
        case APP_SUBMIT_FORM_SUCCESS:
          // debugger
          return {
            ...state,
            appdata: action.result.result[0],
            message: action.result.message,
            error: null
          };
    default:
      return state;
  }
};

export default formReducer;