import Cookies from 'js-cookie';

const MedicalSessionRemove = () => {

    sessionStorage.removeItem("medicalbasicinfo");
    sessionStorage.removeItem("medicalpremiumcover");
    // sessionStorage.removeItem("personalaccidentbeneficiaries");
    sessionStorage.removeItem("medicalinformationpage");
    // sessionStorage.removeItem("personalaccdientPolicynumber");
  
};

export default MedicalSessionRemove ;