import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './planeblack.png';
import Cookies from 'js-cookie';

function Partner() {

  const { t } = useTranslation();

  const cardContainerRef = useRef(null)

  // const [insuretoken, setInsuretoken] = useState(null);
  // useEffect(() => {
  //     fetch('https://api.superjetom.com/create_inbound_token')
  //       .then(response => response.json())
  //       .then(data => {
  //         // debugger;
  //         setInsuretoken(data.result.data.token);
  //       })
  //       .catch(error => {
  //         console.error('Error fetching countries:', error);
  //       });
  //   }, []);


  useEffect(() => {
    const container = cardContainerRef.current;
    let scrollInterval;

    if (container) {
      // Start an interval to scroll the container
      scrollInterval = setInterval(() => {
        if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
          
          container.scrollLeft = 0;
        } else {
          container.scrollLeft += 1; 
        }
      }, 20); 

      return () => clearInterval(scrollInterval);
    }
  }, []);

  const handleClick = () => {

    // if(Cookies.get('userData'))
    // {


    window.location.href = 'travel-insurance';
    // window.location.href = 'personal-accident-insurance';

    // }
    // else
    // {
    //     sessionStorage.setItem("searchData", "");
    //     alert("Login required.");
    //     window.location.href = '/Login';
    // }

  };

  const handleClick2 = () => {
    window.location.href = 'personal-accident-insurance';

  };

  const handleClick4 = () => {
    window.location.href = 'medical-insurance';

};

const handleClick3 = () => {
window.location.href = 'life-accident-insurance';

};

const handleClick5 = () => {
window.location.href = 'cyber-plan';

};

const handleClick6 = () => {
  window.location.href = 'motorinsurance';
  
  };

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  return (
    // <div className="App">


    //   <div className="header_section1">

    //     <Navbar />


    //     <div className="container-fluid bg-breadcrumb">
    //       <div className="container text-center py-5">
    //         <h3 className="abt-txt my-5">{t('INSURANCE')}</h3>
    //       </div>
    //     </div>


    //   </div>




    //   <div class="features_section layout_padding">
    //     <div class="container">
    //       <h1 class="features_text">

    //         {(lnstr === 'en') ? <div> {t('Our')} <span className="htext"> {t('Products')}</span> </div> : 'منتجاتنا '}

    //       </h1>
    //       <p className="text-center undertxt">{t('Offered by us to cover all your needs')}.</p>
    //       <div class="features_section2">
    //         <div class="row">

    //           <div className="col-md-6 col-lg-3 col-xl-3 wow fadeInUp">

    //           </div>

              
    //           <div className="col-md-6 col-lg-3 col-xl-3 text-center wow fadeInUp" style={{ cursor: 'pointer' }} data-wow-delay="0.1s" onClick={handleClick}>
    //             <div className="service-item rounded">
    //               <div className="service-img rounded-top">

    //               </div>
    //               <div className="service-content rounded-bottom card bg-light p-4">
    //                 <div className="service-content-inner">
    //                   {/* <img src={travelimg} alt="SUPERJET" /> */}
    //                   <p className="mt-5 pt-5 text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
    //                   <h5 className="mt-3 text-center">{t('Travel Insurance')}</h5>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>


    //           <div className="col-md-6 col-lg-3 col-xl-3 text-center wow fadeInUp" style={{ cursor: 'pointer' }} data-wow-delay="0.1s" onClick={handleClick2}>
    //             <div className="service-item rounded">
    //               <div className="service-img rounded-top">

    //               </div>
    //               <div className="service-content rounded-bottom card bg-light p-4">

    //                 <div className="personalaccident">

    //                   <p className="mt-5 pt-5 text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
    //                   <h5 className="mt-3 text-center">{(lnstr === 'en') ? 'Personal Accident' : 'التأمين ضد الحوادث الشخصية'}</h5>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <Footer />

    // </div>



    <div className="App">
      <div className="header_section1">
        <Navbar />
        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{t('INSURANCE')}</h3>
          </div>
        </div>
      </div>

      <div className="features_section layout_padding">
        <div className="container">
          <h1 className="features_text">
            {(lnstr === 'en') ? <div>{t('Our')} <span className="htext">{t('Products')}</span></div> : 'منتجاتنا'}
          </h1>
          <p className="text-center undertxt">{t('Offered by us to cover all your needs')}.</p>

          <div className="features_section2">
          
            <div className="row d-flex justify-content-center align-items-center"   style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflowX: 'hidden',
      }}>
            <div
        // ref={cardContainerRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          gap: '20px',
          scrollbarWidth: 'none', 
        }}
      >
              <div className="text-center wow fadeInUp" style={{ cursor: 'pointer' , marginRight: '20px', marginLeft : '20px' }} data-wow-delay="0.1s" onClick={handleClick}>
                
                <div className="service-item rounded">
                  <div className="service-img rounded-top"></div>
                  <div className="service-content rounded-bottom card bg-light p-4">
                    <div className="service-content-inner">
                      <p className="text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
                      <h5 className="mt-3 text-center">{t('Travel Insurance')}</h5>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="text-center wow fadeInUp" style={{ cursor: 'pointer' , marginRight: '20px' }} data-wow-delay="0.1s" onClick={handleClick2}>
                <div className="service-item rounded">
                  <div className="service-img rounded-top"></div>
                  <div className="service-content rounded-bottom card bg-light p-4" style={{borderRadius : '0px 0px 10px 0px'}}>
                    <div className="personalaccident">
                      <p className="text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
                      <h5 className="mt-3 text-center">{(lnstr === 'en') ? 'Personal Accident':'حادث شخصي'}</h5>
                    </div>
                  </div>
                </div>
              </div>


              <div className="text-center wow fadeInUp" style={{ cursor: 'pointer', marginRight: '20px' }} data-wow-delay="0.1s" onClick={handleClick3}>
                <div className="service-item rounded">
                  <div className="service-img rounded-top"></div>
                  <div className="service-content rounded-bottom card bg-light p-4">
                    <div className="life">
                      <p className="text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
                      <h5 className="mt-3 text-center">{(lnstr === 'en') ? 'Life  Insurance':'التأمين على الحياة'}</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center wow fadeInUp" style={{ cursor: 'pointer', marginRight: '20px' }} data-wow-delay="0.1s" onClick={handleClick4}>
                <div className="service-item rounded">
                  <div className="service-img rounded-top"></div>
                  <div className="service-content rounded-bottom card bg-light p-4">
                    <div className="medical_insurance">
                      <p className="text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
                      <h5 className="mt-3 text-center">{(lnstr === 'en') ? 'Medical Insurance':'التأمين الطبي'}</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center wow fadeInUp" style={{ cursor: 'pointer', marginRight: '20px' }} data-wow-delay="0.1s" onClick={handleClick5}>
                <div className="service-item rounded">
                  <div className="service-img rounded-top"></div>
                  <div className="service-content rounded-bottom card bg-light p-4">
                    <div className="cyber">
                      <p className="text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
                      <h5 className="mt-3 text-center">{(lnstr === 'en') ? 'Cyber Insurance':'التأمين على الحياة'}</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center wow fadeInUp" style={{ cursor: 'pointer', marginRight: '20px' }} data-wow-delay="0.1s" onClick={handleClick6}>
                <div className="service-item rounded">
                  <div className="service-img rounded-top"></div>
                  <div className="service-content rounded-bottom card bg-light p-4">
                    <div className="motor">
                      <p className="text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
                      <h5 className="mt-3 text-center">{(lnstr === 'en') ? 'Motor Insurance':'التأمين على السيارات'}</h5>
                    </div>
                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </div>
        
      </div>

      </div>

      <Footer />
    </div>
  );
}

export default Partner;

// import React, { Fragment, useState, useEffect } from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { useTranslation } from 'react-i18next';
// import travelimg from './planeblack.png';
// import Cookies from 'js-cookie';

// function Partner() {

//   const { t } = useTranslation();

//   // const [insuretoken, setInsuretoken] = useState(null);
//   // useEffect(() => {
//   //     fetch('https://api.superjetom.com/create_inbound_token')
//   //       .then(response => response.json())
//   //       .then(data => {
//   //         // debugger;
//   //         setInsuretoken(data.result.data.token);
//   //       })
//   //       .catch(error => {
//   //         console.error('Error fetching countries:', error);
//   //       });
//   //   }, []);


//   const handleClick = () => {

//     // if(Cookies.get('userData'))
//     // {


//     window.location.href = 'travel-insurance';
//     // window.location.href = 'personal-accident-insurance';

//     // }
//     // else
//     // {
//     //     sessionStorage.setItem("searchData", "");
//     //     alert("Login required.");
//     //     window.location.href = '/Login';
//     // }

//   };

//   const handleClick2 = () => {
//     window.location.href = 'personal-accident-insurance';

//   };

//   const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


//   return (
//     <div className="App">


//       <div className="header_section1">

//         <Navbar />


//         <div className="container-fluid bg-breadcrumb">
//           <div className="container text-center py-5">
//             <h3 className="abt-txt my-5">{t('INSURANCE')}</h3>
//           </div>
//         </div>


//       </div>




//       <div class="features_section layout_padding">
//         <div class="container">
//           <h1 class="features_text">

//             {(lnstr === 'en') ? <div> {t('Our')} <span className="htext"> {t('Products')}</span> </div> : 'منتجاتنا '}

//           </h1>
//           <p className="text-center undertxt">{t('Offered by us to cover all your needs')}.</p>
//           <div class="features_section2">
//             <div class="row">

//               <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInUp">

//               </div>
//               <div className="col-md-6 col-lg-4 col-xl-4 text-center wow fadeInUp" style={{ cursor: 'pointer' }} data-wow-delay="0.1s" onClick={handleClick}>
//                 <div className="service-item rounded">
//                   <div className="service-img rounded-top">

//                   </div>
//                   <div className="service-content rounded-bottom card bg-light p-4">
//                     <div className="service-content-inner">
//                       {/* <img src={travelimg} alt="SUPERJET" /> */}
//                       <p className="mt-5 pt-5 text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
//                       <h5 className="mt-3 text-center">{t('Travel Insurance')}</h5>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               {/* <div className="col-md-6 col-lg-3 col-xl-3 text-center wow fadeInUp" style={{ cursor: 'pointer' }} data-wow-delay="0.1s" onClick={handleClick2}>
//                 <div className="service-item rounded">
//                   <div className="service-img rounded-top">

//                   </div>
//                   <div className="service-content rounded-bottom card bg-light p-4">

//                     <div className="personalaccident">

//                       <p className="mt-5 pt-5 text-center policytxt">{t('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
//                       <h5 className="mt-3 text-center">{(lnstr === 'en') ? 'Personal Accident' : 'التأمين ضد الحوادث الشخصية'}</h5>
//                     </div>
//                   </div>
//                 </div>
//               </div> */}
//               <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInUp">

//               </div>

//               {/* <div className="col-md-6 col-lg-4 col-xl-4 text-center wow fadeInUp" style={{cursor: 'pointer'}} data-wow-delay="0.1s" onClick={handleClick3}>
//                         <div className="service-item rounded">
//                            <div className="service-img rounded-top">
                                
//                            </div>
//                             <div className="service-content rounded-bottom card bg-light p-4">
//                                 <div className="motor">
//                                     <p className="mt-5 pt-5 text-center policytxt">{('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
//                                     <h5 className="mt-3 text-center">{(lnstr === 'en') ? 'Motor Insurance':'التأمين على السيارات'}</h5>
//                                 </div>
//                             </div>
//                         </div>
//                     </div> */}



//               {/* <div className="col-md-6 col-lg-4 col-xl-4 text-center wow fadeInUp"  style={{ cursor: 'pointer', marginTop: '20px', marginBottom: '20px' }} data-wow-delay="0.1s" onClick={handleClick4}>
//                         <div className="service-item rounded">
//                            <div className="service-img rounded-top">
                                
//                            </div>
//                             <div className="service-content rounded-bottom card bg-light p-4">
//                                 <div className="life">
//                                     <p className="mt-5 pt-5 text-center policytxt">{('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
//                                     <h5 className="mt-3 text-center">{(lnstr === 'en') ? 'Life Insurance':'التأمين على الحياة'}</h5>
//                                 </div>
//                             </div>
//                         </div>
//                     </div> */}





//               {/* <div className="col-md-6 col-lg-4 col-xl-4 text-center wow fadeInUp"  style={{ cursor: 'pointer', marginTop: '20px', marginBottom: '20px' }} data-wow-delay="0.1s" onClick={handleClick5}>
//                         <div className="service-item rounded">
//                            <div className="service-img rounded-top">
                                
//                            </div>
//                             <div className="service-content rounded-bottom card bg-light p-4">
//                                 <div className="cyber">
//                                     <p className="mt-5 pt-5 text-center policytxt">{('Buy Policy')} <span className="fa fa-arrow-right"></span></p>
//                                     <h5 className="mt-3 text-center">{(lnstr === 'en') ? 'Cyber Insurance':'التأمين على الحياة'}</h5>
//                                 </div>
//                             </div>
//                         </div>
//                     </div> */}






//             </div>
//           </div>
//         </div>
//       </div>

//       <Footer />

//     </div>
//   );
// }

// export default Partner;
// insurance page only travel insurance