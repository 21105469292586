import React, { Fragment , useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Select2Wrapper from './Select2Wrapperins';
import Cookies from 'js-cookie';
const { config } = require('../src/api/api');

function Outboundtravelinfo() {
  const base_url = config.baseURL;
    const [dhofarData, setDhofarData] = useState(false);  
    useEffect(() => {
    const storedDhofarString = Cookies.get('dhofarDataoutbound'); //sessionStorage.getItem('userData')
      if (storedDhofarString!==undefined) {
        const storedDhofar = JSON.parse(storedDhofarString);
        setDhofarData(storedDhofar);
      }
      else
      {
        // alert("token expired");
        // window.location.href='/';
      }
    }, []);

    const postData = { token: dhofarData };

    const [vendorKey, setVendorKey] = useState('');
  
    useEffect(() => {

      const postvData = { organization_name: "Superjet" };

      if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
        
        
        fetch(base_url+'/create_organization', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(postvData),
          })
          .then(response => response.json())
          .then(data => {
            setVendorKey(data.result[0].vendor_key);
          })
          .catch(error => {
              console.error('Error posting data:', error);
          });


      }
    }, [vendorKey]); // Dependency array

  //getcountries

      const [options, setCountries] = useState([]);

      if(options && options.length===0)
      {

      fetch(base_url+'/get_outbound_countries', {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
      })
      .then(response => response.json())
      .then(data => {

        setCountries(data.result.data.response_data);
        
      })
      .catch(error => {
          console.error('Error posting data:', error);
      });

      }



    const { t } = useTranslation();

    // const [selectedfromOption, setSelectedfromOption] = useState(null);
    // const [selectedfromOptionid, setSelectedfromOptionid] = useState(null);
    // const handlefromcountryChange = (event) => {
    //   setSelectedfromOptionid(event.target.options[event.target.selectedIndex].id);
    //   setSelectedfromOption(event.target.value);
    // };

    const [appendsValuesid, setAppendsValuesid] = useState([]);
    const [appendedValuesid, setAppendedValuesid] = useState(['130']);
    const [appendedValues, setAppendedValues] = useState(['Oman']); // State to store appended values
  

  // Function to handle select change
  const handlefromcountryChange = (value) => {

    // alert(value);

      const [description, id] = value.split("#");

    const isValueInArray = appendedValues.includes(description);

    if(!isValueInArray)
    {
    setAppendedValues([...appendedValues, description ]);
    setAppendedValuesid([...appendedValuesid, id]);
    }

  };

  


  // Function to handle drag start
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('index', index.toString());
  };

  // Function to handle drag over
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // Function to handle drop
  const handleDrop = (e, newIndex) => {

    if(newIndex!==0)
    {

    const draggedIndex = parseInt(e.dataTransfer.getData('index'));
    const updatedValues = [...appendedValues];
    const updatedValuesid = [...appendedValuesid];
    const draggedItem = updatedValues[draggedIndex];
    const draggedItemid = updatedValuesid[draggedIndex];

    // alert(newIndex);

    // Remove the dragged item from its original position
    updatedValues.splice(draggedIndex, 1);
    updatedValuesid.splice(draggedIndex, 1);

    // Insert the dragged item at the new index
    updatedValues.splice(newIndex, 0, draggedItem);
    updatedValuesid.splice(newIndex, 0, draggedItemid);

    setAppendedValues(updatedValues);
    setAppendedValuesid(updatedValuesid);

    }

  };

  const removeItem = (index) => {
    const updatedItems = [...appendedValues];
    const updatedItemsid = [...appendedValuesid];
    updatedItems.splice(index, 1);
    updatedItemsid.splice(index, 1);
    setAppendedValues(updatedItems);
    setAppendedValuesid(updatedItemsid);
  };







    const togglestartVisibility = () => {

      if(appendedValues && appendedValues.length===0)
      {
        alert('Please destination country');
        return false;
      }
      else
      {

        const appendedValuesd = [];

        Object.keys(appendedValues).forEach(key => {

            // debugger

            if(appendedValuesid[key]!=='130'){
              const vl = { 'countryID': appendedValuesid[key], 'countryName': appendedValues[key] }
              appendedValuesd.push(vl);
            }

            // const vl = { 'countryID': appendedValuesid[key], 'countryName': appendedValues[key] }
            
            // // setAppendedValuesd([...appendedValuesd, vl]);
            // appendedValuesd.push(vl);
            // setAppendedValuesd(appendedValuesd);
            
        });

        // debugger

        sessionStorage.setItem("outboundtravelinfo", JSON.stringify(appendedValuesd));
    
        // if(Cookies.get('userData'))
        // {
        window.location.href = `/outbound-traveller-info`;
        // }
        // else
        // {
        //   sessionStorage.setItem("searchData", "");
        //   alert("Login required.");
        //   window.location.href = '/Login?red=Outboundtravel';
        // }

      }

    };


  return (
    <Fragment>

      <div className="header_section1">

      <Navbar />


      <div className="container-fluid bg-breadcrumb">
            <div className="container text-center py-5">            
                <h3 className="abt-txt my-5">{t('Travel')} {t('Info')}</h3>
            </div>
        </div>


      </div>
       
 
        <div className="features_section my-1">
        <div className="container">
            
            <div className="row">

                <Col md={3}></Col>

                    <Col md={6} className="myforms my-5"> 


                            <div>

                            
                            <h4 className="px-0 py-2 mt-3">Select Destination Country</h4>

                            {/* <Select2Wrapper name="insurancefromcountry" options={options} className='form-control' onChange={handlefromcountryChange} required style={{ padding: 15 }} /> */}

                                <Select2Wrapper name="insurancefromcountry" value={appendsValuesid} options={options} className='form-control' onChange={handlefromcountryChange} required style={{ padding: 15 }} />

                            {/*<select name="insurancefromcountry" className="form-control" onChange={handlefromcountryChange} required>*/}
                            {/*  <option value="">-- {t('Select')} --</option>*/}
                            {/*  {options && options.map((option) => */}
                            {/*    <option key={option.id} id={option.id} value={option.description}>{t(option.description)}</option>*/}
                            {/*  )}*/}
                            {/*</select>*/}

                            <p className="my-1"><b className="my-3">In Case of Multiple Destinations required
                            Please select the countries one by one from the above dropdown</b></p>


                            {appendedValues && appendedValues.map((value, index) => (


<div key={index}>
          {index === 0 ? (

            <div>
                            <div
                            key={index}
                            
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, index)}
                            style={{
                            padding: '10px',
                            margin: '5px 0',
                            backgroundColor: '#f0f0f0',
                            borderRadius: '4px',
                            cursor: 'move',
                            }}
                        >
                            {value} 
                        </div>
                        
                        {(appendedValues.length!=(index+1)) ? 
                        <div class="outboundarrow"></div> : ''
                        }
                        
                        </div> ) : (
            <div>
            <div
                            key={index}
                            draggable
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, index)}
                            style={{
                            padding: '10px',
                            margin: '5px 0',
                            backgroundColor: '#f0f0f0',
                            borderRadius: '4px',
                            cursor: 'move',
                            }}
                        >
                            {value} <button style={{float: 'right', fontWeight: 'normal', fontSize: 10, width: 30}} className="btn btn-primary btn-sm my-0 text-white" onClick={() => removeItem(index)}>X</button>
                        </div>
                        
                        {(appendedValues.length!=(index+1)) ? 
                        <div class="outboundarrow"></div> : ''
                        }
                        
                        </div>  )}

            </div>

                        ))}

                            <br />

                            <button type="button" onClick={togglestartVisibility} className="btn btn-primary rounded-pill text-white my-3 py-1 px-4">{t('Submit')}</button>
                            
                            </div>

                    </Col>

<Col md={3}></Col>

</div>
            
            </div>
          </div>

    <Footer />

    

    </Fragment>
  );
}

export default Outboundtravelinfo;
