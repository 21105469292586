import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import { event } from "jquery";
const { config } = require('../api/api');


function NewpolicyDrivingLincese() {
  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });
  const [loading, setLoading] = useState(false);
  const [date, setdate] = useState('');
  const [timeLeft, setTimeLeft] = useState(60);
  const [isOtpExpired, setIsOtpExpired] = useState(false);
  const [vendorKey, setVendorkey] = useState('');







  useEffect(() => {

    const postvData = { organization_name: "Superjet" };
    debugger

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch( base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorkey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); 


  useEffect(() => {
    if (timeLeft <= 0) {
      setIsOtpExpired(true);
      return;
    }

    
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    
    return () => clearInterval(timer);
  }, [timeLeft]);


  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };


  const handleSubmit = async (event) => {

    debugger

    // var data = {
    //   "basicinfo_gender_id": selectedgenderOptionid,
    //   "basicinfo_gender_detail": selectedgenderOption,
    //   "basicinfo_marital_status_id": selectedmaritalOptionid,
    //   "basicinfo_marital_status_detail": selectedmaritalOption,
    //   "dob": selectedDateofBirth
    // }

    // debugger

    // sessionStorage.setItem("personalaccidentbasicinfo", JSON.stringify(data))

    event.preventDefault();
    setLoading(true);

    event.preventDefault();
    window.location.href = '/newpolicy-generalinfo';

  };



  const [dhofarData, setDhofarData] = useState(false);
  const [age, setAge] = useState(null);
  useEffect(() => {


    const storedDhofarString = Cookies.get('personalData'); //sessionStorage.getItem('userData')
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);


  const handledateChange = (event) => {
    setdate(event.target.value);
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };


  // const isButtonDisabled =  


  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'New Policy' : 'سياسة جديدة\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">



              {/* <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Select Insured Details' : 'حدد تفاصيل المؤمن عليه\n'}</h4> */}

              <form onSubmit={handleSubmit}>

                <p>
                    <input type='checkbox' onChange={handleCheckboxChange} name='note'/>
                    {'Is Drriver same as Insured?'}
                </p>

              <div className="input-group mb-2 mt-2">
  <div className="input-group-prepend prepends">
    <span className="input-group-text">
      {(lnstr === 'en') ? 'Insured License issue Date' : 'تاريخ إصدار الترخيص المؤمن عليه'}
    </span>
  </div>
  <input 
    type="date" 
    name="date" 
    className="form-control" 
    onChange={handledateChange}
    required 
    maxLength={6} 
    
  />
</div>

                <button type="submit"  className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{(lnstr === 'en') ? '  Next':'التالي'}</button>


              </form>


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );


}

export default NewpolicyDrivingLincese;
