import { SEARCH_FORM_SUBMITTED, SEARCH_SUBMIT_FORM_FAIL, SEARCH_SUBMIT_FORM_SUCCESS } from '../actions/actions';

const initialState = {
  submitted: false,
  message: null,
  searchretdata: null,
  countryflagdata: null,
  embassydata: null,
  error: null
};

const formReducer = (state = initialState, action) => {
//   debugger
  switch (action.type) {
    case SEARCH_FORM_SUBMITTED:
      return {
        ...state,
        message: null,
        searchretdata: null,
        countryflagdata: null,
        embassydata: null,
        error: null
      };
      case SEARCH_SUBMIT_FORM_FAIL:
        // debugger
        return {
          ...state,
          searchretdata: null,
          countryflagdata: null,
          embassydata: null,
          message: action.result.message,
          error: null
        };
        case SEARCH_SUBMIT_FORM_SUCCESS:
          // debugger
          return {
            ...state,
            searchretdata: action.result.result['programs'][0],
            countryflagdata: action.result.country_flags,
            embassydata: action.result.result['embassy'] === undefined ? []: action.result.result['embassy'],
            message: action.result.message,
            error: null
          };
    default:
      return state;
  }
};

export default formReducer;