import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

function Travelagent() {

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const { t } = useTranslation();

  return (
    <div>

        <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
            <div className="container text-center py-5">            
                <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Travel Agents' :'مكاتب السفر'}</h3>
            </div>
        </div>


        </div>
        

        <div className="features_section layout_padding my-1">
        <div className="container">


                <div className="row g-4 justify-content-center">
                    
                    <div className="col-12 p-0 mt-3 wow fadeInUp" data-wow-delay="0.2s">


                        {(lnstr==='en') ? '' :
                            <div className={lnstr}>
                                <b style={{ color: "#33d0f2" }}>مكاتب السفر</b>
                            </div>
                        }



                        {(lnstr==='en') ? <div className={lnstr}>SuperJet simplifies travel by facilitating border crossing administration and insurance needs. We understand the constraints, uncertainties, and challenges you face and aim to help you navigate them with our services.
                            </div>
                        :
                        <div className={lnstr}>تقوم  SuperJet بتبسيط السفر من خلال تسهيل إدارة عبور الحدود واحتياجات التأمين. نحن نفهم القيود والشكوك والتحديات التي تواجهها ونسعى لمساعدتك في التنقل من خلالها من خلال خدماتنا.</div>}

                        <br />


                        {(lnstr === 'en') ?
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>How SuperJet Can Help:</b>
                            </div>
                            :
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>كيف يمكن لـ SuperJet  مساعدتك:</b>
                            </div>
                        }


                        {(lnstr === 'en') ?
                            <div className={lnstr}>
                                <ul className="custom-list">
                                    <li> <b>Website Integration:</b> Utilize our API’s to offer your online customers a seamless experience in obtaining eVisas and insurance. </li>
                                    <li> <b>Increase Revenue:</b> Earn on each successful transaction</li>
                                    <li> <b>Special Promotions:</b> Travel agents can offer special discounts to their customers based on the partner scheme offering. </li>
                                </ul>
                            </div>
                        :
                        <div className={lnstr}>
                            <ul>
                                <li><b>دمج الموقع الإلكتروني: </b>استخدم API الخاصة بنا لتقديم تجربة سلسة لعملائك على الإنترنت في الحصول على التأشيرات الإلكترونية والتأمين.</li>
                                <li><b>زيادة الإيرادات: </b>اربح من كل عملية ناجحة.</li>
                                <li><b>عروض خاصة: </b>يمكن لوكلاء السفر تقديم خصومات خاصة لعملائهم بناءً على نظام الشراكة المعروض.</li>
                            </ul>
                        </div>}
                        <br />


                        {(lnstr === 'en') ?
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>How It Works:</b>
                            </div>
                            :
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>آلية العمل:</b>
                            </div>
                        }

                        {(lnstr === 'en') ?
                            <div className={lnstr}>
                            <ul className="custom-list">
                                    <li> <b>API Integration:</b> Travel agents ready with ecommerce website gets SuperJet API Pack to access Super Services directly from their website or App.</li>
                                    <li> <b>Superjet Website:</b> Travel agents without ecommerce platform can still access Super Services directly from Superjet Website. </li>
                                </ul>
                            </div>
                        :
                        <div className={lnstr}>
                            <ul>

                                <li><b>دمج  : API </b>يحصل وكلاء السفر الذين لديهم موقع إلكتروني للتجارة الإلكترونية على حزمة SuperJet API للوصول إلى الخدمات مباشرة من موقعهم الإلكتروني أو تطبيقهم.</li>

                                <li><b>موقع  :SuperJet</b>يمكن لوكلاء السفر الذين لا يمتلكون منصة تجارة إلكترونية الوصول إلى الخدمات مباشرة من موقع SuperJet
                                </li>
                            </ul>
                        </div>}

                        <br />


                        {(lnstr === 'en') ?
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>Services Offered</b>

                                <ul className="custom-list">
                                    <li> eVisa API</li>
                                    <li> Insurance API
                                    </li>
                                </ul>
                            </div>
                            :
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>الخدمات المقدمة:</b>

                                <ul className="custom-list">
                                    <li> API  للتأشيرات الإلكترونية</li>
                                    <li>API للتأمين الإلكتروني</li>
                                </ul>
                            </div>}


                        <br/>


                        {(lnstr === 'en') ?
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>Contact</b> : <a style={{ color: "blue", textDecoration: "none" }} href="mailto:admin@superjetom.com">admin@superjetom.com</a>
                            </div>
                            :
                            <div className={lnstr}>
                                <b style={{color: "#33d0f2"}}>دعونا نتواصل:</b> : <a style={{ color: "blue", textDecoration: "none" }} href="mailto:admin@superjetom.com">admin@superjetom.com</a>
                            </div>
                        }
                    </div>
                </div>
        </div>
        </div>


        <Footer/>

    </div>
  );
}

export default Travelagent;