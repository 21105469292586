import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import { event } from "jquery";
const { config } = require('../api/api');


function NewpolicyVehicleDetails() {
  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });
  const [loading, setLoading] = useState(false);
  const [selectedDateofBirth, setSelectedDateofBirth] = useState(null);
  const [make, setmakevalue] = useState(null);
  const [Chassissnumber, setChassissnumbervalue] = useState(null);
  const [model, setmodelvalue] = useState(null);
  const [bodytype, setbodytypevalue] = useState(null);
  const [modelyear, setModelyear] = useState(null);
  const [makeyear, setmakeyearvalue] = useState(null);
  const [mulkiyausing , setmulkiyatypevalue] = useState(null);
  const [passenger , setPassengervalue] = useState(null);
  const [product, setProduct] = useState(null);
  const [vehiclecc , setVehicleccvalue] = useState(null);
  const [vehiclevalue ,setvehiclevalue] = useState(null);
  const [vehicleprovince, setvehicleprovince] = useState(null);
  const [registrationtype , setRegistrationtypevalue] = useState(null);
  const [registrationmark , setRegistrationmarkvalue] =useState(null);
  const [vendorKey, setVendorkey] = useState('');
  


  // const [selectedqOption, setSelectedqOption] = useState(null);
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // month is 0-indexed
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = parseDate(dob);

    // Check if birthDate is valid
    if (isNaN(birthDate.getTime())) {
      throw new Error('Invalid date');
    }

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birth date hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };




  useEffect(() => {

    const postvData = { organization_name: "Superjet" };
    debugger

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch( base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorkey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array


  const handleSubmit = async (event) => {

    debugger

    // var data = {
    //   "basicinfo_gender_id": selectedgenderOptionid,
    //   "basicinfo_gender_detail": selectedgenderOption,
    //   "basicinfo_marital_status_id": selectedmaritalOptionid,
    //   "basicinfo_marital_status_detail": selectedmaritalOption,
    //   "dob": selectedDateofBirth
    // }

    // debugger

    // sessionStorage.setItem("personalaccidentbasicinfo", JSON.stringify(data))

    event.preventDefault();
    setLoading(true);

    event.preventDefault();
    window.location.href = '/newpolicy-driver';

  };

//   useEffect(() => {
//     debugger
//     const basicvalue = sessionStorage.getItem('personalaccidentbasicinfo');
//     if (basicvalue != undefined) {
//       const value = JSON.parse(basicvalue);
//       setSelectedGender(value.basicinfo_gender_detail);
//       setSelectedmarital(value.basicinfo_marital_status_detail);
//       setSelectedDateofBirth(value.dob);
//       setSelectedgenderOption(value.basicinfo_gender_detail);
//       setSelectedgenderOptionid(value.basicinfo_gender_id);
//       setSelectedmaritalOptionid(value.basicinfo_marital_status_id);
//       setSelectedmaritalOption(value.basicinfo_marital_status_detail);

//     }

//   }, []);

  const [dhofarData, setDhofarData] = useState(false);
  const [age, setAge] = useState(null);
  useEffect(() => {


    const storedDhofarString = Cookies.get('personalData'); //sessionStorage.getItem('userData')
    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);


  const [buttonDisabled, setButtonDisabled] = useState(false);
  const postData = { token: dhofarData };
  // const today = new Date().toISOString().split('T')[0];
  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];
  const minDate = new Date(today.getFullYear() - 60, today.getMonth(), today.getDate()).toISOString().split('T')[0];

  const [dobValue, setdobValue] = useState('');
  const handledobChange = (event) => {
    const selectedDob = event.target.value;
    setdobValue(event.target.value);

    const dobDate = new Date(selectedDob);
    const today = new Date();
    let calculatedAge = today.getFullYear() - dobDate.getFullYear();
    const monthDiff = today.getMonth() - dobDate.getMonth();

    // Adjust age if the current date is before the user's birthday in the current year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
      calculatedAge--;
    }

    setAge(calculatedAge);
  };

  
  const handlemakeChange = (event) => {
    setmakevalue(event.target.value)
  };

  const handleChassissnumberChange =(event) => {
    setChassissnumbervalue(event.target.value)
  };

  const handlemodelChange = (event) => {
    setmodelvalue(event.target.value)
  };

  const handleBodytypeChange = (event) => {
    setbodytypevalue(event.target.value)
  };

  const handleModelyearChange = (event) => {
    setModelyear(event.target.value)
  };

  const handlemakeyearChange = (event) => {
    setmakeyearvalue(event.target.value)
  };

  const handlemulkiyausingChange = (event) => {
    setmulkiyatypevalue(event.target.value)
  };

  const handleproductChange = (event) => {
    setProduct(event.target.value)
  };

  const handlePassengerChange = (event) => {
    setPassengervalue(event.target.value)
  };

  const handlevehicleccChange = (event) => {
    setVehicleccvalue(event.target.value)
  };

  const handlevehiclevalueChange = (event) => {
    setvehiclevalue(event.target.value)
  };

  const handleVehicleprovinceChange = (event) => {
    setvehicleprovince(event.target.value)
  };

  const handleregistratintypeChange =(event) => {
    setRegistrationtypevalue(event.target.value)
  };

  const handleRegistrationmarkChange = (event) => {
    setRegistrationmarkvalue(event.target.value)
  };



 
  //getgender

//   const [goptions, setGender] = useState([]);
//   const [moptions, setMarital] = useState([]);

//   useEffect(() => {
//     if (goptions && goptions.length === 0 && vendorKey !== '') {

      

//       debugger
//       fetch(base_url + '/getGender', {
//         method: 'POST',
//         headers: {
//           'Authorization': `Bearer ${vendorKey}`,
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(postData),
//       })
//         .then(response => response.json())
//         .then(data => {
//           setGender(data.result.data.response_data);
//           married()

//         })
//         .catch(error => {
//           console.error('Error posting data:', error);
//         });

//     }    
//   },[vendorKey]
//   );

//   const married = () => {
//     if (moptions && moptions.length === 0 && vendorKey !== '') {
//       setLoading(true);

//       fetch(base_url + '/getMaritalStatus', {
//         method: 'POST',
//         headers: {
//           'Authorization': `Bearer ${vendorKey}`,
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(postData),
//       })
//         .then(response => response.json())
//         .then(data => {

//           setMarital(data.result.data.response_data);
//           setLoading(false);


//         })
//         .catch(error => {
//           console.error('Error posting data:', error);
//         });

//     }
//   };






  // const isButtonDisabled =  






  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'New Policy' : 'سياسة جديدة\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">



              <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Vehicle Details' : 'تفاصيل المركبة\n'}</h4>

              <form onSubmit={handleSubmit}>

              <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{(lnstr === 'en') ? 'Product':'منتج'}</span>
                  </div>
                  <select  name="product" className="form-control" onChange={handleproductChange} required>
                    <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                    <option value=' Comprehensive '> Comprehensive </option>
                    <option value = ' Third Party '> Third Party </option>
                  </select>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{(lnstr === 'en') ? 'Enter chassis Number':'أدخل رقم الهيكل'}</span>
                  </div>
                  <input type="text" name="chassisnumber" className="form-control" onChange={handleChassissnumberChange} required/>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  <span className="input-group-text">{(lnstr === 'en') ? 'Make':'يصنع'}</span>
                  </div>
                  <select  name="make" className="form-control" onChange={handlemakeChange} required>
                    <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                  </select>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  <span className="input-group-text">{(lnstr === 'en') ? 'Model':'نموذج'}</span>
                  </div>
                  <select  name="model" className="form-control" onChange={handlemodelChange} required>
                    <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                  </select>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  <span className="input-group-text">{(lnstr === 'en') ? 'Model':'نموذج'}</span>
                  </div>
                  <select  name="model" className="form-control" onChange={handlemodelChange} required>
                    <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                  </select>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  <span className="input-group-text">{(lnstr === 'en') ? 'Body type':'نوع الجسم'}</span>
                  </div>
                  <select  name="bodytype" className="form-control" onChange={handleBodytypeChange} required>
                        <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                        </select>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  <span className="input-group-text">{(lnstr === 'en') ? 'Model Year':'سنة الموديل'}</span>
                  </div>
                  <select  name="modelyear" className="form-control" onChange={handleModelyearChange} required>
                        <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                        </select>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  <span className="input-group-text">{(lnstr === 'en') ? 'Make Year':'جعل السنة'}</span>
                  </div>
                  <select  name="makeyear" className="form-control" onChange={handlemakeyearChange} required>
                        <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                        </select>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  <span className="input-group-text">{(lnstr === 'en') ? 'mulkiya usage type':'جعل السنة'}</span>
                  </div>
                  <select  name="modelyear" className="form-control" onChange={handlemulkiyausingChange} required>
                        <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                        </select>
                </div>


                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{(lnstr === 'en') ? 'No Of Passengers':'عدد الركاب'}</span>
                  </div>
                  <input type="text" name="nopassengers" className="form-control" onChange={handlePassengerChange} required/>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{(lnstr === 'en') ? 'Vehicle CC':'مركبة سي سي'}</span>
                  </div>
                  <input type="text" name="vehiclecc" className="form-control" onChange={handlevehicleccChange} required/>
                </div>


                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    {/* <h6>Mulkiya Status</h6>  */}

                    <label className="form-check-label" htmlFor="new1" style={{marginRight:'17px'}}><b>Mulkiya Status</b></label>

                    <input type="radio" id="new1" name="new" value="new" />
                    <label className="form-check-label" htmlFor="new1" style={{marginLeft:'5px'}}>New</label>
                    <input type="radio" id="renew" name="renew" value="new" style={{marginLeft:'10px'}}/>
                    <label className="form-check-label" htmlFor="renew" style={{marginLeft:'5px'}}>Renewal</label>
                  </div>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                    <span className="input-group-text">{(lnstr === 'en') ? 'Vehicle Value (RO)':'قيمة المركبة (ر.ع)'}</span>
                  </div>
                  <input type="text" name="vehiclevalue" className="form-control" onChange={handlevehiclevalueChange} required/>
                </div>


                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  <span className="input-group-text">{(lnstr === 'en') ? 'Vehicle Province':'مقاطعة المركبات'}</span>
                  </div>
                  <select  name="vehicleprovince" className="form-control" onChange={handleVehicleprovinceChange} required>
                        <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                        </select>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  <span className="input-group-text">{(lnstr === 'en') ? 'Plate Registration Type':'نوع تسجيل اللوحة'}</span>
                  </div>
                  <select  name="registrationtype0" className="form-control" onChange={handleregistratintypeChange} required>
                        <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                        </select>
                </div>


                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend prepends">
                  <span className="input-group-text">{(lnstr === 'en') ? 'Registration Mark':'علامة التسجيل'}</span>
                  </div>
                  <select  name="registrationmark" className="form-control" onChange={handleRegistrationmarkChange} required>
                        <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                        </select>
                </div>

                






                {/* <div className="row mt-1 "> */}

                    {/* <div className="col-md-6 mb-2 ">
                      <div className="input-group w-100">
                        <div className="input-group-prepend prepends" style={{ width: '43%' }}>
                          <span className="input-group-text">{(lnstr === 'en') ? 'Make':'يصنع'}</span>
                        </div>
                        <select  name="make" className="form-control" onChange={handlemakeChange} required>
                    <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                  </select>
                      </div>
                    </div> */}


                    {/* <div className="col-md-6 mb-2 ">
                      <div className="input-group w-100">
                        <div className="input-group-prepend prepends" style={{ width: '43%' }}>
                          <span className="input-group-text">{(lnstr === 'en') ? 'Model':'نموذج'}</span>
                        </div>
                        <select  name="model" className="form-control" onChange={handlemodelChange} required>
                    <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                  </select>
                      </div>
                    </div> */}


                    {/* <div className="col-md-6 mb-2 ">
                      <div className="input-group w-100">
                        <div className="input-group-prepend prepends" style={{ width: '43%' }}>
                          <span className="input-group-text">{(lnstr === 'en') ? 'Body type':'نوع الجسم'}</span>
                        </div>
                        <select  name="bodytype" className="form-control" onChange={handleBodytypeChange} required>
                        <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                        </select>
                      </div>
                    </div> */}


                    {/* <div className="col-md-6 mb-2 ">
                      <div className="input-group w-100">
                        <div className="input-group-prepend prepends" style={{ width: '43%' }}>
                          <span className="input-group-text">{(lnstr === 'en') ? 'Model Year':'سنة الموديل'}</span>
                        </div>
                        <select  name="modelyear" className="form-control" onChange={handleModelyearChange} required>
                        <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                        </select>
                      </div>
                    </div> */}



                    {/* <div className="col-md-6 mb-2 ">
                      <div className="input-group w-100">
                        <div className="input-group-prepend prepends" style={{ width: '43%' }}>
                          <span className="input-group-text">{(lnstr === 'en') ? 'Make Year':'جعل السنة'}</span>
                        </div>
                        <select  name="makeyear" className="form-control" onChange={handlemakeyearChange} required>
                        <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                        </select>
                      </div>
                    </div> */}


                    {/* <div className="col-md-6 mb-2 ">
                      <div className="input-group w-100">
                        <div className="input-group-prepend prepends" style={{ width: '43%' }}>
                          <span className="input-group-text">{(lnstr === 'en') ? 'mulkiya usage type':'جعل السنة'}</span>
                        </div>
                        <select  name="makeyear" className="form-control" onChange={handlemakeyearChange} required>
                        <option  >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                        </select>
                      </div>
                    </div> */}

                  {/* </div> */}



            


                <button type="submit"  className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{(lnstr === 'en') ? 'Next':'التالي'}</button>


              </form>


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );


}

export default NewpolicyVehicleDetails;
