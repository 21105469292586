import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Navbar from "../Navbar";
import Footer from "../Footer";
import Select2Wrapper from '../Select2Wrapper';
import Cookies from 'js-cookie';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const { config } = require('../api/api');

Modal.setAppElement('#root');
const ResetPassword = () => {
  const base_url = config.baseURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
  const [pdata, setPdata] = useState([]);
  const [message, setMessage] = useState('');

  const [vendorKey, setVendorKey] = useState('');
  const [formData, setFormData] = useState({
    id: '',
    email: '',
    password: '',
    confirmpassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, confirmsetPasswordVisible] = useState(false);
  const [searchData, setSearchData] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    const urlSearchString = window.location.search;
    const uparams = new URLSearchParams(urlSearchString);
    const email = uparams.get('email');
    const id = uparams.get('id');
    const type = uparams.get('type');

    var decodeed_mail = decodeURIComponent(escape(window.atob(email)));
    var decodeed_id = decodeURIComponent(escape(window.atob(id)));
    var decodeed_type = decodeURIComponent(escape(window.atob(type)));
    // decodeURIComponent(escape(window.atob(base64)));
    //  const decodedAgencyId = Buffer.from(id, 'base64').toString('utf-8');
    //         const decodedEmail = Buffer.from(email, 'base64').toString('utf-8');
    setFormData(prevState => ({
      ...prevState,
      email: decodeed_mail || '',
      id: decodeed_id || '',
      type: decodeed_type||''
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const ConfirmtogglePasswordVisibility = () => {
    confirmsetPasswordVisible(!confirmpasswordVisible);
  };




  useEffect(() => {

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch(base_url+'/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setLoading(true);

    const data = {
      email: formData.email,
      password: formData.password,
      confirm_password: formData.confirmpassword,
      id: formData.id
    };

    if (data.password === data.confirm_password) {
     
      debugger
      var update_data = {
        "vendor_key":vendorKey,
        "id": formData.id,
        "email":formData.email,
        "password": formData.confirmpassword,
        "type":formData.type
      }
      fetch(base_url+'/changepassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(update_data),
      })
        .then(response => response.json())
        .then(data => {
          debugger
          if (data.message == 'success') {
            alert("Password Set Successfully");
            setTimeout(() => {
              window.location.href = '/login';
            }, 1000);
          }

          // setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });



      Cookies.remove('userData');
    } else {
      alert("Password does not match");
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <Navbar />
      <div className="features_section layout_padding my-5">
        <div className="container">
          <div className="row">
            <Col md={3}></Col>
            <Col md={6} className="myforms">
              <h1 className={lnstr}>{(lnstr === 'en') ? 'Reset Password' : 'إعادة تعيين كلمة المرور'}</h1>
              <form onSubmit={handleSubmit}>
                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{t('Email')}</span>
                  </div>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    readOnly
                  />
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{t('Password')}</span>
                  </div>
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    name="password"
                    className="form-control"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <button type="button" className="btn btn-outline-grey" onClick={togglePasswordVisibility}>
                    {passwordVisible ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                  </button>
                </div>

                <div className="input-group mb-2 mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{t('Confirm password')}</span>
                  </div>
                  <input
                    type={confirmpasswordVisible ? 'text' : 'password'}
                    name="confirmpassword"
                    className="form-control"
                    value={formData.confirmpassword}
                    onChange={handleChange}
                    required
                  />
                  <button type="button" className="btn btn-outline-grey" onClick={ConfirmtogglePasswordVisibility}>
                    {confirmpasswordVisible ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                  </button>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary rounded-pill text-white my-2 py-1 px-4"
                >
                  {t('Submit')}
                </button>
                {message && message !== 'success' && (
                  <span style={{ color: 'red' }}>{message}</span>
                )}
              </form>
            </Col>
            <Col md={3}></Col>
          </div>
        </div>
      </div>
      <Footer />
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
    </Fragment>
  );

};

export default ResetPassword;