import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SessionRemove from "../helper/sessionremove";
const { config } = require('../api/api');



function MedicalLimits() {
  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });

  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSection, setShowSection] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    debugger
    setFormData({ ...formData, 'insurancetype': event.target.value });

    if (event.target.value === ' ') {
      setShowSection(true);
      setButtonDisabled(true);
    }
    else {
      setShowSection(false);
      setButtonDisabled(false);
    }

  };



  // const [selectedqOption, setSelectedqOption] = useState(null);

  //   const handleqOptionChange = (event) => {

  //   if (event.target.value === 'No') {
  //     alert("The outbound plans are available only for residents of Oman.");
  //     setButtonDisabled(true);
  //     return;
  //   }
  //   else {
  //     setButtonDisabled(false);
  //   }

  // };


  const [vendorKey, setVendorKey] = useState('');

  // useEffect(() => {
  //   debugger

  //   const postvData = { organization_name: "Superjet" };
  //   Cookies.set('Vendorkey', JSON.stringify(vendorKey))

  //   if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


  //     fetch('https://api.superjetom.com/create_organization', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(postvData),
  //     })
  //       .then(response => response.json())
  //       .then(data => {
  //         debugger
  //         setVendorKey(data.result[0].vendor_key);

  //         Cookies.set('Vendorkey', JSON.stringify(vendorKey))
  //       })
  //       .catch(error => {
  //         console.error('Error posting data:', error);
  //       });


  //   }
  // }, [vendorKey]); // Dependency array



  // const [personaltocken , setPersonaltocken] = useState('');
  // useEffect(() => {
  //   const postvData = {organization_name: "Superjet"};
  //   debugger
  //   if (personaltocken === '' || personaltocken === null || personaltocken === undefined){

  //     fetch('https://api.superjetom.com/create_personal_accident_token' ,{
  //       method:'POST',
  //       headers : {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(postvData),
  //     })
  //     .then(response => response.json())
  //     .then(data => {
  //       debugger
  //       setPersonaltocken(data.result)
  //     })
  //     .catch(error => {
  //       console.error('error posting data:',error)
  //     });
  //   }
  // },[personaltocken]);









  const handleSubmit = async (event) => { 
     window.location.href = '/medical-plan'
    
  };

  const handleCancel = async (event) => { 
    window.location.href = '/medical-assured'
   
 };


  
  const [medicalExpense, setMedicalExpense] = useState('');
  const [outOfPocket, setOutOfPocket] = useState(null);

  const annualLimit = 5000; // OMR
  const deductible = 3; // OMR
  const coPayPercentage = 0.20; // 20%

  const calculateOutOfPocket = () => {
    const expense = parseFloat(medicalExpense);
    if (isNaN(expense) || expense <= 0) {
      alert('Please enter a valid medical expense.');
      return;
    }

    let totalOutOfPocket = 0;

    // Deductible
    totalOutOfPocket += deductible;

    // Calculate remaining after deductible
    const remainingExpense = expense - deductible > 0 ? expense - deductible : 0;

    // Co-pay
    totalOutOfPocket += remainingExpense * coPayPercentage;

    // Check against annual limit
    if (totalOutOfPocket > annualLimit) {
      totalOutOfPocket = annualLimit;
    }

    setOutOfPocket(totalOutOfPocket.toFixed(2));
  };




  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Medical Insurance' : 'التأمين الطبي\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">



              <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Choose your limits' : 'اختر حدودك\n'}</h4>

              <form>

              <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
              <div className="d-flex justify-content-between my-3">
                <div>
                <p>{(lnstr === 'en') ? 'Plan Type' :'نوع الخطة'}</p>
                <h6><strong>See Details</strong></h6>
                </div>
                <div>
              <p>{(lnstr === 'en') ? 'Sum Assured':'المبلغ المؤمن'}</p>
              {/* <h6><strong>{sumaasured !=null ? sumaasured ?.assured_value :''}</strong></h6> */}
              </div>
              
              </div>
              
            </div>
            <div>

            <div>
                <h4>{(lnstr === 'en') ? 'Annual Limit':'المبلغ المؤمن'}</h4>
                <label>
                <input type="radio" name="limit" value="3,000" checked />
                3,000 OMR
                </label>
                <br />
                <label>
                <input type="radio" name="limit" value="5,000" />
                5,000 OMR
                </label>
                <br />
                <label>
                <input type="radio" name="limit" value="10,000" />
                10,000 OMR
                </label>
            </div>

            <div>
                <h4>{(lnstr === 'en') ? 'Op deductible':'المرجع للخصم'}</h4>
                <label>
                <input type="radio" name="op" value="3" checked />
                3 OMR
                </label>
                <br />
                <label>
                <input type="radio" name="op" value="2" />
                2 OMR
                </label>
                <br />
                <label>
                <input type="radio" name="op" value="1" />
                1 OMR
                </label>
            </div>

            <div>
                <h4>{(lnstr === 'en') ? 'PM Co Pay':'PM Co الدفع'}</h4>
                <label>
                <input type="radio" name="pm" value="20" checked />
                20 %
                </label>
                <br />
                <label>
                <input type="radio" name="pm" value="10" />
                10 %
                </label>
                <br />
                <label>
                <input type="radio" name="pm" value="nil" />
                Nil
                </label>
            </div>
            </div>


                
            {/* <div className="d-flex justify-content-between my-3">
                <button type="cancel" disabled={buttonDisabled} className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('Cancel')}</button>
                <button type="submit" disabled={buttonDisabled} onClick={ window.location.href = '/medical-assured' } className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('Next')}</button>
                </div> */}

              </form>

              <div className="d-flex justify-content-between my-3">
    <button 
        type="button" 
        disabled={buttonDisabled} 
        onClick={handleCancel} 
        className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">
        {t('Cancel')}
    </button>
    <button 
        type="submit" 
        disabled={buttonDisabled} 
        onClick={handleSubmit}
        className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">
        {t('Next')}
    </button>
</div>


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default MedicalLimits;