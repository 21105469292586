import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import { useTranslation } from 'react-i18next';
// import travelimg from '../tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../../api/api');

function LifeDownloadTermPlanPolicy() {

  const base_url = config.baseURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [vendorKey, setVendorkey] = useState('');
  const [dhofarData, setDhofarData] = useState(false);
  const [downloadpolicy, setDownloadValue] = useState('');
  const [policyLink, setPolicyLink] = useState(null);
  const [policy_no, setPolicyNo] = useState(null);
  const [age, setAge] = useState(null);
  // useEffect(() => {

  //   var quotation_data =
  //   {
  //     "token": JSON.parse(dhofarData),
  //     "policy_no": policy_no,
  //   };



  //   if (vendorKey !== '') {


  //     fetch(base_url + '/get_personal_accident_policy_details', {
  //       method: 'POST',
  //       headers: {
  //         'Authorization': `Bearer ${vendorKey}`,
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(quotation_data),
  //     })

  //       .then(response => response.json())
  //       .then(data => {

  //         debugger
  //         setDownloadValue(data.result[0]);

  //       })
  //       .catch(error => {
  //         console.error('Error posting data:', error);
  //       });

  //   }

  // }, []);

const pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)(:[0-9]{1,5})?(\/.*)?$/;

useEffect(() => {
  const urlSearchString = window.location.search;
  const uparams = new URLSearchParams(urlSearchString);
  const policy_no = uparams.get('policyno');
  setPolicyNo(policy_no);
}, []);


useEffect(() => {
  const createOrganization = async () => {
    try {
      const postvData = { organization_name: "Superjet" };

      const response = await fetch(base_url + '/create_organization', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postvData),
      });
      const data = await response.json();
      setVendorkey(data.result[0].vendor_key);
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  if (!vendorKey) {
    createOrganization();
  }
}, [vendorKey, base_url]);

useEffect(() => {
  const createToken = async () => {
    if (vendorKey) {
      try {
        const response = await fetch(base_url + '/create_personal_accident_token', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
          },
        });
        const data = await response.json();
        const dhdata = data.result.data.token;
        setDhofarData(dhdata);
      } catch (error) {
        console.error('Error getting token:', error);
      }
    }
  };

  createToken();
}, [vendorKey, base_url]);

useEffect(() => {
  const fetchPolicyDetails = async () => {
    if (vendorKey && dhofarData && policy_no) {
      try {
        const quotation_data = {
          token: dhofarData,
          policy_no: policy_no,
        };

        const response = await fetch(base_url + '/get_personal_accident_policy_details', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(quotation_data),
        });

        const data = await response.json();
        setDownloadValue(data.result[0]);

        const postData1 = { token: dhofarData, policy_no: policy_no };
        const response2 = await fetch(base_url + '/download_personalaccident_policy', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData1),
        });
        const data2 = await response2.json();

        if (data2.message === 'success') {
          debugger
          setPolicyLink(data2.result.data.policy_schedule_url);
        } else {
          setPolicyLink('');
        }
      } catch (error) {
        console.error('Error fetching policy details:', error);
      }
    }
  };

  fetchPolicyDetails();
}, [vendorKey, dhofarData, policy_no, base_url]); 

const handleSubmit = async (event) => {
  event.preventDefault();

  window.location.href = '/insurance';    

};
const isValidUrl = (url) => {
  return pattern.test(url);
};



return (



  <Fragment>

      <div className="header_section1">

          <Navbar />


          <div className="container-fluid bg-breadcrumb">
              <div className="container text-center py-5">
                  <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Download Policy - ' + policy_no : 'تحميل السياسة\n'}</h3>
              </div>
          </div>


      </div>


      <div className="features_section">
          <div className="container">

              <div className="row">

                  <Col md={3}></Col>

                  <Col md={6} className="myforms my-5" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                      <br />

                      <table className="table table-bordered table-condenced" style={{ textAlign: 'left' }}>

                          <tbody>

                              <tr><td style={{ width: '50%' }}><b>Name</b>
                                  </td><td>{downloadpolicy != null ? downloadpolicy ?.first_name : ''}</td></tr>

                              <tr><td><b>Email</b>
                                  </td><td>{downloadpolicy != null ? downloadpolicy ?.email : ''}</td></tr>
                              


                              <tr><td><b>Passport</b>
                                  </td><td>{downloadpolicy != null ? downloadpolicy?.passport : ''}</td></tr>
                              

                              <tr><td><b>Cover Type</b>
            </td><td>{downloadpolicy != null ? downloadpolicy.policy_data?.cover_type : ''}</td></tr>

                              <tr><td><b>Civil ID</b>
                                  </td><td>{downloadpolicy != null ? downloadpolicy.policy_data?.civil_id : ''}</td></tr>

                             

                          </tbody>

                      </table>

                      <div className="text-center">
          {
             policyLink != null && policyLink != '' ?
            // true ?
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <button
                  onClick={() => {
                    debugger
                    if (policyLink) {
                      var url = policyLink;
                      const link = document.createElement('a');
                      link.href = url;
                      link.target = '_blank';
                      link.download = url.split('/').pop(); // Extracts filename from URL
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    } else {
                      alert('Policy error')
                    }

                  }}
                  style={{ fontWeight: 'bold' }}
                >
                  Download Policy Document
                </button>
                <div style={{ margin: '0px 100px'}} >
                  <button style={{fontWeight: 'bold' }}
                  onClick={handleSubmit}
                  >Go To Home</button>
                </div></div>
              : policyLink === '' ? <div><h2>Policy Payment Pending</h2>
              </div> : "Loading"
          }
        </div>

                  </Col>

                  <Col md={3}></Col>

              </div>


          </div>
      </div>

      <Footer />

      {loading && (
          <div className="overlay">
              <div className="spinner"></div>
          </div>
      )}

  </Fragment>




        // <Fragment>
        //   <div className="features_section layout_padding my-5">

        //     <div>

        //       <div className="row">

        //         <Col md={3}></Col>


        //         <Col md={6} className="myforms my-3 ">
        //   <div style={{ display: 'flex', paddingBottom: '20px' }}>
        //     <button style={{ width: '100px', fontWeight: 'bold' }} onClick={() => {
        //       const regex = /^(ftp|http|https):\/\/[^ "]+$/;
        //       var isValidUrl = regex.test(sandaUrlData?.back_url);

        //       if(isValidUrl){
        //         window.open(sandaUrlData?.back_url + '?policyno=' + policyDetails?.policy_no , '_blank')
        //       }else{
        //         window.location.reload();
        //       }

        //     }} >Back</button>
        //   </div>

        //   <div style={{ textAlign: 'left', fontSize: '20px' }}>
        //     <b>Policy Details  - {policyDetails != null ? policyDetails?.policy_no : ''}</b>
        //   </div>

        //   <br />

        //   <table className="table table-bordered table-condenced" style={{ textAlign: 'left' }}>

        //     <tbody>

        //     <tr><td style={{width:'50%'}}><b>Name</b>
        //       </td><td>{policyDetails != null ? policyDetails?.first_name : ''}</td></tr>

        //       <tr><td><b>Occupation</b>
        //       </td><td>{policyCoverDetails != null ? policyCoverDetails?.personalInformation?.occupation?.toString() : ''}</td></tr>


        //       <tr><td><b>Passport</b>
        //       </td><td>{policyDetails != null ? policyDetails?.sanad_passport_no : ''}</td></tr>

        //       {/* <tr><td><b>Sanad Reference No</b>
        //       </td><td>{policyDetails != null ? policyDetails?.sanad_reference_no : ''}</td></tr> */}

        //       <tr><td><b>Civil ID</b>
        //       </td><td>{policyCoverDetails != null ? policyCoverDetails?.civil_id : ''}</td></tr>

        //       <tr><td><b>Total Fee (OMR)</b>
        //       </td><td>{policyCoverDetails != null ? policyCoverDetails?.amount?.total?.toString() : ''}</td></tr>

        //       <tr><td><b>Created</b>
        //       </td><td>{policyDetails != null ? policyDetails?.created_at.toString().split('T')[0] : ''}</td></tr>

        //     </tbody>

        //   </table>

        //   {/* <a href='outbound-travel-details'><button type="button" class="btn btn-primary-outline">Edit</button></a> &nbsp; <a href={(paymentUrl) ? paymentUrl : ''}><button type="submit" className="btn btn-primary rounded-pill text-white my-3 py-2 px-5">{(lnstr === 'en') ? 'Proceed to payment' : 'الدفع'}</button></a> */}


        //   <div className="text-center">
        //     {
        //        policyLink != null && policyLink != '' ?
        //       // true ?
        //         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        //           <button
        //             onClick={() => {
        //               debugger
        //               if (policyLink) {
        //                 var url = policyLink;
        //                 const link = document.createElement('a');
        //                 link.href = url;
        //                 link.target = '_blank';
        //                 link.download = url.split('/').pop(); // Extracts filename from URL
        //                 document.body.appendChild(link);
        //                 link.click();
        //                 document.body.removeChild(link);
        //               } else {
        //                 alert('Policy error')
        //               }

        //             }}
        //             style={{ fontWeight: 'bold' }}
        //           >
        //             Download Policy Document
        //           </button>
        //           <div style={{ margin: '10px 0' }} >
        //             <button style={{ marginLeft: 'auto', fontWeight: 'bold' }}
        //             onClick={() => {
        //               debugger
        //               const regex = /^(ftp|http|https):\/\/[^ "]+$/;
        //               var isValidUrl = regex.test(sandaUrlData?.return_url);

        //               if(isValidUrl){
        //                 window.open(sandaUrlData?.return_url, '_blank')
        //               }else{
        //                 window.location.reload();
        //               }

        //             }}
        //             >Go To Home</button>
        //           </div></div>
        //         : policyLink === '' ? <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><h2>Policy Payment Pending</h2>
        //           <div>
        //             <button
        //               onClick={() => {

        //                 if (policyDetails?.payment_link) {
        //                   window.open(policyDetails?.payment_link, '_blank')

        //                   // Reload the current page (form tab)
        //                   window.location.reload();
        //                 }

        //               }} style={{ marginLeft: 'auto', width: '200px', fontWeight: 'bold' }}>Make Payment</button></div></div> : "Loading"
        //     }
        //   </div>


        // </Col>

        //         <Col md={3}></Col>

        //       </div>



        //     </div>


        //   </div>
        // </Fragment>
    );
}

export default LifeDownloadTermPlanPolicy;