import Navbar from "./Navbar";
import Footer from "./Footer";
import {useTranslation} from 'react-i18next';
import Cookies from 'js-cookie';

function Terms() {

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const {t} = useTranslation();

    return (
        <div>

            <div className="header_section1">

                <Navbar/>


                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{t('TERMS OF USE')}</h3>
                    </div>
                </div>


            </div>


            <div className="features_section layout_padding my-1">
                <div className="container">

                    <div className="row g-4 justify-content-center">

                        <div className="col-12 wow fadeInUp" data-wow-delay="0.2s">

                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Processing Start Date:</b>
                                    <p>The processing time is calculated starting from the
                                    date the documents are submitted to the Embassy or Consulate.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>تاريخ بدء المعاملة</b>
                                    <p>يتم حساب وقت المعاملة بدءًا من تاريخ تقديم المستندات
                                    إلى السفارة أو القنصلية</p>
                                </div>}

                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Potential Delays and Denials:</b>
                                    <p>The Embassy or Consulate may request
                                    additional documents, which can delay the processingThey have the discretion to
                                    delay and deny processing without providing further explanations.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>التأخيرات المحتملة والرفض</b>
                                    <p>قد تطلب السفارة أو القنصلية مستندات إضافية، مما
                                    يمكن أن يؤخر عملية المعالجة. لديها الصلاحية لتأخير ورفض المعالجة دون تقديم تفسيرات
                                    إضافية</p>
                                </div>}


                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Liability Disclaimer:</b>
                                    <p>Superjet is not responsible for any delays,
                                    cancellations, financial losses, or other issues arising from the denial or delay in
                                    processingNo refunds will be issued for service fees, embassy fees, courier fees,
                                    shipping charges, or any portion thereof due to delays or denial of service.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>إخلاء المسؤولية</b>
                                    <p>لا تتحمل سوبرجيت مسؤولية أي تأخيرات أو إلغاءات أو خسائر
                                    مالية أو مشاكل أخرى تنشأ عن رفض أو تأخير في المعالجة. لن يتم إصدار أي استرداد لرسوم
                                    الخدمة أو رسوم السفارة أو رسوم البريد السريع أو رسوم الشحن أو أي جزء منها بسبب
                                    التأخيرات أو رفض الخدمة</p>
                                </div>}


                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Fee Structure:</b>
                                    <p>Service Fees: Superjet charges a service fee for processing
                                    e-visa applications, which is separate from the embassy or consulate fees.Embassy
                                    Fees: These are the fees set by the respective embassies or consulates for the visa
                                    itself.Additional Charges: There may also be courier fees, shipping charges, or
                                    other ancillary costs involved in the process Super Jet</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>هيكل الرسوم </b>
                                    <p>رسوم الخدمة: تفرض سوبرجيت رسوم خدمة لمعالجة طلبات التأشيرة
                                    الإلكترونية، وهي منفصلة عن رسوم السفارة أو القنصلية.
                                    رسوم السفارة: هذه هي الرسوم التي تحددها السفارات أو القنصليات المعنية للتأشيرة
                                    نفسها.
                                    الرسوم الإضافية: قد تكون هناك أيضًا رسوم البريد السريع أو رسوم الشحن أو تكاليف
                                    إضافية أخرى متعلقة بالعملية.</p>
                                </div>}


                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Payment Terms:</b>
                                    <p>Payments for the service and embassy fees must be made at
                                    the time of application submission Super jet accepts payments through credit or
                                    debit cards.The total cost for obtaining a visa includes both the service fee and
                                    the embassy fee, which will be detailed during the application process Superjet.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>شروط الدفع </b>
                                    <p>يجب دفع رسوم الخدمة ورسوم السفارة وقت تقديم الطلب. تقبل
                                    سوبرجيت المدفوعات عبر بطاقات الائتمان أو الخصم. يشمل التكلفة الإجمالية للحصول على
                                    التأشيرة كل من رسوم الخدمة ورسوم السفارة، والتي سيتم تفصيلها أثناء عملية التقديم</p>
                                </div>}


                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Refund Policy:</b>
                                    <p>Non-Refundable Fees: All fees (service fees, embassy fees,
                                    courier fees, and shipping charges) are non-refundable, even in cases of visa
                                    processing delays or denials.Responsibility for Delays: Superjet is not responsible
                                    for any financial losses incurred due to visa processing delays or denials by the
                                    embassy or consulate. Delays may occur if additional documents are requested by the
                                    embassy or consulate Superjet.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>سياسة الاسترداد </b>
                                    <p>الرسوم غير قابلة للاسترداد: جميع الرسوم (رسوم الخدمة،
                                    رسوم السفارة، رسوم البريد السريع، ورسوم الشحن) غير قابلة للاسترداد، حتى في حالات
                                    تأخير أو رفض معالجة التأشيرة
                                    مسؤولية التأخيرات: لا تتحمل سوبرجيت مسؤولية أي خسائر مالية تنشأ عن تأخير معالجة
                                    التأشيرة أو رفضها من قبل السفارة أو القنصلية. قد تحدث تأخيرات إذا طلبت السفارة أو
                                    القنصلية مستندات إضافية</p>
                                </div>}


                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Document Submission and Return:</b>
                                    <p>Applicants are required to submit all
                                    necessary documents for visa processing. Once the visa is processed, the documents,
                                    including the visa, will be returned to the applicant via the shipping method chosen
                                    during the application process.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>تقديم وإعادة المستندات </b>
                                    <p>يجب على المتقدمين تقديم جميع المستندات اللازمة
                                    لمعالجة التأشيرة. بمجرد معالجة التأشيرة، سيتم إعادة المستندات، بما في ذلك التأشيرة،
                                    إلى المتقدم عبر طريقة الشحن المختارة أثناء عملية التقديم</p>
                                </div>}


                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Shipping Options:</b>
                                    <p>Superjet offers various shipping options, typically
                                    through reputable courier services. The options may include standard, expedited, and
                                    overnight shipping, depending on the urgency and the destination.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>خيارات الشحن </b>
                                    <p>تقدم سوبرجيت خيارات شحن متنوعة، عادةً عبر خدمات البريد
                                    السريع المعروفة. تشمل الخيارات الشحن القياسي، العاجل، والشحن الليلي، اعتمادًا على
                                    مدى الحاجة والوجهة</p>
                                </div>}


                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Responsibility and Liability:</b>
                                    <p>Superjet is not liable for any delays or
                                    losses caused by shipping providers. Any issues that arise after the documents have
                                    been handed over to the courier service are beyond Superjet control.Applicants are
                                    encouraged to track their shipments and to contact the courier service directly in
                                    case of any delays or issues with delivery.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>المسؤولية والالتزامات </b>
                                    <p>لا تتحمل سوبرجيت مسؤولية أي تأخيرات أو خسائر تسببها
                                    مزودي خدمات الشحن. أي مشاكل تنشأ بعد تسليم المستندات إلى خدمة البريد السريع تقع خارج
                                    نطاق سيطرة سوبرجيت. يُنصح المتقدمون بتتبع شحناتهم والاتصال بخدمة البريد السريع
                                    مباشرةً في حالة وجود أي تأخيرات أو مشاكل في التسليم</p>
                                </div>}


                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Shipping Costs:</b>
                                    <p>Shipping fees are additional to the visa and service fees
                                    and will be calculated based on the shipping method and destination. These fees are
                                    non-refundable, even if there are delays or issues beyond Superjet control.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>تكاليف الشحن </b>
                                    <p>رسوم الشحن تضاف إلى رسوم التأشيرة ورسوم الخدمة وسيتم حسابها
                                    بناءً على طريقة الشحن والوجهة. هذه الرسوم غير قابلة للاسترداد، حتى إذا كانت هناك
                                    تأخيرات أو مشاكل خارج نطاق سيطرة سوبرجيت</p>
                                </div>}


                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Secure Handling:</b>
                                    <p>Superjet ensures that all documents are handled securely
                                    and confidentially. However, the responsibility for the documents is transferred to
                                    the shipping provider once the package is dispatched.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>المعالجة الآمنة</b>
                                    <p>تضمن سوبرجيت أن جميع المستندات يتم التعامل معها بأمان
                                    وسرية. ومع ذلك، تنتقل المسؤولية عن المستندات إلى مزود خدمات الشحن بمجرد إرسال
                                    الطرد</p>
                                </div>}


                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Services:</b>
                                    <p>Apply well in advance to account for potential delays.Ensure all
                                    documentation is complete and accurate to avoid processing issues.Stay informed of
                                    any changes in visa requirements or fees by regularly checking the official Superjet
                                    website or contacting their customer service.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>الخدمات </b>
                                    <p>تقدم بطلبك مسبقًا لحساب التأخيرات المحتملة
                                    تأكد من أن جميع المستندات كاملة ودقيقة لتجنب مشاكل المعالجة
                                    كن على علم بأي تغييرات في متطلبات التأشيرة أو الرسوم عن طريق التحقق بانتظام من
                                    الموقع الرسمي لسوبرجيت أو الاتصال بخدمة العملاء</p>
                                </div>}


                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Communications:</b>
                                    <p>Explicit Consent: By using the site, you agree to receive
                                    communications from Superjet.Forms of Communication: These may include email, text
                                    messaging, and other electronic or traditional methods.Purpose of Communication:
                                    Communications may involve marketing, promotional materials, and other
                                    service-related information.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>الاتصالات </b>
                                    <p>الموافقة الصريحة: باستخدام الموقع، فإنك توافق على تلقي
                                    الاتصالات من سوبرجيت
                                    أشكال الاتصال: قد تشمل هذه الاتصالات البريد الإلكتروني، الرسائل النصية، وطرق
                                    إلكترونية أو تقليدية أخرى
                                    غرض الاتصال: قد تتضمن الاتصالات مواد تسويقية، عروض ترويجية، ومعلومات أخرى متعلقة
                                    بالخدمة</p>
                                </div>}


                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>CopyRights Compliances:</b>
                                    <p>By agreeing to these terms, you are essentially
                                    stating that you acknowledge SuperJet commitment to upholding intellectual property
                                    rights and agree to absolve them of any responsibility or liability regarding any
                                    infringement issues. Additionally, you are agreeing to inform Superjet promptly if
                                    you become aware of any infringement and give them the opportunity to investigate
                                    and respond before pursuing legal action. It's a standard clause designed to protect
                                    both parties involved.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>التوافق مع حقوق الطبع والنشر </b>
                                    <p>بالموافقة على هذه الشروط، فإنك تقر بالتزام
                                    سوبرجيت بحماية حقوق الملكية الفكرية وتوافق على إعفائهم من أي مسؤولية أو التزام بخصوص
                                    أي قضايا انتهاك. بالإضافة إلى ذلك، توافق على إخطار سوبرجيت فورًا إذا علمت بأي انتهاك
                                    وإعطائهم الفرصة للتحقيق والرد قبل متابعة أي إجراء قانوني. هذه فقرة قياسية تهدف إلى
                                    حماية الطرفين المعنيين</p>
                                </div>}


                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Restrictions on Use:</b>
                                    <p>Age Requirement: Users must be adults over 18 years
                                    old to use the site. Legal and Intended The site is to be used only for legal
                                    purposes consistent with its terms and conditions.Prohibited Activities: Users are
                                    prohibited from engaging in various activities, including posting pyramid schemes or
                                    chain letters, transmitting viruses, violating copyright or intellectual property
                                    rights, posting defamatory or unlawful content, and engaging in activities that
                                    could encourage criminal behaviour.Restrictions on Use: Users are not allowed to
                                    copy, reproduce, distribute, or exploit any part of the website or its content
                                    without prior written consent from Superjet.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>القيود على الاستخدام</b>
                                    <p>متطلبات العمر: يجب أن يكون المستخدمون بالغين فوق سن
                                    18 عامًا لاستخدام الموقع

                                    الاستخدام القانوني والمقصود: يجب استخدام الموقع فقط لأغراض قانونية تتوافق مع شروطه
                                    وأحكامه
                                    الأنشطة المحظورة: يُحظر على المستخدمين الانخراط في أنشطة متعددة، بما في ذلك نشر
                                    مخططات الهرم أو الرسائل المتسلسلة، نقل الفيروسات، انتهاك حقوق الطبع والنشر أو حقوق
                                    الملكية الفكرية، نشر محتوى تشهيري أو غير قانوني، والانخراط في أنشطة قد تشجع على
                                    السلوك الإجرامي
                                    القيود على الاستخدام: لا يُسمح للمستخدمين بنسخ أو إعادة إنتاج أو توزيع أو استغلال أي
                                    جزء من الموقع أو محتواه دون موافقة خطية مسبقة من سوبرجيت</p>
                                </div>}


                            {(lnstr === 'en') ?
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>Disclaimer:</b>
                                    <p>This section essentially states that Superjet provides
                                    information on its website "as is," without any warranties, whether expressed or
                                    implied. This includes warranties related to accuracy, completeness, fitness for a
                                    particular purpose, merchantability, title, and non-infringement.It also clarifies
                                    that Superjet is not responsible for any damages or losses arising from the use or
                                    inability to use the website. This includes direct or indirect damages, incidental
                                    or consequential damages, special or punitive damages.Furthermore, it disclaims any
                                    warranties regarding the hardware and software used to support the website. This
                                    disclaimer extends to any inaccuracies, errors, or omissions in the services
                                    provided, including delays, interruptions, or errors in transmission or delivery of
                                    services or products.</p>
                                </div>
                                :
                                <div className={lnstr}>
                                    <b style={{ color: "#33d0f2" }}>إخلاء المسؤولية</b>
                                    <p>هذه الفقرة تنص على أن سوبرجيت توفر المعلومات على موقعها
                                    "كما هي"، دون أي ضمانات، سواء كانت صريحة أو ضمنية. وهذا يشمل الضمانات المتعلقة
                                    بالدقة، الشمولية، الملاءمة لغرض معين، قابلية التسويق، العنوان، وعدم الانتهاك. كما
                                    توضح أن سوبرجيت ليست مسؤولة عن أي أضرار أو خسائر تنشأ عن استخدام أو عدم القدرة على
                                    استخدام الموقع. وهذا يشمل الأضرار المباشرة أو غير المباشرة، الأضرار العرضية أو
                                    التبعية، الأضرار الخاصة أو التأديبية وعلاوة على ذلك، تنفي أي ضمانات بخصوص الأجهزة
                                    والبرامج المستخدمة لدعم الموقع. يمتد هذا التنصل إلى أي عدم دقة أو أخطاء أو سهو في
                                    الخدمات المقدمة، بما في ذلك التأخيرات، الانقطاعات، أو الأخطاء في نقل أو تسليم
                                    الخدمات أو المنتجات</p>
                                </div>}

                        </div>
                    </div>
                </div>
            </div>


            <Footer/>

        </div>
    );
}

export default Terms;