import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import { useTranslation } from 'react-i18next';
// import travelimg from '../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
// import SessionRemove from "../helper/sessionremove";
const { config } = require('../../api/api');



function MedicalWorkerPlan() {
  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });

  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSection, setShowSection] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    debugger
    setFormData({ ...formData, 'insurancetype': event.target.value });

    if (event.target.value === ' ') {
      setShowSection(true);
      setButtonDisabled(true);
    }
    else {
      setShowSection(false);
      setButtonDisabled(false);
    }

  };


  const assuredjson = sessionStorage.getItem('medicalpremiumcover');
  const assured = JSON.parse(assuredjson);


  const [vendorKey, setVendorKey] = useState('');

  const handleSubmit = async (event) => {
    window.location.href ='/medicalworker-personal'
  };

  const handleNext = async (event) => {
    window.location.href='/'
  };


  
  const [medicalExpense, setMedicalExpense] = useState('');
  const [outOfPocket, setOutOfPocket] = useState(null);

  const annualLimit = 5000; // OMR
  const deductible = 3; // OMR
  const coPayPercentage = 0.20; // 20%

  const calculateOutOfPocket = () => {
    const expense = parseFloat(medicalExpense);
    if (isNaN(expense) || expense <= 0) {
      alert('Please enter a valid medical expense.');
      return;
    }

    let totalOutOfPocket = 0;

    // Deductible
    totalOutOfPocket += deductible;

    // Calculate remaining after deductible
    const remainingExpense = expense - deductible > 0 ? expense - deductible : 0;

    // Co-pay
    totalOutOfPocket += remainingExpense * coPayPercentage;

    // Check against annual limit
    if (totalOutOfPocket > annualLimit) {
      totalOutOfPocket = annualLimit;
    }

    setOutOfPocket(totalOutOfPocket.toFixed(2));
  };




  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Unified Domestic Worker' : 'العمالة المنزلية الموحدة\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">


              <form>

              <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
              <div className="d-flex justify-content-between my-3">
                <div>
                <h4><strong>Plan Details</strong></h4>
                </div>
                <div>
              <h3>{assured.premium_valu}  OMR</h3>
              {/* <h6><strong>{sumaasured !=null ? sumaasured ?.assured_value :''}</strong></h6> */}
              </div>
              
              </div>

              <div>
                <ul className="list-unstyled">
                <li>In Patient Coverage 4000 OMR</li>
                    <li>Out Patient Coverage 500 OMR</li>
                    <li>2 Years Coverage</li>
                </ul>
            </div>
              
            </div>

              </form>

              <div className="d-flex justify-content-between my-3">
    <button 
        type="button" 
        disabled={buttonDisabled} 
        onClick={handleNext} 
        className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">
        {lnstr === 'en' ? 'Select Life Insurance Addon':'اختر ملحق التأمين على الحياة'}
    </button>
    <button 
        type="submit" 
        disabled={buttonDisabled} 
        onClick={handleSubmit}
        className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">
        {lnstr === 'en' ? 'Confirm Plan':'تأكيد الخطة'}
    </button>
</div>

              {/* <div className="d-flex justify-content-between my-3">
              <button 
        type="button" 
        disabled={buttonDisabled} 
        onClick={handleNext} 
        className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{lnstr === 'en' ? 'Select Life Insurance Addon' :'اختر ملحق التأمين على الحياة'}</button>
                <button 
        type="submit" 
        disabled={buttonDisabled} 
        onClick={handleSubmit}
        className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{lnstr === 'en' ? 'Confirm Plan' :'تأكيد الخطة'}</button>
                </div> */}


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default MedicalWorkerPlan;